import React, { useState } from "react";
import "../styles/ResetPasswordStyles.scss";
import { Input, Button, PasswordInput } from "@mantine/core";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";

const port = process.env.REACT_APP_IP_ADDRESS;
const API_URL = `${port}/api/users/`;

const ResetPassword = () => {
  const [formData, setFormData] = useState({
    newPassword: "",
    confirmNewPassword: "",
  });

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const Token = searchParams.get("Token");
  const type = searchParams.get("type");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError("");

    if (formData.newPassword !== formData.confirmNewPassword) {
      setError("Passwords do not match");
      setIsLoading(false);
      return;
    }

    try {
      // Send Axios request to reset password endpoint
      const response = await axios.post(API_URL + type + "/reset-password", {
        newPassword: formData.newPassword,
        token: Token,
      });

      // Check if password reset was successful
      if (response.status === 200) {
        toast.success(
          <>
            <img src="images/new-logo.png" className="toast_logo" />
            <p className="mt-3">Password successfully changed</p>
          </>,
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          }
        );
        // Redirect or show success message as needed
        navigate(`/login${type}`);
      }
    } catch (error) {
      console.error("Error resetting password:", error);
      setError("Error resetting password. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div id="reset_cont">
      <div className="top_cont d-flex  align-items-center justify-content-center pt-4">
        <img
          src="images/new-logo.png"
          alt=""
          className="me-1"
          onClick={() => navigate("/chooseuser")}
        />
      </div>
      <div className="form_cont">
        <h3>Reset Password</h3>
        <div className="login_form">
          <form onSubmit={onSubmit}>
            <Input.Wrapper
              label="New Password"
              className="mb-4"
              size="md"
              required
            >
              <PasswordInput
                placeholder="Enter New Password"
                size="lg"
                radius="md"
                name="newPassword"
                value={formData.newPassword}
                onChange={onChange}
              />
            </Input.Wrapper>
            <Input.Wrapper
              label="Confirm New Password"
              className="mb-2"
              size="md"
              required
              error={error}
            >
              <PasswordInput
                placeholder="Confirm New Password"
                size="lg"
                radius="md"
                name="confirmNewPassword"
                value={formData.confirmNewPassword}
                onChange={onChange}
              />
            </Input.Wrapper>
            <div className="button_cont d-flex align-items-center justify-content-center mb-3">
              <Button
                variant="filled"
                color="rgba(0, 155, 58, 1)"
                type="submit"
                loading={isLoading}
              >
                Reset Password
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
