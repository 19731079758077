import React from "react";
import "./SearchHistoryStyles.scss";
import { Button } from "@mantine/core";
import { format } from "date-fns";
const SearchHistory = ({ search }) => {
  return (
    <div className=" col-4 mt-4 ">
      <div className="search_history_card mx-5 px-2  py-3">
        <div className="row">
          <div className="col-12 heading d-flex align-items-center justify-content-center">
            <h3>{format(new Date(search?.createdAt), "dd/MMM/yy")}</h3>
          </div>
          <div className="col-6 d-flex flex-column align-items-center ">
            <p className="title">Age</p>
            <p className="title">Statistics</p>
            <p className="title">Physical</p>
            <p className="title">Academic</p>
          </div>
          <div className="col-6">
            <p>
              {search?.criteria?.ageRange?.minAge}-
              {search?.criteria?.ageRange?.maxAge}
            </p>
            <p> {search?.criteria?.metrics?.hands}</p>
            <p>Height</p>
            <p>GPA</p>
          </div>
          <div className="col-12 button_cont d-flex justify-content-center align-items-center">
            <Button variant="outline" color="#BEBEBE">
              Search Again
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchHistory;
