import { Button, Stepper, Tooltip } from "@mantine/core";
import { nprogress } from "@mantine/nprogress";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import "../styles/OnboardingStyles.scss";
import { useDispatch, useSelector } from "react-redux";
const Onboarding = () => {
  const port = process.env.REACT_APP_IP_ADDRESS + "/api/PsychQues";
  const [questions, setQuestions] = useState([]);
  const [questions2, setQuestions2] = useState([]);
  const [questions3, setQuestions3] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [active, setActive] = useState(0);
  const [disableback, setDisableback] = useState(true);
  const [activeElement, setActiveElement] = useState(null);
  const [disableContinue, setDisableContinue] = useState(true);
  const [QuestionIdphase1, setQuestionIdphase1] = useState(new Array(10));
  const [AnswerNumberphase1, setAnswerNumberphase1] = useState(new Array(10));
  const [QuestionIdphase2, setQuestionIdphase2] = useState(new Array(10));
  const [AnswerNumberphase2, setAnswerNumberphase2] = useState(new Array(10));
  const [QuestionIdphase3, setQuestionIdphase3] = useState(new Array(10));
  const [AnswerNumberphase3, setAnswerNumberphase3] = useState(new Array(10));
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const nextStep = () => {
    if (active === 0) {
      SubmitAnswers1();
    }
    if (active === 1) {
      SubmitAnswers2();
    }
    if (active === 2) {
      SubmitAnswers3();
    }

    setActive((current) => (current < 3 ? current + 1 : current));
  };
  const prevStep = () => {
    setActive((current) => (current > 0 ? current - 1 : current));
  };
  const handlePrevClick = () => {
    if (currentQuestionIndex > 0) {
      removeActiveStyles();
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
    if (currentQuestionIndex === 0 && active > 0) {
      removeActiveStyles();
      setCurrentQuestionIndex(9);
      prevStep();
    }
  };

  const handleNextClick = () => {
    if (currentQuestionIndex < 9 && active < 3) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      removeActiveStyles();
    }
    if (currentQuestionIndex === 9 && active < 3) {
      removeActiveStyles();
      if (active <= 1) {
        setCurrentQuestionIndex(0);
      }
      nextStep();
    }
  };
  const removeActiveStyles = () => {
    if (activeElement) {
      setActiveElement(null);
      activeElement.style.backgroundColor = "";
      activeElement.style.color = "";
      activeElement.style.boxShadow = "";
      activeElement.style.borderColor = "";
    }
  };
  useEffect(() => {
    nprogress.set((currentQuestionIndex + 1) * 10);
  }, [currentQuestionIndex]);

  const handleClick = (event, index) => {
    const answerDiv = event.target.closest(".answer");
    if (answerDiv) {
      // Remove the styles from the previously active element
      if (activeElement) {
        activeElement.style.backgroundColor = "";
        activeElement.style.color = "";
        activeElement.style.boxShadow = "";
        activeElement.style.borderColor = "";
      }

      // Apply the styles to the clicked element
      answerDiv.style.backgroundColor = "#3e68ff";
      answerDiv.style.color = "white";
      answerDiv.style.boxShadow = "rgba(0, 0, 0, 0.24) 0px 3px 8px";
      answerDiv.style.borderColor = "#3e68ff";

      // Set the clicked element as the active element
      setActiveElement(answerDiv);
      const questionIndex = currentQuestionIndex;
      const answerIndex = index;

      // Update the questionID and answerNumber arrays
      const newQuestionId = [...QuestionIdphase1];
      newQuestionId[questionIndex] = currentQuestion?.id;

      const newAnswerNumber = [...AnswerNumberphase1];
      newAnswerNumber[questionIndex] = answerIndex + 1;

      if (active === 0) {
        setQuestionIdphase1(newQuestionId);
        setAnswerNumberphase1(newAnswerNumber);
      }
      if (active === 1) {
        setQuestionIdphase2(newQuestionId);
        setAnswerNumberphase2(newAnswerNumber);
      }
      if (active === 2) {
        setQuestionIdphase3(newQuestionId);
        setAnswerNumberphase3(newAnswerNumber);
      }
    }
  };
  useEffect(() => {
    if (activeElement !== null) {
      setDisableContinue(false);
    } else {
      setDisableContinue(true);
    }
  }, [activeElement]);
  useEffect(() => {
    if (currentQuestionIndex === 0 && active === 0) {
      setDisableback(true);
    } else {
      setDisableback(false);
    }
  }, [currentQuestionIndex]);
  const getQuestionsphase1 = async () => {
    try {
      const response = await axios.post(`${port}/phase1`);

      setQuestions(response.data.questions);
    } catch (error) {
      console.error(error);
    }
  };
  const getQuestionsphase2 = async () => {
    try {
      const response = await axios.post(`${port}/phase2`);

      setQuestions2(response.data.questions);
    } catch (error) {
      console.error(error);
    }
  };
  const getQuestionsphase3 = async () => {
    try {
      const response = await axios.post(`${port}/phase3`);

      setQuestions3(response.data.questions);
    } catch (error) {
      console.error(error);
    }
  };
  const SubmitAnswers1 = async () => {
    try {
      const response = await axios.post(`${port}/submit1`, {
        ids: QuestionIdphase1,
        answers: AnswerNumberphase1,
        playerid: user._id,
      });

      console.log(response);
    } catch (error) {
      console.error(error);
    }
  };
  const SubmitAnswers2 = async () => {
    try {
      const response = await axios.post(`${port}/submit2`, {
        ids: QuestionIdphase2,
        answers: AnswerNumberphase2,
        playerid: user._id,
      });

      console.log(response);
    } catch (error) {
      console.error(error);
    }
  };
  const SubmitAnswers3 = async () => {
    try {
      const response = await axios.post(`${port}/submit3`, {
        ids: QuestionIdphase3,
        answers: AnswerNumberphase3,
        playerid: user._id,
      });

      console.log(response);
    } catch (error) {
      console.error(error);
    } finally {
      navigate("/dashboard");
    }
  };
  useEffect(() => {
    getQuestionsphase1();
    getQuestionsphase2();
    getQuestionsphase3();
  }, []);
  // let currentQuestion;
  // if (active === 1) {
  //   currentQuestion = questions[currentQuestionIndex];
  // }
  // if (active === 2) {
  //   currentQuestion = questions2[currentQuestionIndex];
  // }
  // if (active === 3) {
  //   currentQuestion = questions3[currentQuestionIndex];
  // }
  const currentQuestion =
    active === 0
      ? questions[currentQuestionIndex]
      : active === 1
      ? questions2[currentQuestionIndex]
      : questions3[currentQuestionIndex];

  return (
    <div className="Onboarding-main">
      <div className="onboarding-top d-flex justify-content-center">
        <Button
          variant="filled"
          color="#3E68FF"
          radius="xl"
          className="align-self-start "
          id="back_button"
          onClick={handlePrevClick}
          disabled={disableback}
        >
          Back
        </Button>

        <Stepper
          size="md"
          active={active}
          allowNextStepsSelect={false}
          color="#3E68FF"
        >
          <Stepper.Step label="Step 1" description="Game Intelligence" />
          <Stepper.Step label="Step 2" description="Resillience" />
          <Stepper.Step label="Step 3" description="Leadership" />
        </Stepper>
      </div>

      <div className="container " id="question_answers">
        <div className="row">
          <div
            className={`col-6 d-flex flex-column  ${
              currentQuestion?.content ? "" : "justify-content-center"
            }`}
          >
            <p>Question {currentQuestionIndex + 1}/10</p>
            <h4>{currentQuestion?.questionStatement}</h4>
            {currentQuestion?.content && (
              <iframe
                height="315"
                src="https://www.youtube.com/embed/TtYDod_bRnY?si=972XlNfd0kq3vMQq"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
                style={{
                  borderRadius: "5px",
                  boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
                }}
              ></iframe>
            )}
          </div>
          <div
            className="col-6 d-flex flex-column justify-content-between "
            id="answer_cont"
          >
            {currentQuestion &&
              Object?.values(currentQuestion?.answers)?.map((answer, index) => (
                <div
                  key={index}
                  className="answer py-3 d-flex align-items-center justify-content-center "
                  onClick={(event) => handleClick(event, index)}
                >
                  <h6>{answer}</h6>
                </div>
              ))}
          </div>
        </div>
      </div>
      <div className="onboarding-bottom d-flex align-items-center justify-content-end">
        {disableContinue ? (
          <Tooltip
            label="Select an option to continue"
            transitionProps={{ transition: "skew-down", duration: 300 }}
            openDelay={500}
          >
            <Button
              variant="outline"
              color="#3E68FF"
              radius="xl"
              disabled={true}
              className="align-self-start "
              id="back_button"
            >
              Continue
            </Button>
          </Tooltip>
        ) : (
          <Button
            variant="filled"
            color="#3E68FF"
            radius="xl"
            className="align-self-start "
            id="back_button"
            disabled={disableContinue}
            onClick={handleNextClick}
          >
            Continue
          </Button>
        )}
      </div>
    </div>
  );
};

export default Onboarding;
