import React, { useState } from "react";
import { Input, Button, Textarea } from "@mantine/core";
import "../styles/PricingPageStyles.scss";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { Menu, MenuItem, ListItemIcon, ListItemText } from "@mui/material";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { faCheck, faTimes, faCircle } from "@fortawesome/free-solid-svg-icons";

import {
  faLocationDot,
  faPhone,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
      });
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
};
const PricingPage = () => {
  const [rescipent, setrescipent] = useState("Institute"); //institute or private coaching
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (feature) => {
    // Handle the selection here, e.g., navigate to the selected feature
    console.log(`Selected feature: ${feature}`);
    handleClose();
  };
  const navigate = useNavigate();
  const navtohome = () => {
    navigate("/");
  };
  const navtodash = () => {
    navigate("/chooseuser");
  };

  const navtopracticefeature = () => {
    navigate("/practice-features");
  };
  const navtotrainingfeature = () => {
    navigate("/training-features");
  };
  const navtovideofeature = () => {
    navigate("/video-features");
  };
  const navtopricing = () => {
    navigate("/pricing");
  };
  const navtocontactus = () => {
    navigate("/contactus");
  };

  const navtoBlog = () => {
    navigate("/blog");
  };
  const { width } = useWindowSize();
  return (
    <div className="pricing_home_cont">
      <div className="pricing_landing">
        <div id="elipse_1"></div>
        <div className="landing_top d-flex align-items-center justify-content-sm-between justify-content-center">
          <img src="images/Vector.png" alt="" id="top_logo" />
          <div className="navbar">
            <Button
              variant="transparent"
              size="lg"
              color="gray"
              onClick={navtohome}
              id="aboutUs_btn"
            >
              Home
            </Button>
            <Button
              variant="transparent"
              size="lg"
              color="gray"
              //   aria-controls="features-menu"
              //   aria-haspopup="true"
              onClick={handleClick}
              id="features_btn"
              rightSection={<FontAwesomeIcon icon={faAngleDown} />}
            >
              Features
            </Button>
            <Menu
              id="features-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              PaperProps={{
                style: {
                  background: "transparent",
                  boxShadow: "none",
                },
              }}
            >
              <MenuItem onClick={() => handleMenuItemClick("Feature 1")}>
                <Button
                  variant="transparent"
                  size="md"
                  color="gray"
                  onClick={navtopracticefeature}
                  id="pricing_btn"
                >
                  Practice
                </Button>{" "}
              </MenuItem>
              <MenuItem onClick={() => handleMenuItemClick("Feature 2")}>
                <Button
                  variant="transparent"
                  size="md"
                  color="gray"
                  onClick={navtotrainingfeature}
                  id="pricing_btn"
                >
                  Training
                </Button>
              </MenuItem>
              <MenuItem onClick={() => handleMenuItemClick("Feature 3")}>
                <Button
                  variant="transparent"
                  size="md"
                  color="gray"
                  onClick={navtovideofeature}
                  id="pricing_btn"
                >
                  Video
                </Button>{" "}
              </MenuItem>
            </Menu>
            <Button
              variant="transparent"
              size="lg"
              color="gray"
              onClick={navtopricing}
              id="pricing_btn"
            >
              Pricing
            </Button>{" "}
            <Button
              variant="transparent"
              size="lg"
              color="gray"
              onClick={navtocontactus}
              id="contactUs_btn"
            >
              Contact us
            </Button>
            <Button
              variant="transparent"
              size="lg"
              color="gray"
              onClick={navtoBlog}
              id="contactUs_btn"
            >
              Blog
            </Button>
          </div>

          <Button
            variant="filled"
            size="lg"
            radius={"xl"}
            color="#0C0F21"
            onClick={navtodash}
            id="dashboard_btn"
          >
            Dashboard
          </Button>
        </div>
        <div className="landing_main d-flex flex-column align-items-center">
          <h1 className="pricing_headline">
            <span>Pricing</span>
          </h1>
          <p className="main_text">
            Embrace the Future of Football with <span>Athlantix!</span> Our
            intuitive platform is tailored to arm <br />{" "}
            <span>players, coaches</span>, and parents with insights like never
            before. Get ready to gain a <br /> competitive edge and see the game
            through the lens of <span>advanced analytics</span>.
          </p>
        </div>

        {/* //pricing table */}
        <div>
          <div class="pricing-table-wrapper">
            <ul class="pricing-table">
              {/* FIRST */}
              <li class="pricing-table__item">
                <h3 class="pricing-table__title">BASIC</h3>
                <p class="pricing-table__description">
                  <span class="pricing-table__tagline">$650</span>
                </p>
                <ul class="pricing-table__products">
                  <li class="pricing-table__product">
                    <a href="">
                      <FontAwesomeIcon
                        icon={faCircle}
                        className="text-white"
                        style={{ marginRight: "8px", height: "10px" }}
                      />
                      <span> 150 </span>Footage Hours
                    </a>
                  </li>
                  <li class="pricing-table__product">
                    <a href="">
                      <FontAwesomeIcon
                        icon={faCheck}
                        className="text-white "
                        style={{ marginRight: "8px" }}
                      />
                      {"   "}
                      NCAA Requirement Tracking
                    </a>
                  </li>

                  <li class="pricing-table__product pricing-table__product--excluded">
                    <a href="">
                      {" "}
                      <FontAwesomeIcon
                        icon={faCheck}
                        className="text-white "
                        style={{ marginRight: "8px" }}
                      />
                      {"   "}Team Messaging
                    </a>
                  </li>
                  <li class="pricing-table__product pricing-table__product--excluded">
                    <a href="">
                      {" "}
                      <FontAwesomeIcon
                        icon={faCheck}
                        className="text-white "
                        style={{ marginRight: "8px" }}
                      />
                      {"   "}Notification System
                    </a>
                  </li>
                  <li class="pricing-table__product pricing-table__product--excluded">
                    <a href="">
                      {" "}
                      <FontAwesomeIcon
                        icon={faCheck}
                        className="text-white "
                        style={{ marginRight: "8px" }}
                      />
                      {"   "}Team Training Analyzer
                    </a>
                  </li>
                  <li class="pricing-table__product">
                    <a href="">
                      {" "}
                      <FontAwesomeIcon
                        icon={faCheck}
                        className="text-white "
                        style={{ marginRight: "8px" }}
                      />
                      {"   "}Team Practice Analyzer
                    </a>
                  </li>
                  <li class="pricing-table__product">
                    <a href="">
                      {" "}
                      <FontAwesomeIcon
                        icon={faCheck}
                        className="text-white "
                        style={{ marginRight: "8px" }}
                      />
                      {"   "}Parent-Athlete Tracker
                    </a>
                  </li>
                  <li class="pricing-table__product">
                    <a href="">
                      {" "}
                      <FontAwesomeIcon
                        icon={faCheck}
                        className="text-white "
                        style={{ marginRight: "8px" }}
                      />
                      {"   "}Player Performance Insights
                    </a>
                  </li>
                  <li class="pricing-table__product pricing-table__product--excluded">
                    <a href="" style={{ color: "gray" }}>
                      <FontAwesomeIcon
                        icon={faTimes}
                        style={{ marginRight: "8px" }}
                      />
                      {"   "} AI Assist
                    </a>
                  </li>
                  <li class="pricing-table__product pricing-table__product--excluded">
                    <a href="" style={{ color: "gray" }}>
                      <FontAwesomeIcon
                        icon={faTimes}
                        style={{ marginRight: "8px" }}
                      />
                      {"   "}Expert Analysis & Breakdown
                    </a>
                  </li>
                </ul>
                <a
                  href="https://mojtabaseyedi.com"
                  class="pricing-table__button-req-access"
                >
                  Request Access
                </a>
              </li>
              {/* SECOND */}
              <li class="pricing-table__item">
                <h3 class="pricing-table__title" style={{ color: "#858FD8" }}>
                  STANDARD
                </h3>
                <p class="pricing-table__description">
                  <span class="pricing-table__tagline">$1150</span>
                </p>
                <ul class="pricing-table__products">
                  <li class="pricing-table__product">
                    <a href="">
                      <FontAwesomeIcon
                        icon={faCircle}
                        style={{
                          marginRight: "8px",
                          height: "10px",
                          color: "#858FD8",
                        }}
                      />
                      <span> 350 </span>Footage Hours
                    </a>
                  </li>
                  <li class="pricing-table__product">
                    <a href="">
                      <FontAwesomeIcon
                        icon={faCheck}
                        style={{ marginRight: "8px", color: "#858FD8" }}
                      />
                      {"   "}
                      NCAA Requirement Tracking
                    </a>
                  </li>

                  <li class="pricing-table__product pricing-table__product--excluded">
                    <a href="">
                      {" "}
                      <FontAwesomeIcon
                        icon={faCheck}
                        style={{ marginRight: "8px", color: "#858FD8" }}
                      />
                      {"   "}Team Messaging
                    </a>
                  </li>
                  <li class="pricing-table__product pricing-table__product--excluded">
                    <a href="">
                      {" "}
                      <FontAwesomeIcon
                        icon={faCheck}
                        style={{ marginRight: "8px", color: "#858FD8" }}
                      />
                      {"   "}Notification System
                    </a>
                  </li>
                  <li class="pricing-table__product pricing-table__product--excluded">
                    <a href="">
                      {" "}
                      <FontAwesomeIcon
                        icon={faCheck}
                        style={{ marginRight: "8px", color: "#858FD8" }}
                      />
                      {"   "}Team Training Analyzer
                    </a>
                  </li>
                  <li class="pricing-table__product">
                    <a href="">
                      {" "}
                      <FontAwesomeIcon
                        icon={faCheck}
                        style={{ marginRight: "8px", color: "#858FD8" }}
                      />
                      {"   "}Team Practice Analyzer
                    </a>
                  </li>
                  <li class="pricing-table__product">
                    <a href="">
                      {" "}
                      <FontAwesomeIcon
                        icon={faCheck}
                        style={{ marginRight: "8px", color: "#858FD8" }}
                      />
                      {"   "}Parent-Athlete Tracker
                    </a>
                  </li>
                  <li class="pricing-table__product">
                    <a href="">
                      {" "}
                      <FontAwesomeIcon
                        icon={faCheck}
                        style={{ marginRight: "8px", color: "#858FD8" }}
                      />
                      {"   "}Player Performance Insights
                    </a>
                  </li>
                  <li class="pricing-table__product pricing-table__product--excluded">
                    <a href="">
                      <FontAwesomeIcon
                        icon={faCheck}
                        style={{ marginRight: "8px", color: "#858FD8" }}
                      />
                      {"   "} AI Assist
                    </a>
                  </li>
                  <li class="pricing-table__product pricing-table__product--excluded">
                    <a href="" style={{ color: "gray" }}>
                      <FontAwesomeIcon
                        icon={faTimes}
                        style={{ marginRight: "8px" }}
                      />
                      {"   "}Expert Analysis & Breakdown
                    </a>
                  </li>
                </ul>
                <a
                  href="https://mojtabaseyedi.com"
                  class="pricing-table__button-req-access-2"
                >
                  Request Access
                </a>
              </li>
              {/* THIRD */}
              <li class="pricing-table__item">
                <h3 class="pricing-table__title" style={{ color: "#13bd53" }}>
                  PREMIUM
                </h3>
                <p class="pricing-table__description">
                  <span class="pricing-table__tagline">$2000</span>
                </p>
                <ul class="pricing-table__products">
                  <li class="pricing-table__product">
                    <a href="">
                      <FontAwesomeIcon
                        icon={faCircle}
                        style={{
                          marginRight: "8px",
                          height: "10px",
                          color: "#13bd53",
                        }}
                      />
                      <span> 850 </span>Footage Hours
                    </a>
                  </li>
                  <li class="pricing-table__product">
                    <a href="">
                      <FontAwesomeIcon
                        icon={faCheck}
                        style={{ marginRight: "8px", color: "#13bd53" }}
                      />
                      {"   "}
                      NCAA Requirement Tracking
                    </a>
                  </li>

                  <li class="pricing-table__product pricing-table__product--excluded">
                    <a href="">
                      {" "}
                      <FontAwesomeIcon
                        icon={faCheck}
                        style={{ marginRight: "8px", color: "#13bd53" }}
                      />
                      {"   "}Team Messaging
                    </a>
                  </li>
                  <li class="pricing-table__product pricing-table__product--excluded">
                    <a href="">
                      {" "}
                      <FontAwesomeIcon
                        icon={faCheck}
                        style={{ marginRight: "8px", color: "#13bd53" }}
                      />
                      {"   "}Notification System
                    </a>
                  </li>
                  <li class="pricing-table__product pricing-table__product--excluded">
                    <a href="">
                      {" "}
                      <FontAwesomeIcon
                        icon={faCheck}
                        style={{ marginRight: "8px", color: "#13bd53" }}
                      />
                      {"   "}Team Training Analyzer
                    </a>
                  </li>
                  <li class="pricing-table__product">
                    <a href="">
                      {" "}
                      <FontAwesomeIcon
                        icon={faCheck}
                        style={{ marginRight: "8px", color: "#13bd53" }}
                      />
                      {"   "}Team Practice Analyzer
                    </a>
                  </li>
                  <li class="pricing-table__product">
                    <a href="">
                      {" "}
                      <FontAwesomeIcon
                        icon={faCheck}
                        style={{ marginRight: "8px", color: "#13bd53" }}
                      />
                      {"   "}Parent-Athlete Tracker
                    </a>
                  </li>
                  <li class="pricing-table__product">
                    <a href="">
                      {" "}
                      <FontAwesomeIcon
                        icon={faCheck}
                        style={{ marginRight: "8px", color: "#13bd53" }}
                      />
                      {"   "}Player Performance Insights
                    </a>
                  </li>
                  <li class="pricing-table__product pricing-table__product--excluded">
                    <a href="">
                      <FontAwesomeIcon
                        icon={faCheck}
                        style={{ marginRight: "8px", color: "#13bd53" }}
                      />
                      {"   "} AI Assist
                    </a>
                  </li>
                  <li class="pricing-table__product pricing-table__product--excluded">
                    <a href="">
                      <FontAwesomeIcon
                        icon={faCheck}
                        style={{ marginRight: "8px", color: "#13bd53" }}
                      />
                      {"   "}Expert Analysis & Breakdown
                    </a>
                  </li>
                  {/* <li
                    class="pricing-table__product"
                    style={{ marginLeft: "23px" }}
                  >
                    <a href="">
                      <FontAwesomeIcon
                        icon={faCircle}
                        style={{
                          marginRight: "8px",
                          height: "10px",
                          color: "#13bd53",
                        }}
                      />
                      <span> $200 </span>per each team
                    </a>
                  </li> */}
                  {/* <li class="pricing-table__product">
                    <a href="">
                      <FontAwesomeIcon
                        icon={faCircle}
                        style={{
                          marginRight: "8px",
                          height: "10px",
                          color: "#13bd53",
                        }}
                      />
                      <span> $600 </span>per each program (each program consists
                      of 4 teams)
                    </a>
                  </li> */}
                </ul>
                <a
                  href="https://mojtabaseyedi.com"
                  class="pricing-table__button-req-access-3"
                >
                  Request Access
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="cta_form_features d-flex flex-column align-items-center">
        <h1 className="mb-5">Contact us</h1>
        <p className="main_text">
          Join the Athlantix community and take control of your American
          football journey. Sign <br /> up for a <span>free trial</span> today!
        </p>
        <div className="pt-5 form_outter d-flex flex-column align-items-center px-sm-5">
          <div className="choose_recepient d-flex align-items-center justify-content-between ">
            <h4
              onClick={() => setrescipent("Institute")}
              className={`${rescipent == "Institute" && "selected"}`}
            >
              Institute
            </h4>
            <h4
              onClick={() => setrescipent("Private Coaching")}
              className={`${rescipent == "Private Coaching" && "selected"}`}
            >
              Private Coaching
            </h4>
          </div>
          <div className="my-3 input_cont">
            <Input placeholder="Name" size="lg" radius={"xl"} />
          </div>
          <div className="my-3 input_cont">
            <Input placeholder="Email Address" size="lg" radius={"xl"} />
          </div>
          <div className="my-3 input_cont">
            <Input placeholder="Phone Number" size="lg" radius={"xl"} />
          </div>
          <div className="my-3 text_area_cont">
            <Textarea
              placeholder="Description"
              size="lg"
              radius={"lg"}
              autosize
              minRows={4}
            />
          </div>
          <div className="button_cont">
            <Button
              fullWidth
              variant="filled"
              color="#0C0F21"
              size="md"
              radius={"xl"}
            >
              Request a Demo
            </Button>
          </div>
        </div>
        <div id="elipse_5"></div>
      </div>
      <div className="footer_features d-flex flex-column align-items-center justify-conetnt-center">
        <div className="logo_cont">
          <img src="images/Vector-2.png" alt="" />
        </div>
        <div className="tag_cont d-flex align-items-center justify-content-start flex-sm-row flex-column">
          <div className="d-flex flex-column align-items-start justify-content-start ">
            <div className="mb-4 tag d-flex ">
              <FontAwesomeIcon
                icon={faLocationDot}
                className="me-3"
                size="lg"
              />
              <p>Texas</p>
            </div>

            <div className="mb-4 tag d-flex ">
              <FontAwesomeIcon icon={faEnvelope} className="me-3" size="lg" />
              <p>contact@athlantix.com</p>
            </div>
          </div>
          <div className="d-flex flex-column align-items-start justify-content-start sitemap">
            <div className="mb-4 tag d-flex ">
              <p>Home</p>
            </div>
            <div className="mb-4 tag d-flex ">
              <p>Features </p>
            </div>
            <div className="mb-4 tag d-flex ">
              <p>Pricing</p>
            </div>
            <div className="mb-4 tag d-flex ">
              <p> Testimonials</p>
            </div>
          </div>
        </div>
        <div className="copyright d-flex align-items-center justify-content-center">
          <p>Copyright © 2024 Athlantix. All rights reserved.</p>
        </div>
      </div>
    </div>
  );
};

export default PricingPage;
