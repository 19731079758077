import React, { useState, useEffect } from "react";
import "../styles/LoginStyles.scss";
import { Input, Tooltip } from "@mantine/core";
import { Button } from "@mantine/core";
import { PasswordInput } from "@mantine/core";
import { Checkbox } from "@mantine/core";
import { useSelector, useDispatch } from "react-redux";
import { login, reseter } from "../auth/authslice";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import { useDisclosure } from "@mantine/hooks";
import { Modal } from "@mantine/core";
import { toast } from "react-toastify";

const port = process.env.REACT_APP_IP_ADDRESS;
const API_URL = `${port}/api/users/`;

const Login = (props) => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState("");
  const [isSendingEmail, setIsSendingEmail] = useState(false);

  // Extract the value after "login" from the URL
  const location = useLocation();
  const pathname = location.pathname; // Gets the path, e.g., /loginCoach

  // Assuming the structure always starts with /login
  const role = pathname.substring(pathname.indexOf("login") + "login".length);

  const [opened, { open, close }] = useDisclosure(false);
  const { email, password } = formData;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user, isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.auth
  );
  const onSubmit = (e) => {
    e.preventDefault();
    const type = props.type;
    const userData = {
      email,
      password,
      type,
    };

    dispatch(login(userData));
  };
  const [errorMessages, setErrorMessages] = useState();
  useEffect(() => {
    setErrorMessages(message);
  }, [message]);
  useEffect(() => {
    if (errorMessages) {
      console.log("error", message, isError);
      toast.error(
        <>
          <img src="images/new-logo.png" className="toast_logo" />
          <p className="mt-3">{errorMessages}</p>
        </>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        }
      );
    }
  }, [errorMessages]);

  useEffect(() => {
    const type = props.type;
    if (user !== null) {
      if (type == "Player") {
        navigate("/dashboard");
      }
      if (type == "Scout") {
        navigate("/scout");
      }
      if (type == "Coach") {
        navigate("/coach");
      }
      if (type == "Institute") {
        navigate("/inst");
      }
      if (type == "Parent") {
        navigate("/parentdash");
      }
      if (type == "VARouter") {
        navigate("/vadash");
      }
    }
  }, [user]);

  useEffect(() => {}, [message]);

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const sendResetPasswordEmail = async () => {
    console.log(role);

    if (!validateEmail(forgotPasswordEmail)) {
      toast.error(
        <>
          <img src="images/new-logo.png" className="toast_logo" />
          <p className="mt-3">Please enter a valid email.</p>
        </>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        }
      );
      return;
    }
    try {
      setIsSendingEmail(true);
      await axios.post(API_URL + role + "/forgot-password", {
        email: forgotPasswordEmail,
      });
      toast.success(
        <>
          <img src="images/new-logo.png" className="toast_logo" />
          <p className="mt-3">Password reset email sent! Check your inbox.</p>
        </>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        }
      );
    } catch (error) {
      toast.error(
        <>
          <img src="images/new-logo.png" className="toast_logo" />
          <p className="mt-3">Failed to send password reset email.</p>
        </>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        }
      );
    } finally {
      setIsSendingEmail(false);
      close(); // Close the modal after sending the email
      setForgotPasswordEmail("");
    }
  };

  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const handleCloseModal = () => {
    setForgotPasswordEmail(""); // Clear the email input field when modal is closed
    close(); // Close the modal
  };

  return (
    <>
      <div id="login_cont">
        <div className="top_cont d-flex  align-items-center justify-content-center pt-4">
          <img
            src="images/new-logo.png"
            alt=""
            className="me-1"
            onClick={() => navigate("/chooseuser")}
          />
        </div>
        <div className="form_cont">
          <h3>Login</h3>
          <div className="login_form">
            <form onSubmit={onSubmit}>
              <Input.Wrapper
                label="Email Address"
                description=""
                className="mb-4"
                size="md"
              >
                <Tooltip
                  label="Please enter a valid email address (e.g., example@example.com)"
                  position="right"
                >
                  <Input
                    variant="filled"
                    placeholder="Enter Email"
                    size="lg"
                    radius="md"
                    name="email"
                    value={email}
                    onChange={onChange}
                    error={isError}
                    required
                  />
                </Tooltip>
              </Input.Wrapper>
              <Input.Wrapper
                label="Password"
                description=""
                className="mb-2"
                size="md"
                error={isError ? "Invalid Credentials" : ""}
              >
                <Tooltip
                  label="Password must be 8 characters long  and contain alphanumeric characters"
                  position="right"
                >
                  <PasswordInput
                    placeholder="Enter Password"
                    size="lg"
                    radius="md"
                    name="password"
                    value={password}
                    onChange={onChange}
                    error={isError}
                    required
                  />
                </Tooltip>
              </Input.Wrapper>
              <div className="forgot_password d-flex align-items-center justify-content-end">
                <p onClick={open}>Forgot Password</p>
              </div>
              <div className="button_cont d-flex align-items-center justify-content-center mb-3">
                <Button
                  variant="filled"
                  color="rgba(0, 155, 58, 1)"
                  type="submit"
                  loading={isLoading}
                >
                  Login
                </Button>
              </div>
              <div className="terms_conditions d-flex align-items-center justify-content-start">
                <Checkbox
                  size="xs"
                  defaultChecked
                  label="I agree to terms & conditions"
                  radius="xl"
                  color="rgba(0, 155, 58, 1)"
                  iconColor="black"
                  required
                />
              </div>
            </form>
          </div>
        </div>
        {/* <Modal
          opened={opened}
          onClose={close}
          title="Reset Password"
          centered
          size={"lg"}
        >
          <Input.Wrapper
            label="Password"
            description=""
            className="mb-4 mx-3"
            size="md"
            error={isError ? "Invalid Credentials" : ""}
          >
            <PasswordInput
              placeholder="Enter Password"
              size="lg"
              radius="md"
              name="password"
              error={isError}
            />
          </Input.Wrapper>
          <Input.Wrapper
            label="Confirm Password"
            description=""
            className="mb-4 mx-3"
            size="md"
            error={isError ? "Invalid Credentials" : ""}
          >
            <PasswordInput
              placeholder="Enter Password"
              size="lg"
              radius="md"
              name="password"
              error={isError}
            />
          </Input.Wrapper>
        </Modal> */}
        <Modal
          opened={opened}
          onClose={handleCloseModal}
          title="Reset Password"
          centered
          size={"lg"}
        >
          <Input
            value={forgotPasswordEmail}
            onChange={(e) => setForgotPasswordEmail(e.target.value)}
            placeholder="Enter your email"
            type="email"
            label="Email"
            required
            className="mb-4 mx-3"
            size="md"
          />
          <div className="sendemail">
            <Button
              size="md"
              className="mb-4 mx-3"
              variant="filled"
              color="rgba(0, 155, 58, 1)"
              onClick={sendResetPasswordEmail}
              loading={isSendingEmail}
            >
              Send Reset link
            </Button>
          </div>
        </Modal>
        ;
      </div>
    </>
  );
};

export default Login;
