import axios from "axios";

const port = process.env.REACT_APP_IP_ADDRESS;
const API_URL = `${port}/api/users/`;

// Register user
const register = async (userData) => {
  const response = await axios.post(
    API_URL + userData.type + `/signup`,
    userData
  );

  if (response.data) {
    sessionStorage.setItem("user", JSON.stringify(response.data));
  }

  return response.data;
};

// Login user
const login = async (userData) => {
  console.log(API_URL);
  const response = await axios.post(
    API_URL + userData.type + `/login`,
    userData
  );

  if (response.data) {
    sessionStorage.setItem("user", JSON.stringify(response.data));
  }
  console.log(response.data);

  return response.data;
};

const update = async (userData) => {
  const response = await axios.post(API_URL + userData.type + "/getUser", {
    id: userData._id,
  });

  if (response.data) {
    sessionStorage.setItem("user", JSON.stringify(response.data));
  }

  return response.data;
};
// Logout user
const logout = () => {
  sessionStorage.removeItem("user");
};

const authService = {
  register,
  logout,
  login,
  update,
};

export default authService;
