import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import sanityClient from "../client";
import BlockContent from "@sanity/block-content-to-react";
import imageUrlBuilder from "@sanity/image-url";
// import { format } from "date-fns"; // Importing directly to format dates

const builder = imageUrlBuilder(sanityClient);

function urlFor(source) {
  return builder.image(source);
}

function renderVideo(video) {
  if (!video || !video.asset || !video.asset.url) {
    console.log("Video data is missing or malformed:", video);
    return null;
  }
  return <video controls src={video.asset.url} style={{ width: "100%" }} />;
}

function renderLinks(links) {
  return links.map((link, index) => (
    <a key={index} href={link} target="_blank" rel="noopener noreferrer">
      {link}
    </a>
  ));
}

const BlogDetails = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const slug = params.get("slug");
  const [postData, setPostData] = useState(null);
  const [relatedPosts, setRelatedPosts] = useState([]);

  useEffect(() => {
    if (slug) {
      sanityClient
        .fetch(
          `*[slug.current == "${slug}"]{
          title,
          mainHeading,
          subHeadings[]{heading, text},
          slug,
          mainImage{asset->{_id, url}},
          popularPosts[]{rank, postTitle, link},
          additionalImages,
          backgroundImage{asset->{_id, url}},
          author->{name, image, bio},
          publishedAt,
          categories[]->{_ref, title, description},
          body,
          websites[]->{name},
          backgroundColor,
          textColor,
          video{asset->{_id, url}},
          videos[]{title, description, link},
          firstHeading,
          secondHeading,
          links,
          embeddedContent,
          excerpt,
          tags,
          isFeatured
        }`
        )
        .then((data) => {
          setPostData(data[0]);
          if (data[0].categories && data[0].categories.length > 0) {
            const firstCategoryTitle = data[0].categories[0].title;
            sanityClient
              .fetch(
                `*[_type == "post" && $firstCategoryTitle in categories[]->title &&
                websites[references(*[_type == "website" && name match "Athlantix*"]._id)]
              ] {
                title,
                slug,
                publishedAt,
                categories[]->{title},
                websites[]->{name}
              }`,
                { firstCategoryTitle }
              )
              .then(setRelatedPosts)
              .then((relatedPosts) =>
                console.log("related posts", relatedPosts)
              )
              .catch(console.error);
          }
        })
        .catch(console.error);
    }
  }, [slug]);

  if (!postData) return <div></div>;

  const backgroundStyles = {
    backgroundColor: postData.backgroundColor || "inherit",
    color: postData.textColor || "inherit",
    backgroundImage: postData.backgroundImage
      ? `url(${urlFor(postData.backgroundImage).url()})`
      : "none",
    backgroundSize: "cover",
    backgroundPosition: "center",
  };

  const serializers = {
    types: {
      block: (props) => {
        switch (props.node.style) {
          case "h1":
            return <h1 className="text-4xl ">{props.children}</h1>;
          case "h2":
            return <h2 className="text-3xl ">{props.children}</h2>;
          case "h3":
            return <h3 className="text-2xl ">{props.children}</h3>;
          case "blockquote":
            return (
              <blockquote className="pl-4 my-4 italic border-l-4 border-gray-200">
                {props.children}
              </blockquote>
            );
          case "bullet":
          case "list-item":
            return <li className="ml-4 list-disc">{props.children}</li>;
          case "normal":
          default:
            return <p className="text-base">{props.children}</p>;
        }
      },
      list: {
        bullet: (props) => (
          <ul className="my-2 ml-5 list-disc">{props.children}</ul>
        ),
        number: (props) => (
          <ol className="my-2 ml-5 list-decimal">{props.children}</ol>
        ),
      },
      image: (props) => {
        const imageUrl = urlFor(props.node.asset).url(); // Utilizing Sanity's image builder, if you use one
        return (
          <div className="my-4">
            <img
              src={imageUrl}
              alt={props.node.alt || "Sanity image"}
              className="h-auto max-w-full"
            />
            {props.node.caption && (
              <p className="mt-2 text-sm text-center">{props.node.caption}</p>
            )}
          </div>
        );
      },
    },
    marks: {
      strong: (props) => <strong>{props.children}</strong>,
      em: (props) => <em>{props.children}</em>,
      link: (props) => (
        <a href={props.mark.href} className="text-blue-500 hover:text-blue-700">
          {props.children}
        </a>
      ),
    },
  };

  // Function to interleave paragraphs and subHeadings
  const renderBodyWithSubHeadings = (body, subHeadings) => {
    const content = [];
    let subHeadingIndex = 0;
    let paragraphCounter = 0;

    body?.forEach((block, index) => {
      content.push(
        <div key={index} className="font-kanit">
          <BlockContent
            blocks={[block]}
            serializers={serializers}
            projectId={sanityClient.clientConfig?.projectId}
            dataset={sanityClient.clientConfig?.dataset}
          />
          <br />
        </div>
      );
      paragraphCounter++;

      if (paragraphCounter === 3 && subHeadingIndex < subHeadings?.length) {
        const subHeading = subHeadings[subHeadingIndex];
        content.push(
          <div
            className="flex flex-col mt-4 mb-4 space-x-0 md:flex-row md:pr-6 md:space-x-6 font-kanit"
            key={`subheading-${subHeadingIndex}`}
          >
            <p className="text-xl font-semibold md:text-3xl font-kanit">
              {subHeading.heading}
            </p>
            <p className="text-md md:text-lg font-kanit">{subHeading.text}</p>
          </div>
        );
        subHeadingIndex++;
        paragraphCounter = 0; // Reset counter after adding a subheading
      }
    });

    return content;
  };

  return (
    <>
      <div>
        <div className="px-[1rem] md:px-[3rem] lg:px-[12rem] xl:px-[25rem]">
          <div className="pt-10 pb-6 md:pt-10">
            <div className="m-4">
              <img src="images/three-stars.png" />
            </div>
            <p className="text-3xl font-bold md:text-5xl font-kanit">
              {postData?.title}
            </p>
          </div>
          <div className="flex flex-col space-x-0 md:flex-row md:space-x-8">
            <div className="h-[26.6rem]  w-[41.2rem] flex-shrink-0">
              <img
                src={urlFor(postData?.mainImage).url()}
                alt="Main"
                className="object-cover w-full h-full"
              />
            </div>
            <div className="flex md:h-[26.6rem] space-y-6 md:space-y-0 flex-col justify-between w-full md:w-80">
              <div>
                {" "}
                {/* Align main heading at the start of the image */}
                <p className="text-xl font-semibold text-left md:text-3xl font-kanit">
                  <span className="text-blue-700">/ </span>
                  {postData?.mainHeading}
                </p>
              </div>
              <div>
                {" "}
                {/* Align remaining content at the bottom end of the image */}
                <p className="text-sm text-gray-600 font-kanit">
                  By{" "}
                  <span className="text-blue-700 font-kanit">
                    {postData?.author?.name}
                  </span>
                  ,
                  {postData.author?.bio && (
                    <div className="font-kanit">
                      <BlockContent
                        blocks={postData.author?.bio}
                        projectId={sanityClient.clientConfig?.projectId}
                        dataset={sanityClient.clientConfig?.dataset}
                      />
                    </div>
                  )}
                </p>
                <p className="text-xs text-gray-600 md:text-sm font-kanit">
                  {postData.publishedAt
                    ? new Date(postData.publishedAt).toLocaleDateString(
                        "en-US",
                        {
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                        }
                      )
                    : "No date available"}
                </p>
              </div>
            </div>
          </div>

          <div className="flex flex-col space-y-4 md:flex-row md:space-x-4 md:space-y-0">
            <div className="pt-4 pl-4 pr-4 md:pt-16 md:pr-6 md:w-[46rem] w-full">
              {renderBodyWithSubHeadings(postData.body, postData.subHeadings)}
            </div>
            <div className="w-full mt-4 md:w-56">
              <p className="mt-0 mb-4 text-lg text-blue-600 md:mt-32 font-kanit">
                Most Popular
              </p>
              <div className="space-y-4">
                {postData?.popularPosts?.length > 0 ? (
                  postData.popularPosts.map((post) => (
                    <div
                      key={post?.rank}
                      className="flex flex-row p-2 space-x-4 border-b-2 border-blue-700"
                    >
                      <p className="font-bold text-blue-700 font-kanit">
                        {post?.rank}
                      </p>
                      <a
                        href={post?.link}
                        style={{
                          fontFamily: "Kanit, sans-serif",
                          textDecoration: "none",
                          color: "black",
                          fontWeight: "bold",
                          ":hover": {
                            color: "blue",
                          },
                        }}
                        className="hover:text-blue-500"
                      >
                        {post?.postTitle}
                      </a>
                    </div>
                  ))
                ) : (
                  <p className="text-gray-600">No popular posts available</p>
                )}
              </div>
            </div>
          </div>

          <div className="px-4 pt-4 md:pt-16 md:pr-96">
            <div className="flex flex-col p-4 space-x-4 border-blue-700 md:space-x-0 md:space-y-4 border-y-2 md:flex-col">
              <div className="inline-block p-2 bg-blue-700">
                <p className="text-white font-kanit">
                  Featured Videos from the Verge
                </p>
              </div>
              {postData?.videos?.length > 0 ? (
                postData.videos.map((video, index) => (
                  <div className="flex-1" key={index}>
                    <p className="my-4 text-2xl font-bold md:text-4xl">
                      {video?.title}
                    </p>
                    <div className="my-4">
                      <iframe
                        className="w-full aspect-video"
                        src={video?.link}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      ></iframe>
                    </div>
                    <p className="text-sm md:text-base">{video?.description}</p>
                  </div>
                ))
              ) : (
                <p className="text-gray-600">No videos available</p>
              )}
            </div>
          </div>
        </div>

        <div className="bg-[#2c5178] flex flex-col md:flex-row justify-center items-center my-10 py-8">
          <div className="flex flex-col max-w-md mx-auto space-y-5 md:max-w-none">
            {relatedPosts.length > 0 ? (
              relatedPosts.map((post) => (
                <div
                  key={post?.slug}
                  className="my-4 space-y-4 border-b-2 border-white "
                >
                  <a
                    href={`/blog-details?slug=${post.slug.current}`}
                    className="text-2xl font-bold text-white md:text-4xl hover:underline font-kanit"
                    style={{ textDecoration: "none" }}
                  >
                    {post?.title}
                  </a>

                  <p className="text-sm text-white md:text-sm font-kanit">
                    {post.publishedAt
                      ? new Date(post.publishedAt).toLocaleDateString("en-US", {
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                        })
                      : "No date available"}
                  </p>
                </div>
              ))
            ) : (
              <div className="p-4 my-5 space-y-4 border-b-2 border-white">
                <p className="text-2xl font-bold text-white md:text-4xl font-kanit">
                  No related posts found.
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogDetails;
