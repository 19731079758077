import React, { useState } from "react";
import "./VideoFeatureSliderStyles.css"; // Ensure the CSS file is correctly imported
import { Button } from "@mantine/core";

const VideoFeatureSlider = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [prevIndex, setPrevIndex] = useState(0);
  const images = [
    "/images/feature_video_image1.png",
    "/images/feature_video_image2.png",
    "/images/feature_video_image3.png",
  ];

  const buttonImages = [
    "/images/video-slider-button1.png",
    "/images/video-slider-button2.png",
    "/images/video-slider-button3.png",
  ];

  // const texts = [
  //   "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The here, content here', making it look like readable English.",
  //   "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The here, content here', making it look like readable English.",
  //   "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The here, content here', making it look like readable English.",
  // ];
  const texts = ['','','']
  const handleImageClick = (index) => {
    setPrevIndex(activeIndex); // Set previous index to current before changing
    setActiveIndex(index);
  };

  return (
    <div className="container">
      <div className="left-column">
        {texts.map((text, index) => (
          <div
            className={`video-row ${activeIndex === index ? "expanded" : ""}`}
            key={index}
            role="button"
            tabIndex={0}
            onClick={() => handleImageClick(index)}
          >
            <div className="my-items">
              {" "}
              <Button variant="transparent" style={{ height: "95px" }}>
                <img
                  src={buttonImages[index]}
                  alt={`Button Image ${index + 1}`}
                  style={{ cursor: "pointer", display: "block" }}
                />
              </Button>
              <div className="content">
                {activeIndex === index && (
                  <p
                    className="text"
                    style={{ color: "#9d9fa6", fontSize: "17px" }}
                  >
                    {text}
                  </p>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="right-column">
        <div className="image-container">
          <img
            key={prevIndex}
            src={images[prevIndex]}
            alt={`Image ${prevIndex + 1}`}
            className="fading-out"
          />
          <img
            key={activeIndex}
            src={images[activeIndex]}
            alt={`Image ${activeIndex + 1}`}
            className="fading-in"
          />
        </div>
      </div>
    </div>
  );
};

export default VideoFeatureSlider;
