import React from "react";
import ContentLoader from "react-content-loader";

const CoachSkelletonLoader = (props) => (
  <ContentLoader
    speed={2}
    width={1150}
    height={500}
    viewBox="0 0 1150 500"
    backgroundColor="#40424f33"
    foregroundColor="#5b5b5f33"
    {...props}
  >
    <rect x="5" y="5" rx="23" ry="23" width="500" height="200" />

    <rect x="525" y="5" rx="23" ry="23" width="500" height="200" />

    <rect x="5" y="225" rx="23" ry="23" width="500" height="200" />

    <rect x="525" y="225" rx="23" ry="23" width="500" height="200" />
  </ContentLoader>
);

export default CoachSkelletonLoader;
