import React, { useState } from "react";
import { Input, Button, Textarea } from "@mantine/core";
import "../styles/HomePageStyles.scss";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { Menu, MenuItem, ListItemIcon, ListItemText } from "@mui/material";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";

import {
  faLocationDot,
  faPhone,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faInstagram,
  faXTwitter,
} from "@fortawesome/free-brands-svg-icons";
//swiper

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
      });
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
};
const HomePage = () => {
  const [rescipent, setrescipent] = useState("Institute"); //institute or private coaching
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (feature) => {
    // Handle the selection here, e.g., navigate to the selected feature
    console.log(`Selected feature: ${feature}`);
    handleClose();
  };
  const navtohome = () => {
    navigate("/");
  };
  const navtodash = () => {
    navigate("/chooseuser");
  };
  const navtocontactus = () => {
    navigate("/contactus");
  };
  const navtopracticefeature = () => {
    navigate("/practice-features");
  };
  const navtotrainingfeature = () => {
    navigate("/training-features");
  };
  const navtovideofeature = () => {
    navigate("/video-features");
  };
  const navtopricing = () => {
    navigate("/pricing");
  };
  const navtoBlog = () => {
    navigate("/blog");
  };

  const { width } = useWindowSize();
  return (
    <div className="home_cont">
      <div className="landing">
        <div id="elipse_1"></div>
        <div className="landing_top d-flex align-items-center justify-content-sm-between justify-content-center">
          <img src="images/Vector.png" alt="" id="top_logo" />
          <div className="navbar">
            <Button
              variant="transparent"
              size="lg"
              color="gray"
              onClick={navtohome}
              id="aboutUs_btn"
            >
              Home
            </Button>
            {/* <Button
              variant="transparent"
              size="lg"
              color="gray"
              onClick={navtofeatures}
              id="features_btn"
            >
              Features
            </Button>{" "} */}
            <Button
              variant="transparent"
              size="lg"
              color="gray"
              //   aria-controls="features-menu"
              //   aria-haspopup="true"
              onClick={handleClick}
              id="features_btn"
              rightSection={<FontAwesomeIcon icon={faAngleDown} />}
            >
              Features
            </Button>
            <Menu
              id="features-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              PaperProps={{
                style: {
                  background: "transparent",
                  boxShadow: "none",
                },
              }}
            >
              <MenuItem onClick={() => handleMenuItemClick("Feature 1")}>
                <Button
                  variant="transparent"
                  size="md"
                  color="gray"
                  onClick={navtopracticefeature}
                  id="pricing_btn"
                >
                  Practice
                </Button>{" "}
              </MenuItem>
              <MenuItem onClick={() => handleMenuItemClick("Feature 2")}>
                <Button
                  variant="transparent"
                  size="md"
                  color="gray"
                  onClick={navtotrainingfeature}
                  id="pricing_btn"
                >
                  Training
                </Button>
              </MenuItem>
              <MenuItem onClick={() => handleMenuItemClick("Feature 3")}>
                <Button
                  variant="transparent"
                  size="md"
                  color="gray"
                  onClick={navtovideofeature}
                  id="pricing_btn"
                >
                  Video
                </Button>{" "}
              </MenuItem>
            </Menu>
            <Button
              variant="transparent"
              size="lg"
              color="gray"
              onClick={navtopricing}
              id="pricing_btn"
            >
              Pricing
            </Button>{" "}
            <Button
              variant="transparent"
              size="lg"
              color="gray"
              onClick={navtocontactus}
              id="contactUs_btn"
            >
              Contact us
            </Button>
            <Button
              variant="transparent"
              size="lg"
              color="gray"
              onClick={navtoBlog}
              id="contactUs_btn"
            >
              Blog
            </Button>
          </div>

          <Button
            variant="filled"
            size="lg"
            radius={"xl"}
            color="#0C0F21"
            onClick={navtodash}
            id="dashboard_btn"
          >
            Dashboard
          </Button>
        </div>
        <div className="landing_main d-flex flex-column align-items-center">
          <p className="spaced_text">ELEVATE TO EXCELLENCE</p>
          <h1 className="headline">
            Full-Spectrum <span>Analytics</span> for Ultimate
            <br /> <span> Team</span> Success
          </h1>
          <p className="main_text">
            Embrace the Future of Football with <span>Athlantix!</span> Our
            intuitive platform is tailored to arm <br />{" "}
            <span>players, coaches</span>, and parents with insights like never
            before. Get ready to gain a <br /> competitive edge and see the game
            through the lens of <span>advanced analytics</span>.
          </p>
          <div className="mt-4 button_cont">
            {" "}
            <Button
              variant="gradient"
              gradient={{ from: "#009B3A", to: "#2FED76", deg: 90 }}
              style={{ color: "black" }}
              size="xl"
              radius={"xl"}
              fullWidth
              onClick={navtocontactus}
            >
              Request a Demo
            </Button>
          </div>
        </div>
        <div className="laptop_cont d-flex align-items-center justify-content-center">
          <img src="images/laptop_home.png" id="laptop_image" alt="" />
          <>
            <Swiper
              slidesPerView={width < 786 ? 2 : 6}
              spaceBetween={0}
              loop={true}
              autoplay={{
                delay: 1,
                disableOnInteraction: false,
              }}
              freeMode={true}
              freeModeMomentum={false}
              speed={5000}
              modules={[Autoplay]}
              className="mySwiper"
            >
              <SwiperSlide>
                <p className="slider_text">
                  ATHLANTIX <span>.</span>
                </p>
              </SwiperSlide>

              <SwiperSlide>
                <p className="slider_text">
                  ATHLANTIX <span>.</span>
                </p>
              </SwiperSlide>
              <SwiperSlide>
                <p className="slider_text">
                  ATHLANTIX <span>.</span>
                </p>
              </SwiperSlide>
              <SwiperSlide>
                <p className="slider_text">
                  ATHLANTIX <span>.</span>
                </p>
              </SwiperSlide>
              <SwiperSlide>
                <p className="slider_text">
                  ATHLANTIX <span>.</span>
                </p>
              </SwiperSlide>

              <SwiperSlide>
                <p className="slider_text">
                  ATHLANTIX <span>.</span>
                </p>
              </SwiperSlide>
              <SwiperSlide>
                <p className="slider_text">
                  ATHLANTIX <span>.</span>
                </p>
              </SwiperSlide>
              <SwiperSlide>
                <p className="slider_text">
                  ATHLANTIX <span>.</span>
                </p>
              </SwiperSlide>
            </Swiper>
          </>
        </div>
      </div>
      <div className="parental_engagement d-flex flex-column align-items-center justify-content-sm-center justify-content-start">
        <h1 className="headline">
          Building Trust and Belief Through
          <br /> Active <span>Parental Engagement</span>
        </h1>
        <p className="main_text">
          <span>
            Experience the power of direct participation in your child’s sports
            career.{" "}
          </span>
        </p>
        <p className="main_text">
          Witness every <span>pass, goal,</span> and <span>victory</span> with
          insights as an integral part of their journey, helping to <br /> build
          <span> trust, confidence,</span> and a <span>stronger bond</span> with
          the institute.
        </p>
        <div className="mt-4 button_cont">
          {" "}
          <Button
            variant="gradient"
            gradient={{ from: "#009B3A", to: "#2FED76", deg: 90 }}
            style={{ color: "black" }}
            size="xl"
            radius={"xl"}
            fullWidth
            onClick={navtodash}
          >
            Experience Athlantix
          </Button>
        </div>
      </div>
      <div className="practice_based_prediction d-flex flex-column align-items-center justify-content-center ">
        <div className="self_image">
          <div className="custompill" id="Accuracy">
            <p>Accuracy</p>
          </div>
          <div className="custompill" id="footwork">
            <p>Footwork</p>
          </div>
          <div className="custompill" id="Mobility">
            <p>Mobility</p>
          </div>
          <div className="custompill" id="Arm_Strength">
            <p>Arm Strength</p>
          </div>
          <div className="custompill" id="Empty_1_left"></div>
          <div className="custompill" id="Timming">
            <p>Timming</p>
          </div>
          <div className="custompill" id="Progression">
            <p>Progression</p>
          </div>
          <div className="custompill" id="Precision">
            <p>Precision</p>
          </div>
          <div className="custompill" id="Agile">
            <p>Agile</p>
          </div>
          <div className="custompill" id="Empty_2_right"></div>
          <div className="custompill" id="Dynamic">
            <p>Dynamic</p>
          </div>
          <div className="custompill" id="Potent">
            <p>Potent</p>
          </div>
          <div className="custompill" id="Empty_2_rightt"></div>
          <div className="custompill" id="Synchronized">
            <p>Synchronized</p>
          </div>
          <div className="custompill" id="Evolving">
            <p>Evolving</p>
          </div>
          <div className="line_box_left" id="line_box_left_1">
            <div className="line_box_inner">
              <img src="images/down_arrow.png" id="arrow_left_1" alt="" />
            </div>
          </div>
          <div className="line_box_left" id="line_box_left_2">
            <div className="line_box_inner">
              <img src="images/down_arrow.png" id="arrow_left_2" alt="" />
            </div>
          </div>
          <div className="line_box_left" id="line_box_left_3">
            <div className="line_box_inner">
              <img src="images/down_arrow.png" id="arrow_left_3" alt="" />
            </div>
          </div>
          <div className="line_box_right" id="line_box_right_1">
            <div className="line_box_inner">
              <img src="images/up_arrow.png" id="arrow_right_1" alt="" />
            </div>
          </div>
          <div className="line_box_right" id="line_box_right_2">
            <div className="line_box_inner">
              <img src="images/up_arrow.png" id="arrow_right_2" alt="" />
            </div>
          </div>
          <div className="line_box_right" id="line_box_right_3">
            <div className="line_box_inner">
              <img src="images/up_arrow.png" id="arrow_right_3" alt="" />
            </div>
          </div>
        </div>

        <div id="elipse_2"></div>

        <h1>
          PRACTICE BASED <br /> PREDICTION
        </h1>
      </div>
      <div className="management_tools d-flex flex-column align-items-center justify-content-sm-center justify-content-start ">
        <h1 className="headline">
          Streamline Your Team's Dynamics with
          <br /> <span>Intuitive Management Tools</span>
        </h1>
        <p className="main_text">
          Whether you're coordinating a single team or overseeing multiple
          squads, our tools are tailored to streamline your <br />{" "}
          <span>administrative tasks,</span> leaving more time for what truly
          matters - coaching and developing your players. Athlantix brings{" "}
          <br /> a new level of <span>ease to team management.</span>
        </p>
        <div className="mt-4 button_cont">
          {" "}
          <Button
            variant="gradient"
            gradient={{ from: "#009B3A", to: "#2FED76", deg: 90 }}
            style={{ color: "black" }}
            size="xl"
            radius={"xl"}
            fullWidth
            onClick={navtodash}
          >
            Join Athlantix Now
          </Button>
        </div>
      </div>
      <div className="features d-flex align-items-center justify-content_center">
        <div className="features_cont d-flex align-items-center justify-content-evenly flex-sm-row flex-column">
          <div className="pt-5 feature d-flex flex-column align-items-center ">
            <img src="images/process.png" className="mb-3" alt="" />
            <h2>Manage</h2>
            <p>
              Organize player and coach profiles
              <br /> efficiently by our efficient system
              <br /> that saves time and enhances
              <br /> focus.
            </p>
          </div>
          <div className="pt-5 feature d-flex flex-column align-items-center ">
            <img src="images/growth.png" className="mb-3" alt="" />
            <h2>Track</h2>
            <p>
              Ensure no aspect of the game <br /> goes unnoticed or unanalyzed{" "}
              <br /> as we help meticulously monitor
              <br /> every play, strategy, and
              <br /> outcome!
            </p>
          </div>
          <div className="pt-5 feature d-flex flex-column align-items-center ">
            <img src="images/link.png" className="mb-3" alt="" />
            <h2>Connect</h2>
            <p>
              Break down barriers by fostering
              <br /> a robust communication channel <br /> between parents and
              coaches!
            </p>
          </div>
        </div>
      </div>
      <div className="insights d-flex align-items-start justify-content-between ps-sm-5 flex-sm-row flex-column">
        <div className="insights_text">
          <h1 className="headline mb-sm-5">
            Empower Your <span>Team - In-Depth</span>
            <br /> Insights & Analysis
          </h1>
          <p className="main_text mb-sm-5">
            From training intensity to game-day performance, <span>track</span>{" "}
            and
            <br /> <span>analyze</span> every detail across multiple teams and
            seasons.
          </p>
          <p className="main_text ">
            Athlantix transforms{" "}
            <span>raw data into visual, interactive charts,</span>
            <br />
            graphs, and <span>real-time statistics,</span> giving coaches and
            players
            <br /> the insights needed to excel.
          </p>
        </div>
        <div className="insights_image">
          <img src="images/name_card.png" id="name_card" alt="" />
          <img src="images/ring_prog.png" id="ring_prog" alt="" />
          <img src="images/bar_prog.png" id="bar_prog" alt="" />
          <div id="elipse_3"></div>
        </div>
      </div>

      <div className="instant_messaging">
        <div className="messaging d-flex flex-column align-items-center justify-content-start">
          <h1 className="mb-5 headline">
            Introducing <span>Seamless</span> Messaging
          </h1>
          <p className="mb-5 main_text">
            Athlantix elevates team communication to new heights with its
            integrated messaging system,
            <br /> designed to keep players, coaches, and staff connected in
            real-time.
            <br /> Available across all Athlantix plans, this tool is essential
            for maintaining a cohesive and responsive
            <br /> team environment.
          </p>
          <h3>Enhance Your Team's Connectivity</h3>
          <div className="mt-4 button_cont">
            {" "}
            <Button
              variant="gradient"
              gradient={{ from: "#009B3A", to: "#2FED76", deg: 90 }}
              style={{ color: "black" }}
              size="xl"
              radius={"xl"}
              fullWidth
              onClick={navtodash}
            >
              Join Athlantix Now
            </Button>
          </div>
          <div id="elipse_4"></div>
        </div>
        <div className="diagram">
          <div
            className="users d-flex align-items-center justify-content-between"
            id="user_top"
          >
            <div id="connection_top_left"></div>
            <div id="connection_top_right"></div>
            <div id="connection_center"></div>
            <div className="user_cont d-flex flex-column align-items-center justify-content-center">
              <img src="images/choose-player.png" alt="" />
              <p className="mt-4">Player</p>

              <div className="user_elipse"></div>
            </div>
            <div className="user_cont d-flex flex-column align-items-center justify-content-center">
              <img src="images/choose-coach.png" alt="" />
              <p className="mt-4">Coach</p>

              <div className="user_elipse"></div>
            </div>
            <div className="user_cont d-flex flex-column align-items-center justify-content-center">
              <img src="images/choose-parent.png" alt="" />
              <p className="mt-4">Parent</p>

              <div className="user_elipse"></div>
            </div>
          </div>
          <div className="users d-flex align-items-center justify-content-center">
            <div className="user_cont d-flex flex-column align-items-center justify-content-center">
              <img src="images/Vector-2.png" alt="" />
              <p className="mt-4">Athlantix</p>

              <div className="user_elipse"></div>
            </div>
            <div id="connection_bottom_left"></div>
            <div id="connection_bottom_right"></div>
          </div>
        </div>
      </div>
      <div className="cta_form d-flex flex-column align-items-center">
        <h1 className="mb-5">Contact us</h1>
        <p className="main_text">
          Join the Athlantix community and take control of your American
          football journey. Sign <br /> up for a <span>free trial</span> today!
        </p>
        <div className="pt-5 form_outter d-flex flex-column align-items-center px-sm-5">
          <div className="choose_recepient d-flex align-items-center justify-content-between ">
            <h4
              onClick={() => setrescipent("Institute")}
              className={`${rescipent == "Institute" && "selected"}`}
            >
              Institute
            </h4>
            <h4
              onClick={() => setrescipent("Private Coaching")}
              className={`${rescipent == "Private Coaching" && "selected"}`}
            >
              Private Coaching
            </h4>
          </div>
          <div className="my-3 input_cont">
            <Input placeholder="Name" size="lg" radius={"xl"} />
          </div>
          <div className="my-3 input_cont">
            <Input placeholder="Email Address" size="lg" radius={"xl"} />
          </div>
          <div className="my-3 input_cont">
            <Input placeholder="Phone Number" size="lg" radius={"xl"} />
          </div>
          <div className="my-3 text_area_cont">
            <Textarea
              placeholder="Description"
              size="lg"
              radius={"lg"}
              autosize
              minRows={4}
            />
          </div>
          <div className="button_cont">
            <Button
              fullWidth
              variant="filled"
              color="#0C0F21"
              size="md"
              radius={"xl"}
            >
              Request a Demo
            </Button>
          </div>
        </div>
        <div id="elipse_5"></div>
      </div>
      <div className="footer d-flex flex-column align-items-center justify-conetnt-center">
        <div className="logo_cont">
          <img src="images/Vector-2.png" alt="" />
        </div>
        <div className="tag_cont d-flex align-items-center justify-content-start flex-sm-row flex-column">
          <div className="d-flex flex-column align-items-start justify-content-start ">
            <div className="mb-4 tag d-flex ">
              <FontAwesomeIcon
                icon={faLocationDot}
                className="me-3"
                size="lg"
              />
              <p>Texas</p>
            </div>

            <div className="mb-4 tag d-flex ">
              <FontAwesomeIcon icon={faEnvelope} className="me-3" size="lg" />
              <p>contact@athlantix.com</p>
            </div>
          </div>
          <div className="d-flex flex-column align-items-start justify-content-start sitemap">
            <div className="mb-4 tag d-flex ">
              <p>Home</p>
            </div>
            <div className="mb-4 tag d-flex ">
              <p>Features </p>
            </div>
            <div className="mb-4 tag d-flex ">
              <p>Pricing</p>
            </div>
            <div className="mb-4 tag d-flex ">
              <p> Testimonials</p>
            </div>
          </div>
          {/* <div className="d-flex flex-column align-items-start justify-content-start">
            <div className="mb-4 tag d-flex ">
              <FontAwesomeIcon icon={faFacebook} className="me-3" size="lg" />
              <p>www.facebook.com</p>
            </div>
            <div className="mb-4 tag d-flex ">
              <FontAwesomeIcon icon={faInstagram} className="me-3" size="lg" />
              <p>www.instagram.com </p>
            </div>
            <div className="mb-4 tag d-flex ">
              <FontAwesomeIcon icon={faXTwitter} className="me-3" size="lg" />
              <p>www.twitter.com</p>
            </div>
          </div> */}
        </div>
        <div className="copyright d-flex align-items-center justify-content-center">
          <p>Copyright © 2024 Athlantix. All rights reserved.</p>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
