import Onboarding from "./pages/Onboarding";
import Dashboard from "./pages/Dashboard";
import "@mantine/dropzone/styles.css";
import Signup from "./pages/Signup";
import Login from "./pages/Login";
import ScoutDashboard from "./pages/ScoutDashboard";
import CoachDashboard from "./pages/CoachDashboard";
import InstituteDash from "./pages/InstituteDash";
import Signupbyinst from "./pages/Signupbyinst";
import ParentsDash from "./pages/ParentsDash";
import Pricing from "./pages/Pricing";
import SignupCoach from "./pages/SignupCoach";
import Download from "./pages/Download";
import BlogV2Dark from "./pages/blog";
import InstSignup from "./pages/InstSignup";
import NewChooseUser from "./pages/NewChooseUser";
import Confirmation from "./pages/Confirmation";
import BlogDetails from "./pages/blog-details";
import VideoEditing from "./pages/VideoEditing";
import ContactUs from "./pages/ContactUs";
import SignupCoachbyinst from "./pages/SignupCoachbyinst";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePage from "./pages/HomePage";
import PracticeFeaturesPage from "./pages/PracticeFeaturesPage";
import PricingPage from "./pages/PricingPage";
import TrainingFeaturesPage from "./pages/TrainingFeaturesPage";
import VideoFeaturePage from "./pages/VideoFeaturePage";
import PhoneError from "./pages/PhoneError";
import { useState, useEffect } from "react";
import VaDash from "./pages/VaDash";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import VaVideoEditing from "./pages/VaVideoEditing";
import StudentVideoEditing from "./pages/StudentVideoEditing";
import ResetPassword from "./pages/ResetPassword";
import CreateAcc from "./pages/CreateAcc";
import NewSignUp from "./pages/NewSignUp";

const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
      });
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
};

function App() {
  const { width } = useWindowSize();
  console.log("width", width);
  // if (width < 786) {
  //   return <PhoneError />;
  // }
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/practice-features" element={<PracticeFeaturesPage />} />
          <Route path="/training-features" element={<TrainingFeaturesPage />} />
          <Route path="/video-features" element={<VideoFeaturePage />} />
          <Route path="/NewSignup" element={<NewSignUp />} />

          <Route path="/pricing" element={<PricingPage />} />

          <Route path="/contactus" element={<ContactUs />} />
          <Route path="/onboarding" element={<Onboarding />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/chooseuser" element={<NewChooseUser />} />
          <Route path="/loginPlayer" element={<Login type="Player" />} />
          <Route path="/loginscout" element={<Login type="Scout" />} />
          <Route path="/loginInstitute" element={<Login type="Institute" />} />
          <Route path="/loginParent" element={<Login type="Parent" />} />
          <Route path="/loginCoach" element={<Login type="Coach" />} />
          <Route path="/signupplayer" element={<Signup type="Player" />} />
          {/* <Route path="/signupscout" element={<Signup type="Scout" />} /> */}
          <Route path="/signupcoach" element={<SignupCoach />} />
          <Route path="/resetPassword" element={<ResetPassword />} />
          <Route path="/download" element={<Download />} />
          <Route path="/CreateAcc/:Token?/:type?" element={<CreateAcc />} />

          <Route path="/parentdash" element={<ParentsDash />} />
          <Route path="/signupinst" element={<InstSignup />} />
          <Route path="/scout" element={<ScoutDashboard />} />
          <Route path="/coach" element={<CoachDashboard />} />
          <Route path="/inst" element={<InstituteDash />} />
          <Route path="/signupbyinst" element={<Signupbyinst />} />
          <Route path="/signupcoachbyinst" element={<SignupCoachbyinst />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/confirmation" element={<Confirmation />} />
          <Route path="/videoeditor" element={<VideoEditing />} />
          {/* va routes */}
          <Route path="/va" element={<Login type="VARouter" />} />
          <Route path="/vadash" element={<VaDash />} />
          <Route path="/vavideoeditor" element={<VaVideoEditing />} />
          <Route path="/stuvideo" element={<StudentVideoEditing />} />

          {/* blog */}
          <Route path="/blog" element={<BlogV2Dark />} />
          <Route path="/blog-details" element={<BlogDetails />} />
        </Routes>
      </Router>
      <ToastContainer />
    </>
  );
}

export default App;
