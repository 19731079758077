import React, { useState, useEffect } from "react";
import "../styles/ParentsDashstyles.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import { Avatar } from "@mantine/core";
import { Progress } from "@mantine/core";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../auth/authslice";
import { useNavigate } from "react-router-dom";
import { Skeleton, Button } from "@mantine/core";

import Messaging from "../components/Messaging";
import { faMessage } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
const ParentsDash = () => {
  const port = process.env.REACT_APP_IP_ADDRESS;
  const API_URL = `${port}/api/`;
  const { user } = useSelector((state) => state.auth);
  useEffect(() => {
    console.log(user);
  }, []);
  const [playerTraining, setplayertraining] = useState([]);
  const [loading, setloading] = useState(true);
  const getPlayerTraining = async (playerid) => {
    try {
      const res = await axios.post(`${API_URL}Training/getPlayerTraining`, {
        playerID: playerid,
      });
      console.log(res.data);
      setplayertraining((prevTraining) => [...prevTraining, ...res.data]);
    } catch (error) {
      console.log(error);
    }
  };
  const playertraininghelper = () => {
    setplayertraining([]);
    user?.playerID?.map((player) => {
      getPlayerTraining(player._id);
    });
  };
  const [activeIcon, setActiveIcon] = useState("Dashboard");
  useEffect(() => {
    playertraininghelper();
  }, []);
  useEffect(() => {
    console.log(playerTraining);
  }, [playerTraining]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleLogout = () => {
    dispatch(logout());
  };
  useEffect(() => {
    if (!user) {
      navigate("/loginParent");
    }
  }, [user]);
  return (
    <div className="dashboard_cont ">
      <div className="sidebar">
        <div className="sidebar_logo d-flex  align-items-center justify-content-center pt-4">
          <img src="/images/new-logo.png" alt="" className="me-1" />
        </div>
        <div className="sidebar_cont   d-flex flex-column justify-content-between ">
          <div className="sidebar_cont_top d-flex flex-column justify-content-start">
            {user?.playerID?.map((player, index) => (
              <div
                key={index}
                className="player_sidebar d-flex align-items-center justify-content-between px-2 py-1 mb-4"
                onClick={() => setActiveIcon("Dashboard")}
              >
                <img src={player?.Image} alt="" />
                <p>{player?.firstName + " " + player?.lastName}</p>
              </div>
            ))}
            <div
              className={`icon_cont mb-4 ms-2 ${
                activeIcon === "Message" ? "active_nav" : ""
              }`}
              onClick={() => setActiveIcon("Message")}
            >
              <FontAwesomeIcon icon={faMessage} />
              <p>Message</p>
            </div>
          </div>
          <div className="sidebar_cont_bottom">
            {" "}
            <div className="icon_cont" onClick={handleLogout}>
              <FontAwesomeIcon icon={faArrowRightFromBracket} /> <p>Logout</p>
            </div>
          </div>
        </div>
      </div>
      {activeIcon === "Dashboard" && (
        <div className={`scout_main px-4 py-3 `}>
          <div className="main_top_right d-flex justify-content-between align-items-center ">
            <div className="notification_cont"></div>
            <Avatar color="cyan" radius="xl">
              <div className="avatar_image_cont">
                <img src={user?.playerID[0]?.Image} alt="" />
              </div>
            </Avatar>
          </div>
          <div className="parents_main mt-5">
            <h2 className="mb-4">DashBoard</h2>
            <h4>12 November 2023</h4>
            {playerTraining.length === 0 && (
              <div style={{ textAlign: "center", marginTop: "15px" }}>
                <img
                  src="/images/DarkAnalytics-bro.png"
                  alt="No training available"
                />
                <h2 style={{ margin: "10px" }}>Welcome to your dashboard</h2>
                <p style={{ color: "#63656f" }}>
                  This dashboard is your window into your child's journey as a
                  football player, <br /> providing you with insights, updates,
                  and ways to stay engaged with their <br />
                  progress and team activities.
                </p>
              </div>
            )}
            {playerTraining?.map((currplayer, index) => (
              <>
                <div className="user_training d-flex align-items-center justify-content-center mb-3">
                  <div className="d-flex flex-column align-items-center justify-content-center player_image">
                    <img src={currplayer?.PlayerID?.Image} alt="" />
                    <p>
                      {currplayer?.PlayerID?.firstName +
                        " " +
                        currplayer?.PlayerID?.lastName}
                    </p>
                  </div>
                  <div className="d-flex flex-column align-items-center justify-content-center attendance px-3">
                    <div className="d-flex align-items-center justify-content-between pb-2 top_attendance ">
                      <div className="d-flex flex-column align-items-start justify-content center me-4">
                        <p className="complimnetary"> Attendance</p>
                        <p className="value">
                          {" "}
                          {currplayer?.TrainingObj?.Attendance
                            ? "Present"
                            : "Absent"}
                        </p>
                      </div>
                      <div className="d-flex flex-column align-items-start justify-content center ">
                        <p className="complimnetary"> Punctuality</p>
                        <p className="value">
                          {" "}
                          {currplayer?.TrainingObj?.Punctual
                            ? "Punctual"
                            : "Not Punctual"}
                        </p>
                      </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-between pt-2 ">
                      <div className="d-flex flex-column align-items-start justify-content center pe-3 me-5">
                        <p className="complimnetary"> Extra Time</p>
                        <p className="value">
                          {" "}
                          {currplayer?.TrainingObj?.ExtraTime ? "Yes" : "No"}
                        </p>
                      </div>
                      <div className="d-flex flex-column align-items-start justify-content center">
                        <p className="complimnetary"> Film</p>
                        <p className="value">
                          {" "}
                          {currplayer?.TrainingObj?.Film ? "Yes" : "No"}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex flex-column align-items-center justify-content-center metrics px-3">
                    <div className="d-flex flex-column   justify-content-center metric mb-2">
                      <p className="complimentary">Effort</p>
                      <div className="d-flex  justify-content-between metric_value">
                        <p className=" align-self-start"> 1-5</p>
                        <p className=" align-self-end highlight">
                          {(currplayer?.TrainingObj?.Film / 5) * 100 + "%"}
                        </p>
                      </div>
                      <Progress
                        color="green"
                        value={(currplayer?.TrainingObj?.Film / 5) * 100}
                      />
                    </div>
                    <div className="d-flex flex-column   justify-content-center metric mb-2">
                      <p className="complimentary">Coachable</p>
                      <div className="d-flex  justify-content-between metric_value">
                        <p className=" align-self-start"> 1-5</p>
                        <p className=" align-self-end highlight">
                          {(currplayer?.TrainingObj?.Coachable / 5) * 100 + "%"}
                        </p>
                      </div>
                      <Progress
                        color="green"
                        value={(currplayer?.TrainingObj?.Coachable / 5) * 100}
                      />
                    </div>
                  </div>
                  <div className="d-flex flex-column align-items-center justify-content-center metrics px-3">
                    <div className="d-flex flex-column   justify-content-center metric mb-2">
                      <p className="complimentary">Body Language</p>
                      <div className="d-flex  justify-content-between metric_value">
                        <p className=" align-self-start"> 1-5</p>
                        <p className=" align-self-end highlight">
                          {" "}
                          {(currplayer?.TrainingObj?.BodyLanguage / 5) * 100 +
                            "%"}
                        </p>
                      </div>
                      <Progress
                        color="green"
                        value={
                          (currplayer?.TrainingObj?.BodyLanguage / 5) * 100
                        }
                      />
                    </div>
                    <div className="d-flex flex-column   justify-content-center metric mb-2">
                      <p className="complimentary">Mental Toughness</p>
                      <div className="d-flex  justify-content-between metric_value">
                        <p className=" align-self-start"> 1-5</p>
                        <p className=" align-self-end highlight">
                          {(currplayer?.TrainingObj?.MentalToughness / 5) *
                            100 +
                            "%"}
                        </p>
                      </div>
                      <Progress
                        color="green"
                        value={
                          (currplayer?.TrainingObj?.MentalToughness / 5) * 100
                        }
                      />
                    </div>
                  </div>
                  <div
                    id="last_metrics"
                    className="d-flex flex-column align-items-center justify-content-center metrics  px-3"
                  >
                    <div className="d-flex flex-column   justify-content-center metric mb-2">
                      <p className="complimentary">Physical Toughness</p>
                      <div className="d-flex  justify-content-between metric_value">
                        <p className=" align-self-start"> 1-5</p>
                        <p className=" align-self-end highlight">
                          {" "}
                          {(currplayer?.TrainingObj?.PhysicalToughness / 5) *
                            100 +
                            "%"}
                        </p>
                      </div>
                      <Progress
                        color="green"
                        value={
                          (currplayer?.TrainingObj?.PhysicalToughness / 5) * 100
                        }
                      />
                    </div>
                  </div>
                </div>
              </>
            ))}
          </div>
        </div>
      )}

      {activeIcon === "Message" && (
        <div className="dasboard_main px-4 py-3 d-flex align-items-center justfy-content-end">
          <Messaging />
        </div>
      )}
    </div>
  );
};

export default ParentsDash;
