import React, { useState, useEffect } from "react";
import "./InstituteSettingsStyles.scss";
import { Input, TextInput, Button } from "@mantine/core";
import { Avatar } from "@mantine/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { update } from "../auth/authslice";
import Settings from "./Settings";
import { NumberInput } from "@mantine/core";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";

import {
  faMagnifyingGlass,
  faBell,
  faCircleInfo,
  faCirclePlus,
  faBookmark,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";

const InstituteSettings = () => {
  const monthly = process.env.REACT_APP_STRIPE_PROUCT_MONTHLY;
  const yearly = process.env.REACT_APP_STRIPE_PRODUCT_YEARLY;
  const dispatch = useDispatch();

  const [plan, setplan] = useState();
  const [quantity, setquantity] = useState(3);
  const getCheckoutSession = async (planid) => {
    try {
      console.log(planid);

      const res = await axios.post(
        `http://localhost:5009/api/users/Institute/createPayment`,
        {
          planid: planid,
          Inst_id: user?._id,
          quantity: quantity,
        }
      );
      console.log(res);
      window.open(res.data.url); // Open new browser tab with res.url
    } catch (error) {
      console.log(error);
    }
  };
  const [addPaymentLoading, setaddPaymentLoading] = useState(false);
  const addPaymentMethod = async () => {
    setaddPaymentLoading(true);
    try {
      const res = await axios.post(
        `http://localhost:5009/api/users/Institute/getStripePortal`,
        {
          id: user?._id,
        }
      );
      console.log(res);
      window.open(res.data.url); // Open new browser tab with res.url
    } catch (error) {
      console.log(error);
    } finally {
      setaddPaymentLoading(false);
    }
  };
  const [Transactions, setTransactions] = useState();
  const getTransactions = async () => {
    try {
      const res = await axios.post(
        `http://localhost:5009/api/Transaction/getInstTransactions`,
        { InstId: user?._id }
      );
      setTransactions(res.data);
    } catch (error) {}
  };
  useEffect(() => {
    getTransactions();
  }, []);
  const { user } = useSelector((state) => state.auth);
  const port = process.env.REACT_APP_IP_ADDRESS;
  const API_URL = `${port}/api/users/Institute`;
  const [currentseason, setcurrentSeason] = useState("");
  const setSeason = async () => {
    try {
      const res = await axios.post(`${API_URL}/SetCurrentSeason`, {
        instituteId: user?._id,
        SeasonName: currentseason,
      });
      toast.success(
        <>
          <img src="images/new-logo.png" className="toast_logo" />
          <p className="mt-3">Season Set Successfully</p>
        </>,

        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        }
      );
      const userdata = {
        type: "Institute",
        _id: user?._id,
      };
      dispatch(update(userdata));
    } catch (error) {
      console.log(error);
      toast.error(
        <>
          <img src="images/new-logo.png" className="toast_logo" />
          <p className="mt-3">Error in Setting Season</p>
        </>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        }
      );
    }
  };
  useEffect(() => {
    if (!user?.CurrentSeason && user?.isPaid) {
      toast.warning(
        <>
          <img src="images/new-logo.png" className="toast_logo" />
          <p className="mt-3">Add Season to continue</p>
        </>,
        {
          position: "top-right",
          // autoClose: 5000,
          autoClose: false,

          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        }
      );
    }
  }, [user]);
  return (
    <div
      className={`${
        user?.isPaid ? "dashboard_content" : "no_sidebar"
      } px-4 py-3`}
    >
      <div className="main_top d-flex justify-content-end align-items-center">
        {/* <div className="input_cont">
          <Input
            size="md"
            radius="xl"
            placeholder="Search"
            leftSection={<FontAwesomeIcon icon={faMagnifyingGlass} />}
          />
        </div> */}
        {user?.isPaid && (
          <div className="main_top_right d-flex justify-content-between align-items-center ">
            <div className="notification_cont">
              <FontAwesomeIcon icon={faBell} size="xl" />
            </div>
            <Avatar color="cyan" radius="xl">
              <img className="user_image" src={user?.Image} alt="" />
            </Avatar>
          </div>
        )}
      </div>
      <div className="settings_cont">
        {user?.isPaid && (
          <>
            <div className="d-flex align-items-center justify-content-between">
              <h4 style={{ color: "#ffffff" }}>Institute ID</h4>
              <Button
                variant="filled"
                color="#181A2C"
                leftSection={<FontAwesomeIcon icon={faPlus} />}
                onClick={() => addPaymentMethod()}
                loading={addPaymentLoading}
              >
                Add Payment Method
              </Button>
            </div>
            <div className="payment_info_cont d-flex  p-3 mt-3">
              <div className="d-flex flex-column ">
                <h5 className="heading">Institute Name</h5>
                <h5 className="heading">Stripe ID</h5>
              </div>
              <div className="d-flex flex-column">
                <h5 className="ms-5 value">{user?.name}</h5>
                <h5 className="ms-5 value">
                  {Transactions && Transactions[0]?.StripeCustomerID}
                </h5>
              </div>
            </div>
            <div className="transactions my-3">
              <h3>Transactions</h3>
              <div className="Transaction_header row py-2 px-4 mb-2">
                <div className="col-4 d-flex align-items-center">
                  <img src="images/transactions.png" alt="" />
                  <p className="ms-3">Transaction ID</p>
                </div>
                <div className="col-4 d-flex align-items-center justify-content-center">
                  <img src="images/calendar.png" alt="" />
                  <p className="ms-3">Date</p>
                </div>
                <div className="col-4 d-flex align-items-center justify-content-end">
                  <img src="images/medal.png" alt="" />
                  <p className="ms-3">Result</p>
                </div>
              </div>
              {Transactions &&
                Transactions[0]?.Transactions.map((transaction) => (
                  <>
                    <div className="trainsaction_row row py-2 px-4 mb-2">
                      <div className="col-4 d-flex align-items-center">
                        <img src="images/transactions.png" alt="" />
                        <p className="ms-3">
                          {transaction?.StripeTransactionID}
                        </p>
                      </div>
                      <div className="col-4 d-flex align-items-center justify-content-center">
                        <img src="images/calendar.png" alt="" />
                        <p className="ms-3">
                          {new Date(transaction?.Date).toLocaleDateString(
                            "en-US"
                          )}
                        </p>
                      </div>

                      <div className="col-4 d-flex align-items-center justify-content-end">
                        <>
                          {transaction?.Result === "Success" ? (
                            <>
                              <div className="Status success px-3">
                                <p>Success</p>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="Status Failed px-3">
                                <p>Failed</p>
                              </div>
                            </>
                          )}
                        </>
                      </div>
                    </div>
                  </>
                ))}
            </div>
            <div className="season_cont d-flex align-items-end mb-5 ">
              {/* Todo:Change to dropdown */}
              <TextInput
                type="text"
                aria-label="Season"
                name="Season"
                label="Season"
                size="lg"
                radius="md"
                placeholder="Enter Season Name"
                value={currentseason}
                onChange={(event) =>
                  setcurrentSeason(event.currentTarget.value)
                }
              />
              <Button
                variant="outline"
                color="green"
                className="ms-5"
                onClick={setSeason}
              >
                Add Season
              </Button>
            </div>{" "}
          </>
        )}

        <>
          <h2 className="mt-5 mb-3 heading_main">Payments</h2>
          <div className="payment d-flex align-items-center justify-content-between">
            <div className="paymentbox contrast_box d-flex flex-column align-items-center justify-content-start p-3 pt-5 mx-2">
              <h5 className="mb-2 contrast_heading">BASIC</h5>
              <h3 className="mb-5 Price">$650</h3>
              <Button
                fullWidth
                variant="gradient"
                size="lg"
                radius={"xl"}
                gradient={{ from: "#009B3A", to: "#2FED76", deg: 180 }}
                onClick={() => getCheckoutSession(monthly)}
                className="mb-2"
              >
                Current Plan
              </Button>
              <NumberInput
                label="Number of teams bewtween 1-10"
                placeholder="Enter Number of Teams"
                clampBehavior="strict"
                value={quantity}
                onChange={setquantity}
                min={1}
                max={10}
                className="mb-5"
                size="lg"
                radius="md"
              />
              <div className="">
                <div className="d-flex align-items-center">
                  <img src="images/green_dot.png" alt="greendot" />
                  <p className="mt-3 mx-2">
                    <span>150</span> Footage Hours
                  </p>
                </div>
                <div className="d-flex align-items-center">
                  <img src="images/green_tick.png" alt="greendot" />
                  <p className="mt-3 mx-2">NCAA Requirement Tracking</p>
                </div>
                <div className="d-flex align-items-center">
                  <img src="images/green_tick.png" alt="greendot" />
                  <p className="mt-3 mx-2">Team Messaging</p>
                </div>
                <div className="d-flex align-items-center">
                  <img src="images/green_tick.png" alt="greendot" />
                  <p className="mt-3 mx-2">Notification System</p>
                </div>
                <div className="d-flex align-items-center">
                  <img src="images/green_tick.png" alt="greendot" />
                  <p className="mt-3 mx-2">Team Training Analyzer</p>
                </div>
                <div className="d-flex align-items-center">
                  <img src="images/green_tick.png" alt="greendot" />
                  <p className="mt-3 mx-2">Team Practice Analyzer</p>
                </div>
                <div className="d-flex align-items-center">
                  <img src="images/green_tick.png" alt="greendot" />
                  <p className="mt-3 mx-2">Parent-Athlete Tracker</p>
                </div>
                <div className="d-flex align-items-center">
                  <img src="images/green_tick.png" alt="greendot" />
                  <p className="mt-3 mx-2">Player Performance Insights</p>
                </div>
                <div className="d-flex align-items-center">
                  <img src="images/green_cross.png" alt="greendot" />
                  <p id="dull" className="mt-3 mx-2">
                    AI Assist
                  </p>
                </div>
                <div className="d-flex align-items-center">
                  <img src="images/green_cross.png" alt="greendot" />
                  <p id="dull" className="mt-3 mx-2">
                    Expert Analysis & Breakdown
                  </p>
                </div>
              </div>
            </div>
            <div className="paymentbox d-flex flex-column align-items-center justify-content-start p-3 pt-5 mx-2">
              <h5 className="mb-2">STANDARD</h5>
              <h3 className="mb-5 Price">$1150</h3>
              <Button
                fullWidth
                variant="filled"
                size="lg"
                color="rgba(255, 255, 255, 1)"
                radius={"xl"}
                onClick={() => getCheckoutSession(yearly)}
                className="mb-2"
              >
                Upgrade to Standard Plan
              </Button>
              <NumberInput
                label="Number of teams bewtween 1-10"
                placeholder="Enter Number of Teams"
                clampBehavior="strict"
                value={quantity}
                onChange={setquantity}
                min={1}
                max={10}
                className="mb-5"
                size="lg"
              />
              <div className="">
                <div className="d-flex align-items-center">
                  <img src="images/white_dot.png" alt="greendot" />
                  <p className="mt-3 mx-2">
                    <span>350</span> Footage Hours
                  </p>
                </div>
                <div className="d-flex align-items-center">
                  <img src="images/white_tick.png" alt="greendot" />
                  <p className="mt-3 mx-2">NCAA Requirement Tracking</p>
                </div>
                <div className="d-flex align-items-center">
                  <img src="images/white_tick.png" alt="greendot" />
                  <p className="mt-3 mx-2">Team Messaging</p>
                </div>
                <div className="d-flex align-items-center">
                  <img src="images/white_tick.png" alt="greendot" />
                  <p className="mt-3 mx-2">Notification System</p>
                </div>
                <div className="d-flex align-items-center">
                  <img src="images/white_tick.png" alt="greendot" />
                  <p className="mt-3 mx-2">Team Training Analyzer</p>
                </div>
                <div className="d-flex align-items-center">
                  <img src="images/white_tick.png" alt="greendot" />
                  <p className="mt-3 mx-2">Team Practice Analyzer</p>
                </div>
                <div className="d-flex align-items-center">
                  <img src="images/white_tick.png" alt="greendot" />
                  <p className="mt-3 mx-2">Parent-Athlete Tracker</p>
                </div>
                <div className="d-flex align-items-center">
                  <img src="images/white_tick.png" alt="greendot" />
                  <p className="mt-3 mx-2">Player Performance Insights</p>
                </div>
                <div className="d-flex align-items-center">
                  <img src="images/white_tick.png" alt="greendot" />
                  <p className="mt-3 mx-2">AI Assist</p>
                </div>
                <div className="d-flex align-items-center">
                  <img src="images/white_cross.png" alt="greendot" />
                  <p id="dull" className="mt-3 mx-2">
                    Expert Analysis & Breakdown
                  </p>
                </div>
              </div>
            </div>
            <div className="paymentbox d-flex flex-column align-items-center justify-content-start p-3 pt-5 mx-2">
              <h5 className="mb-2">PREMIUM</h5>
              <h3 className="mb-5 Price">$2000</h3>
              <Button
                fullWidth
                variant="filled"
                size="lg"
                color="rgba(255, 255, 255, 1)"
                radius={"xl"}
                onClick={() => getCheckoutSession(yearly)}
                className="mb-2"
              >
                Upgrade to Premium Plan
              </Button>
              <NumberInput
                label="Number of teams bewtween 1-10"
                placeholder="Enter Number of Teams"
                clampBehavior="strict"
                value={quantity}
                onChange={setquantity}
                min={1}
                max={10}
                className="mb-5"
                size="lg"
              />
              <div className="">
                <div className="d-flex align-items-center">
                  <img src="images/white_dot.png" alt="greendot" />
                  <p className="mt-3 mx-2">
                    <span>850</span> Footage Hours
                  </p>
                </div>
                <div className="d-flex align-items-center">
                  <img src="images/white_tick.png" alt="greendot" />
                  <p className="mt-3 mx-2">NCAA Requirement Tracking</p>
                </div>
                <div className="d-flex align-items-center">
                  <img src="images/white_tick.png" alt="greendot" />
                  <p className="mt-3 mx-2">Team Messaging</p>
                </div>
                <div className="d-flex align-items-center">
                  <img src="images/white_tick.png" alt="greendot" />
                  <p className="mt-3 mx-2">Notification System</p>
                </div>
                <div className="d-flex align-items-center">
                  <img src="images/white_tick.png" alt="greendot" />
                  <p className="mt-3 mx-2">Team Training Analyzer</p>
                </div>
                <div className="d-flex align-items-center">
                  <img src="images/white_tick.png" alt="greendot" />
                  <p className="mt-3 mx-2">Team Practice Analyzer</p>
                </div>
                <div className="d-flex align-items-center">
                  <img src="images/white_tick.png" alt="greendot" />
                  <p className="mt-3 mx-2">Parent-Athlete Tracker</p>
                </div>
                <div className="d-flex align-items-center">
                  <img src="images/white_tick.png" alt="greendot" />
                  <p className="mt-3 mx-2">Player Performance Insights</p>
                </div>
                <div className="d-flex align-items-center">
                  <img src="images/white_tick.png" alt="greendot" />
                  <p className="mt-3 mx-2">AI Assist</p>
                </div>
                <div className="d-flex align-items-center">
                  <img src="images/white_tick.png" alt="greendot" />
                  <p className="mt-3 mx-2">Expert Analysis & Breakdown</p>
                </div>
                <div className="d-flex align-items-center">
                  <img src="images/white_dot.png" alt="greendot" />
                  <p className="mt-3 mx-2">
                    <span>$200</span> per each team
                  </p>
                </div>
                <div className="d-flex align-items-center">
                  <img src="images/white_dot.png" alt="greendot" />
                  <p className="mx-2 mt-3">
                    <span>$600</span> per each program (each program consists of
                    4 teams)
                  </p>
                </div>
              </div>
            </div>
          </div>
        </>

        <Settings type="Institute" />
      </div>
    </div>
  );
};

export default InstituteSettings;
