import React, { useState, useEffect } from "react";
import "./MessagingStyles.scss";
import { MessageBox } from "react-chat-elements";
import "react-chat-elements/dist/main.css";
import { ChatList } from "react-chat-elements";
// import { Input } from "react-chat-elements";
import { Input, CloseButton } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { Modal, Button } from "@mantine/core";
import { useSelector, useDispatch } from "react-redux";
import { Loader } from "@mantine/core";
import { toast } from "react-toastify";

import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPaperPlane,
  faCirclePlus,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { MessageList } from "react-chat-elements";
import io from "socket.io-client";

const port = process.env.REACT_APP_IP_ADDRESS;
const socket = io(port);

const Messaging = () => {
  const [userConverstaions, setUserConversations] = useState([]);
  const [messages, setMessages] = useState([]);
  const [chatId, setChatId] = useState(null); // This will be used to get messages from the backend
  const [loadingChats, setLoadingChats] = useState(true); // State variable to track loading state for chats
  const [loadingMessages, setLoadingMessages] = useState(false); // State variable to track loading state for messages
  const [loading, setLoading] = useState(false);

  const API_URL = `${port}/api/`;

  useEffect(() => {
    // socket = io(port);
    socket.emit("setup", user.chatId);
  }, []);

  const getChats = async () => {
    try {
      const response = await axios.post(
        `${API_URL}Chat/conversation/getAllChats`,
        {
          chatUserId: user.chatId,
        }
      );

      const result = response.data.flatMap((conversation) => {
        console.log("concersation", conversation);
        const chatid = conversation._id;
        return conversation?.participants
          ?.map((participant) => {
            if (participant._id === user.chatId) {
              return null;
            } else {
              console.log("conversation", conversation);
              return {
                avatar: participant?.referenceId?.Image,
                alt: participant?.referenceId?.firstName,
                title: participant?.referenceId?.firstName,
                subtitle: conversation.latestMessage,
                date: conversation?.updatedAt,
                id: chatid,
              };
            }
          })
          .filter(Boolean); // This will remove null values from the array
      });
      console.log(response);
      setUserConversations(result);

      //   console.log(result);
      //   console.log(response.data);
    } catch (err) {
      console.log(err);
    } finally {
      setLoadingChats(false); // Set loading to false once chats are fetched
    }
  };
  const getMessages = async (chat) => {
    setLoadingMessages(true); // Set loading to true when messages start loading

    const id = chat?.id;
    setChatId(id);
    const Data = {
      ChatID: id,
      chatUserId: user.chatId,
    };
    socket.emit("join room", Data);
    try {
      const response = await axios.post(
        `${API_URL}Chat/message/getConversation`,
        {
          ChatID: id,
        }
      );
      const result = response.data.map((message) => {
        console.log(message, "message");
        return {
          position: message.sender === user.chatId ? "right" : "left",
          type: "text",
          title: message.sender === user.chatId ? "You" : "Other",
          text: message.content,
          date: message?.createdAt,
        };
      });
      setMessages(result);
    } catch (err) {
      console.log(err);
    } finally {
      setLoadingMessages(false); // Set loading to false once messages are fetched
    }
  };

  useEffect(() => {
    socket.on("message recieved", (newMessageRecieve) => {
      console.log(newMessageRecieve, "message received");
      const currmessae = {
        position: "left",
        type: "text",
        title: "Other",
        text: newMessageRecieve.content,
        date: new Date(),
      };
      setMessages([...messages, currmessae]);
    });
  });

  const sendMessage = async (e) => {
    e.preventDefault();
    if (value === "") return;
    if (chatId === null) return;
    const currmessae = {
      position: "right",
      type: "text",
      title: "You",
      text: value,
      date: new Date(),
    };

    setMessages([...messages, currmessae]);
    const newMessageRecieve = {
      ChatID: chatId,
      sender: user.chatId,
      content: value,
    };
    socket.emit("new message", newMessageRecieve);
    setValue("");
    try {
      const response = await axios.post(`${API_URL}Chat/message`, {
        ChatID: chatId,
        sender: user.chatId,
        content: value,
      });
      console.log(response.data);
    } catch (err) {
      console.log(err);
    } finally {
      setValue("");
    }
  };

  useEffect(() => {
    getChats();

    console.log("user", user);
  }, []);
  useEffect(() => {
    const element = document.getElementById("message_list_cont");
    element.scrollTop = element.scrollHeight;
  }, [messages]);
  const [allPlayers, setallPlayers] = useState([]);
  const [allCoaches, setallCoaches] = useState([]);
  const getPlayers = async () => {
    try {
      setLoading(true); // Set loading to true when search begins

      console.log("user ", user);
      console.log(playerSearchValue);
      if (playerSearchValue.trim() == "") return;
      const res = await axios.post(
        `${API_URL}search/searchPlayersInInstitute`,
        {
          InstituteId:
            user?.userType == "Institute" ? user._id : user?.Institute,
          SearchText: playerSearchValue,
        }
      );
      console.log("players", res.data);

      setallPlayers(res.data);
    } catch (error) {
      if (allPlayers.length === 0) {
        toast.error(
          <>
            <img src="images/new-logo.png" className="toast_logo" />
            <p className="mt-3">Player not found.</p>
          </>,
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          }
        );
      }
      console.log(error);
    } finally {
      setLoading(false); // Reset loading to false when search is complete
    }
  };
  const getCoaches = async () => {
    try {
      const res = await axios.post(`${API_URL}Chat/findCoachesByInst`, {
        id: user?.userType == "Institute" ? user._id : user?.Institute,
      });
      console.log("coaches", res.data);
      setallCoaches(res.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getPlayers();
    getCoaches();
  }, []);

  const [particpant, setparticpant] = useState();
  const participanthelper = (participanttwo) => {
    console.log(participanttwo);
    setparticpant(participanttwo);
    Initiatconvo(participanttwo);
    close();
  };
  const Initiatconvo = async (participanttwo) => {
    try {
      const res = await axios.post(`${API_URL}Chat/conversation/initiate`, {
        participantOne: participanttwo,
        participantTwo: user?.chatId,
      });
    } catch (error) {
      console.log(error);
    } finally {
      getChats();
    }
  };
  const [usertype, setusertype] = useState("");
  const { user } = useSelector((state) => state.auth);
  const [value, setValue] = useState("");
  const [opened, { open, close }] = useDisclosure(false);

  const [playerSearchValue, setPlayerSearchValue] = useState("");

  useEffect(() => {
    if (opened) {
      setusertype("");
      setallPlayers([]);
      setPlayerSearchValue("");
    }
  }, [opened]);
  return (
    <div className="messaging_cont d-flex">
      <div className="converstaion_cont p-3">
        <div className="d-flex align-items-center justify-content-between message_top_box pb-2">
          <h3 className="message_title">Contacts</h3>
          <img
            src="images/add_chat.png"
            alt=""
            id="add_contact"
            onClick={open}
          />
        </div>
        {loadingChats ? (
          // Render loading icon if loadingChats state is true
          <div>
            <Loader color="gray" className="chatloader" />;
          </div>
        ) : (
          <ChatList
            className="chat-list mt-2"
            dataSource={userConverstaions}
            onClick={(chat) => getMessages(chat)}
          />
        )}
      </div>
      <div className="messaging">
        <div className="messages mb-2" id="message_list_cont">
          {loadingMessages ? (
            // Render loading icon if loadingMessages state is true
            <div className="messageloader">
              <Loader color="gray" />;
            </div>
          ) : (
            <MessageList
              className="message-list"
              lockable={true}
              toBottomHeight={"100%"}
              dataSource={messages}
            />
          )}
        </div>

        <form className="input_cont" onSubmit={sendMessage}>
          <Input
            placeholder="Enter Message"
            value={value}
            onChange={(event) => setValue(event.currentTarget.value)}
            rightSectionPointerEvents="all"
            size="md"
            rightSection={
              <FontAwesomeIcon
                icon={faPaperPlane}
                className={`${value != "" && "colored_send"}`}
                onClick={sendMessage}
              />
            }
          />
        </form>
      </div>
      <Modal
        opened={opened}
        onClose={close}
        centered
        id="initiate_convo_modal"
        size={"md"}
      >
        <>
          {usertype == "" && (
            <div className="d-flex align-items-center justify-content-center mb-4 ">
              <h3 className="mb-2"> Choose Participant</h3>
            </div>
          )}

          {usertype == "player" && (
            <div className="d-flex align-items-center justify-content-center mb-4 ">
              <h3 className="mb-2"> Choose Player</h3>
            </div>
          )}
          <div className="d-flex align-items-start justify-content-start flex-wrap">
            {allPlayers?.length > 0 !== "" &&
              usertype == "player" &&
              allPlayers?.map((player, index) => {
                if (user?._id !== player?.referenceId?._id) {
                  return (
                    <div className="m-2" key={index}>
                      <div
                        className={`player_message  px-2 d-flex align-items-center justify-content-start  `}
                        onClick={() => participanthelper(player?.chatId)}
                      >
                        <div className="player_message_image d-flex align-items-center justify-content-center me-4">
                          {" "}
                          <img src={player?.Image} alt="" />
                        </div>

                        <p>{player?.firstName}</p>
                      </div>
                    </div>
                  );
                }
              })}
          </div>
          {allPlayers?.length == 0 && usertype == "player" && (
            <div className="search_player d-flex flex-column align-items-center justify-content-center mt-5">
              <div id="search_player">
                <Input.Wrapper label="Search Player">
                  <Input
                    size="md"
                    placeholder="Enter Player Name"
                    value={playerSearchValue}
                    onChange={(e) => setPlayerSearchValue(e.target.value)}
                  />
                </Input.Wrapper>
              </div>
              <Button
                variant="outline"
                color="green"
                className="mt-3"
                onClick={getPlayers}
              >
                Submit
              </Button>
              {loading && (
                <Loader size="md" className="contactSearchingLoader" />
              )}
            </div>
          )}
          {usertype == "coach" && (
            <div className="d-flex align-items-center justify-content-center mb-4 ">
              <h3 className="mb-2"> Choose Coach</h3>
            </div>
          )}
          <div className="d-flex align-items-start justify-content-start flex-wrap">
            {usertype == "coach" &&
              allCoaches?.map((coach, index) => {
                if (user?._id !== coach?.referenceId?._id) {
                  return (
                    <div className="m-2" key={index}>
                      <div
                        className={`player_message  px-2 d-flex align-items-center justify-content-start`}
                        onClick={() => participanthelper(coach?._id)}
                        // onClick={() => console.log(coach?._id)}
                      >
                        <div className="player_message_image d-flex align-items-center justify-content-center me-4">
                          {" "}
                          <img src={coach?.referenceId?.Image} alt="" />
                        </div>

                        <p>{coach?.referenceId?.firstName}</p>
                      </div>
                    </div>
                  );
                }
              })}
          </div>
          {usertype == "" && (
            <div className="d-flex user_type_cont align-items-center justify-content-center mt-5">
              <div
                className={`user_message mx-2 d-flex flex-column align-items-center justify-content-center ${
                  usertype === "player" && "Active"
                }`}
                onClick={() => setusertype("player")}
              >
                <img src="images/choose-player.png" alt="" />
                <h4 className="mt-4">Player</h4>
              </div>
              <div
                className={`user_message mx-2 d-flex flex-column align-items-center justify-content-center ${
                  usertype === "coach" && "Active"
                }`}
                onClick={() => setusertype("coach")}
              >
                <img src="images/choose-coach.png" alt="" />
                <h4 className="mt-4">Coach</h4>
              </div>
            </div>
          )}
        </>
      </Modal>
    </div>
  );
};

export default Messaging;
