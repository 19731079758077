import React, { useState } from "react";
import { Input, Button, Textarea } from "@mantine/core";
import "../styles/VideoFeaturePageStyles.scss";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { Menu, MenuItem, ListItemIcon, ListItemText } from "@mui/material";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import VideoFeatureSlider from "../components/VideoFeatureSlider";

import {
  faLocationDot,
  faPhone,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
      });
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
};
const VideoFeaturesPage = () => {
  const [rescipent, setrescipent] = useState("Institute"); //institute or private coaching
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (feature) => {
    // Handle the selection here, e.g., navigate to the selected feature
    console.log(`Selected feature: ${feature}`);
    handleClose();
  };
  const navigate = useNavigate();
  const navtohome = () => {
    navigate("/");
  };
  const navtodash = () => {
    navigate("/chooseuser");
  };

  const navtopracticefeature = () => {
    navigate("/practice-features");
  };
  const navtotrainingfeature = () => {
    navigate("/training-features");
  };
  const navtovideofeature = () => {
    navigate("/video-features");
  };
  const navtopricing = () => {
    navigate("/pricing");
  };
  const navtocontactus = () => {
    navigate("/contactus");
  };
  const navtoBlog = () => {
    navigate("/blog");
  };
  const { width } = useWindowSize();
  return (
    <div className="video_feature_home_cont">
      <div className="video_feature_landing">
        <div id="elipse_1"></div>
        <div className="landing_top d-flex align-items-center justify-content-sm-between justify-content-center">
          <img src="images/Vector.png" alt="" id="top_logo" />
          <div className="navbar">
            <Button
              variant="transparent"
              size="lg"
              color="gray"
              onClick={navtohome}
              id="aboutUs_btn"
            >
              Home
            </Button>
            {/* <Button
              variant="transparent"
              size="lg"
              color="gray"
              onClick={navtofeatures}
              id="features_btn"
            >
              Features
            </Button>{" "} */}
            <Button
              variant="transparent"
              size="lg"
              color="gray"
              //   aria-controls="features-menu"
              //   aria-haspopup="true"
              onClick={handleClick}
              id="features_btn"
              rightSection={<FontAwesomeIcon icon={faAngleDown} />}
            >
              Features
            </Button>
            <Menu
              id="features-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              PaperProps={{
                style: {
                  background: "transparent",
                  boxShadow: "none",
                },
              }}
            >
              <MenuItem onClick={() => handleMenuItemClick("Feature 1")}>
                <Button
                  variant="transparent"
                  size="md"
                  color="gray"
                  onClick={navtopracticefeature}
                  id="pricing_btn"
                >
                  Practice
                </Button>{" "}
              </MenuItem>
              <MenuItem onClick={() => handleMenuItemClick("Feature 2")}>
                <Button
                  variant="transparent"
                  size="md"
                  color="gray"
                  onClick={navtotrainingfeature}
                  id="pricing_btn"
                >
                  Training
                </Button>
              </MenuItem>
              <MenuItem onClick={() => handleMenuItemClick("Feature 3")}>
                <Button
                  variant="transparent"
                  size="md"
                  color="gray"
                  onClick={navtovideofeature}
                  id="pricing_btn"
                >
                  Video
                </Button>{" "}
              </MenuItem>
            </Menu>
            <Button
              variant="transparent"
              size="lg"
              color="gray"
              onClick={navtopricing}
              id="pricing_btn"
            >
              Pricing
            </Button>{" "}
            <Button
              variant="transparent"
              size="lg"
              color="gray"
              onClick={navtocontactus}
              id="contactUs_btn"
            >
              Contact us
            </Button>
            <Button
              variant="transparent"
              size="lg"
              color="gray"
              onClick={navtoBlog}
              id="contactUs_btn"
            >
              Blog
            </Button>
          </div>

          <Button
            variant="filled"
            size="lg"
            radius={"xl"}
            color="#0C0F21"
            onClick={navtodash}
            id="dashboard_btn"
          >
            Dashboard
          </Button>
        </div>
        <div className="landing_main d-flex flex-column align-items-center">
          <h1 className="video_feature_headline">
            <span>Video Upload</span> Features
          </h1>
          <p className="main_text">
            Embrace the Future of Football with <span>Athlantix!</span> Our
            intuitive platform is tailored to arm <br />{" "}
            <span>players, coaches</span>, and parents with insights like never
            before. Get ready to gain a <br /> competitive edge and see the game
            through the lens of <span>advanced analytics</span>.
          </p>
        </div>
        <div>
          <VideoFeatureSlider />
        </div>
        {/* <div className="training-features-images">
          <div>
            <img
              src="/images/feature_training_image1.png"
              alt="Image1"
              className="img-1"
            />
          </div>
          <div className="training-features-images-inner">
            <img
              src="/images/feature_training_image3.png"
              alt="Image2"
              className="img-2"
            />
            <img
              src="/images/feature_training_image2.png"
              alt="Image2"
              className="img-2"
            />
          </div>
        </div> */}
      </div>

      <div className="cta_form_features d-flex flex-column align-items-center">
        <h1 className="mb-5">Contact us</h1>
        <p className="main_text">
          Join the Athlantix community and take control of your American
          football journey. Sign <br /> up for a <span>free trial</span> today!
        </p>
        <div className="pt-5 form_outter d-flex flex-column align-items-center px-sm-5">
          <div className="choose_recepient d-flex align-items-center justify-content-between ">
            <h4
              onClick={() => setrescipent("Institute")}
              className={`${rescipent == "Institute" && "selected"}`}
            >
              Institute
            </h4>
            <h4
              onClick={() => setrescipent("Private Coaching")}
              className={`${rescipent == "Private Coaching" && "selected"}`}
            >
              Private Coaching
            </h4>
          </div>
          <div className="my-3 input_cont">
            <Input placeholder="Name" size="lg" radius={"xl"} />
          </div>
          <div className="my-3 input_cont">
            <Input placeholder="Email Address" size="lg" radius={"xl"} />
          </div>
          <div className="my-3 input_cont">
            <Input placeholder="Phone Number" size="lg" radius={"xl"} />
          </div>
          <div className="my-3 text_area_cont">
            <Textarea
              placeholder="Description"
              size="lg"
              radius={"lg"}
              autosize
              minRows={4}
            />
          </div>
          <div className="button_cont">
            <Button
              fullWidth
              variant="filled"
              color="#0C0F21"
              size="md"
              radius={"xl"}
            >
              Request a Demo
            </Button>
          </div>
        </div>
        <div id="elipse_5"></div>
      </div>
      <div className="footer_features d-flex flex-column align-items-center justify-conetnt-center">
        <div className="logo_cont">
          <img src="images/Vector-2.png" alt="" />
        </div>
        <div className="tag_cont d-flex align-items-center justify-content-start flex-sm-row flex-column">
          <div className="d-flex flex-column align-items-start justify-content-start ">
            <div className="mb-4 tag d-flex ">
              <FontAwesomeIcon
                icon={faLocationDot}
                className="me-3"
                size="lg"
              />
              <p>Texas</p>
            </div>

            <div className="mb-4 tag d-flex ">
              <FontAwesomeIcon icon={faEnvelope} className="me-3" size="lg" />
              <p>contact@athlantix.com</p>
            </div>
          </div>
          <div className="d-flex flex-column align-items-start justify-content-start sitemap">
            <div className="mb-4 tag d-flex ">
              <p>Home</p>
            </div>
            <div className="mb-4 tag d-flex ">
              <p>Features </p>
            </div>
            <div className="mb-4 tag d-flex ">
              <p>Pricing</p>
            </div>
            <div className="mb-4 tag d-flex ">
              <p> Testimonials</p>
            </div>
          </div>
        </div>
        <div className="copyright d-flex align-items-center justify-content-center">
          <p>Copyright © 2024 Athlantix. All rights reserved.</p>
        </div>
      </div>
    </div>
  );
};

export default VideoFeaturesPage;
