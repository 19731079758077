import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../styles/NewChooseUserStyles.scss";

const NewChooseUser = () => {
  const navigate = useNavigate();
  const [stage, setstage] = useState("login");
  const [user, setuser] = useState("");

  useEffect(() => {
    setuser("");
  }, [stage]);

  useEffect(() => {
    if (user !== "") {
      if (user === "Coach") {
        if (stage === "signup") navigate("/signupcoach");
        else navigate("/loginCoach");
      }
      if (user === "Institute") {
        if (stage === "signup") navigate("/signupinst");
        else navigate("/loginInstitute");
      }
      if (user === "Player") {
        if (stage === "signup") navigate("/signupplayer");
        else navigate("/loginPlayer");
      }
      if (user === "Parent") {
        if (stage === "login") {
          navigate("/loginParent");
        }
      }
    }
  }, [user]);

  return (
    <div className="chooseuser_cont d-flex flex-column align-items-center ">
      <div className="chooseuser_logo d-flex  align-items-center justify-content-center pt-4">
        <img
          src="/images/new-logo.png"
          alt=""
          className="me-1"
          onClick={() => navigate("/")}
        />
      </div>
      {stage === "signup" && <h2 className="mb-5 signup">Signup As</h2>}
      {stage === "login" && <h2 className="mb-5 signup">Login As</h2>}
      <img
        src="images/left-charecter.png"
        alt=""
        className="charecter"
        id="left_charecter"
      />
      <img
        src="images/center-charecter.png"
        alt=""
        className="charecter"
        id="center_charecter"
      />
      <img
        src="images/right-charecter.png"
        alt=""
        className="charecter"
        id="right_charecter"
      />

      <div className="chooseusers d-flex align-items-center justify-content-center">
        <div
          className={`user mx-2 d-flex flex-column align-items-center justify-content-center ${
            user === "Coach" && "Active"
          }`}
          onClick={() => setuser("Coach")}
        >
          <img src="images/choose-coach.png" alt="" />
          <h2 className="mt-4">Coach</h2>
        </div>
        <div
          className={`user mx-2 d-flex flex-column align-items-center justify-content-center ${
            user === "Institute" && "Active"
          }`}
          onClick={() => setuser("Institute")}
        >
          <img src="images/choose-inst.png" alt="" />
          <h2 className="mt-4">Institute</h2>
        </div>
        <div
          className={`user mx-2 d-flex flex-column align-items-center justify-content-center ${
            user === "Player" && "Active"
          }`}
          onClick={() => setuser("Player")}
        >
          <img src="images/choose-player.png" alt="" />
          <h2 className="mt-4">Player</h2>
        </div>
        {stage === "login" && (
          <>
            <div
              className={`user mx-2 d-flex flex-column align-items-center justify-content-center ${
                user === "Parent" && "Active"
              }`}
              onClick={() => setuser("Parent")}
            >
              <img src="images/choose-parent.png" alt="" />
              <h2 className="mt-5">Parent</h2>
            </div>
          </>
        )}
      </div>
      {stage === "signup" && (
        <p className="login mt-4" onClick={() => setstage("login")}>
          Already have an account <span>Log in</span>
        </p>
      )}
      {stage === "login" && (
        <p className="login mt-4" onClick={() => setstage("signup")}>
          Don't have an account <span>SignUp</span>
        </p>
      )}
    </div>
  );
};

export default NewChooseUser;
