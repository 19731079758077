import React, { useState, useEffect } from "react";
import "../styles/ContactUsStyles.scss";
import { Input } from "@mantine/core";
import { Button } from "@mantine/core";
import { PasswordInput } from "@mantine/core";
import { Checkbox } from "@mantine/core";
import { useSelector, useDispatch } from "react-redux";
import { login, reseter } from "../auth/authslice";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import { useDisclosure } from "@mantine/hooks";
import { Modal } from "@mantine/core";
import { toast } from "react-toastify";
import { NativeSelect } from "@mantine/core";
import { Radio } from "@mantine/core";

const port = process.env.REACT_APP_IP_ADDRESS;
const API_URL = `${port}/api/ContactUs/RequestDemo`;

const ContactUs = (props) => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    state: "",
    postalCode: "",
    organizationName: "",
    organizationLevel: "",
    role: "",
    discussion: "",
    contact: "",
  });

  // const [opened, { open, close }] = useDisclosure(false);

  const {
    firstName,
    lastName,
    email,
    phoneNumber,
    postalCode,
    state,
    organizationName,
    organizationLevel,
    role,
    discussion,
    contact,
  } = formData;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Check if any required field is empty
    for (const key in formData) {
      if (!formData[key]) {
        const capitalizedKey = key.charAt(0).toUpperCase() + key.slice(1);
        toast.error(
          <>
            <img src="images/new-logo.png" className="toast_logo" />
            <p className="mt-3">{`${capitalizedKey} is required`}</p>
          </>,
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          }
        );
        return;
      }
    }

    // If all fields are filled, proceed with sending the data
    axios

      .post(API_URL, formData)
      .then((response) => {
        console.log(response.data);
        console.log(API_URL);

        // Reset form fields
        setFormData({
          firstName: "",
          lastName: "",
          email: "",
          phoneNumber: "",

          state: "",
          postalCode: "",
          organizationName: "",
          organizationLevel: "",
          role: "",
          discussion: "",
          contact: "",
        });
        // Display success toast message
        toast.success(
          <>
            <img src="images/new-logo.png" className="toast_logo" />
            <p className="mt-3">Form Submitted Successfully!</p>
          </>,
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          }
        );
      })
      .catch((error) => {
        console.error("Error submitting form:", error);
        // Display error toast message
        toast.error("Error submitting form");
      });
  };

  return (
    <>
      <div className="allowY">
        {" "}
        <div id="contactUs_cont">
          <div className="top_cont d-flex  align-items-center justify-content-center pt-4">
            <img
              src="images/new-logo.png"
              alt=""
              className="me-1"
              onClick={() => navigate("/")}
            />
          </div>
          <div className="form_cont">
            <h2 className="contactus_heading">Contact Us</h2>
            <p className="contactus_subheading">
              Connect with our team by filing out this form.
            </p>
            <div className="contactus_form">
              <form onSubmit={handleSubmit}>
                {" "}
                <h5 className="personalinfo">Personal Information</h5>
                <div className="formcontainer">
                  <div className="formgroup">
                    <Input.Wrapper
                      label="First Name"
                      description=""
                      className="mb-2"
                      size="md"
                      styles={{
                        label: { color: "#cbd5e1" }, // Change 'blue' to whatever color you prefer
                      }}
                    >
                      <Input
                        variant="filled"
                        size="lg"
                        radius="md"
                        name="firstName"
                        value={firstName}
                        onChange={handleChange}
                        //   error={isError}
                      />
                    </Input.Wrapper>
                    <Input.Wrapper
                      label="Work Email"
                      description=""
                      className="mb-2"
                      size="md"
                      styles={{
                        label: { color: "#cbd5e1" }, // Change 'blue' to whatever color you prefer
                      }}
                      // error={isError ? "Invalid Credentials" : ""}
                    >
                      <Input
                        variant="filled"
                        size="lg"
                        radius="md"
                        name="email"
                        value={email}
                        onChange={handleChange}
                        //   error={isError}
                      />
                    </Input.Wrapper>
                    <Input.Wrapper
                      label="Postal Code"
                      description=""
                      className="mt-2"
                      size="md"
                      styles={{
                        label: { color: "#cbd5e1" }, // Change 'blue' to whatever color you prefer
                      }}
                      // error={isError ? "Invalid Credentials" : ""}
                    >
                      <Input
                        variant="filled"
                        size="lg"
                        radius="md"
                        name="postalCode"
                        value={postalCode}
                        onChange={handleChange}
                        //   error={isError}
                      />
                    </Input.Wrapper>
                  </div>
                  <div className="formgroup">
                    <Input.Wrapper
                      label="Last Name"
                      description=""
                      className="mb-2"
                      size="md"
                      styles={{
                        label: { color: "#cbd5e1" }, // Change 'blue' to whatever color you prefer
                      }}
                      // error={isError ? "Invalid Credentials" : ""}
                    >
                      <Input
                        variant="filled"
                        size="lg"
                        radius="md"
                        name="lastName"
                        value={lastName}
                        onChange={handleChange}
                        //   error={isError}
                      />
                    </Input.Wrapper>
                    <Input.Wrapper
                      label="Phone number"
                      description=""
                      className="mb-2"
                      size="md"
                      styles={{
                        label: { color: "#cbd5e1" }, // Change 'blue' to whatever color you prefer
                      }}
                      // error={isError ? "Invalid Credentials" : ""}
                    >
                      <Input
                        variant="filled"
                        size="lg"
                        radius="md"
                        name="phoneNumber"
                        value={phoneNumber}
                        onChange={handleChange}
                        //   error={isError}
                      />
                    </Input.Wrapper>
                    <NativeSelect
                      label="State"
                      placeholder="Select state"
                      size="lg"
                      radius="md"
                      styles={{
                        label: { color: "#cbd5e1" }, // Change 'blue' to whatever color you prefer
                      }}
                      className="mt-2"
                      value={state}
                      onChange={(e) =>
                        setFormData({ ...formData, state: e.target.value })
                      }
                      id="state"
                      data={[
                        "Select state",
                        "Alabama",
                        "Alaska",
                        "Arizona",
                        "Arkansas",
                        "California",
                        "Colorado",
                        "Connecticut",
                        "Delaware",
                        "Florida",
                        "Georgia",
                        "Hawaii",
                        "Idaho",
                        "Illinois",
                        "Indiana",
                        "Iowa",
                        "Kansas",
                        "Kentucky",
                        "Louisiana",
                        "Maine",
                        "Maryland",
                        "Massachusetts",
                        "Michigan",
                        "Minnesota",
                        "Mississippi",
                        "Missouri",
                        "Montana",
                        "Nebraska",
                        "Nevada",
                        "New Hampshire",
                        "New Jersey",
                        "New Mexico",
                        "New York",
                        "North Carolina",
                        "North Dakota",
                        "Ohio",
                        "Oklahoma",
                        "Oregon",
                        "Pennsylvania",
                        "Rhode Island",
                        "South Carolina",
                        "South Dakota",
                        "Tennessee",
                        "Texas",
                        "Utah",
                        "Vermont",
                        "Virginia",
                        "Washington",
                        "West Virginia",
                        "Wisconsin",
                        "Wyoming",
                      ]}
                    />
                  </div>
                </div>
                <Input.Wrapper
                  label="Organization Name"
                  description=""
                  className="mb-2 mt-2"
                  styles={{
                    label: { color: "#cbd5e1" }, // Change 'blue' to whatever color you prefer
                  }}
                  size="md"
                  // error={isError ? "Invalid Credentials" : ""}
                >
                  <Input
                    variant="filled"
                    size="lg"
                    radius="md"
                    name="organizationName"
                    value={organizationName}
                    onChange={handleChange}
                    //   error={isError}
                  />
                </Input.Wrapper>
                <p className="contactus_ques1">
                  Are you contacting us on behalf of an ogranization or for
                  yourself?
                </p>
                <div className="contactus_radio">
                  {" "}
                  <Radio
                    label="Organization"
                    color="gray"
                    variant="outline"
                    styles={{
                      label: { color: "#cbd5e1" }, // Change 'blue' to whatever color you prefer
                    }}
                    name="contact"
                    checked={contact === "organization"}
                    onChange={(e) =>
                      setFormData({ ...formData, contact: e.target.value })
                    }
                    value="organization"
                  />
                  <Radio
                    label="Self"
                    color="gray"
                    variant="outline"
                    name="contact"
                    styles={{
                      label: { color: "#cbd5e1" }, // Change 'blue' to whatever color you prefer
                    }}
                    checked={contact === "self"}
                    onChange={(e) =>
                      setFormData({ ...formData, contact: e.target.value })
                    }
                    value="self"
                  />
                </div>
                <div className="formcontainer mt-4">
                  <div className="formgroup">
                    {" "}
                    <NativeSelect
                      label="Role"
                      placeholder="Select role"
                      size="lg"
                      radius="md"
                      value={role}
                      styles={{
                        label: { color: "#cbd5e1" }, // Change 'blue' to whatever color you prefer
                      }}
                      onChange={(e) =>
                        setFormData({ ...formData, role: e.target.value })
                      }
                      className="mt-2"
                      id="sport"
                      data={[
                        "Select role",
                        "Analyst",
                        "Assistant Coach",
                        "Athlete",
                        "Athletic Director",
                        "Board Member",
                        "Director",
                        "Head Coach",
                        "Manager",
                        "Office Administrator",
                        "Parent/Fan",
                        "President",
                        "Recruiter",
                        "Video Coordinator",
                      ]}
                    />
                  </div>
                  <div className="formgroup">
                    <NativeSelect
                      label="Organization Level"
                      placeholder="Select level"
                      value={organizationLevel}
                      styles={{
                        label: { color: "#cbd5e1" }, // Change 'blue' to whatever color you prefer
                      }}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          organizationLevel: e.target.value,
                        })
                      }
                      size="lg"
                      radius="md"
                      className="mt-2"
                      id="organizationalLevel"
                      data={[
                        "Select level",
                        "Club / Youth",
                        "High School / Middle School",
                        "College",
                        "Professional",
                      ]}
                    />
                  </div>{" "}
                </div>
                <Input.Wrapper
                  label="Tell us what you would like to discuss"
                  description=""
                  className="mb-2 mt-2"
                  size="md"
                  styles={{
                    label: { color: "#cbd5e1" }, // Change 'blue' to whatever color you prefer
                  }}
                  // error={isError ? "Invalid Credentials" : ""}
                >
                  <Input
                    variant="filled"
                    size="lg"
                    radius="md"
                    name="discussion"
                    value={discussion}
                    onChange={handleChange}
                    //   error={isError}
                  />
                </Input.Wrapper>
                <div className="contactus_send_button">
                  {" "}
                  <div className="button_cont d-flex align-items-center justify-content-center mb-3 mt-3">
                    <Button
                      variant="filled"
                      color="rgba(0, 155, 58, 1)"
                      type="submit"
                      // loading={isLoading}
                    >
                      Send
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
