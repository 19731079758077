import React, { useState, useEffect } from "react";
import "../styles/ScoutDashboardStyles.scss";
import ScoutSearch from "../components/ScoutSearch";
import SearchHistory from "../components/SearchHistory";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Input, TextInput, Select, NumberInput, Button } from "@mantine/core";
import { Avatar } from "@mantine/core";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../auth/authslice";
import { useNavigate } from "react-router-dom";
import SearchResult from "../components/SearchResult";
import Messaging from "../components/Messaging";
import axios from "axios";
import {
  faHouse,
  faFutbol,
  faBookmark,
  faPeopleGroup,
  faSignal,
  faGear,
  faEnvelope,
  faArrowRightFromBracket,
  faMagnifyingGlass,
  faBell,
  faCircleInfo,
  faPlus,
  faMessage,
} from "@fortawesome/free-solid-svg-icons";
const ScoutDashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(logout());
    navigate("/loginscout");
  };
  const { user } = useSelector((state) => state.auth);
  useEffect(() => {
    if (user == null) {
      navigate("/loginscout");
    } else {
      getSeacrches();
    }
  }, [user]);
  const [activeIcon, setActiveIcon] = useState("Dashboard");
  const port = process.env.REACT_APP_IP_ADDRESS;
  const API_URL = `${port}/api/`;
  const [searches, setSearches] = useState([]);
  const getSeacrches = async () => {
    try {
      const response = await axios.post(`${API_URL}search/getSavedSearches`, {
        scoutId: user._id,
      });
      console.log(response.data);
      setSearches(response.data);
    } catch (err) {
      console.log(err);
    }
  };
  const SearchPlayers = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${API_URL}search/getSearches`, {
        scoutId: user._id,
      });
      console.log(response.data);
      setSearches(response.data);
    } catch (err) {
      console.log(err);
    }
  };
  const [formInputs, setFormInputs] = useState([]);
  const [submittedInputs, setSubmittedInputs] = useState([]);
  const [searched, setSearched] = useState(false);
  const [scoutresults, setScoutResults] = useState({});
  const searchScout = async () => {
    const data = {
      text: "a",
      ageRange: {
        minAge: formInputs[2],
        maxAge: formInputs[3],
      },
      metrics: {
        [formInputs[0]]: formInputs[1],
      },
      saveSearch: formInputs[4],
    };
    try {
      const response = await axios.post(`${API_URL}search/unifiedSearch`, {
        scoutId: user._id,
        ...data,
      });
      console.log(response.data);
      setScoutResults(response.data);
    } catch (err) {
      console.log(err);
    } finally {
      setSearched(true);
    }
  };
  const onSubmit = (e) => {
    e.preventDefault();
    console.log(formInputs);
    searchScout();
  };
  return (
    <div className="dashboard_cont ">
      <div className="sidebar">
        <div className="sidebar_logo d-flex  align-items-center justify-content-center pt-4">
          <img src="/images/vector.png" alt="" className="me-1" />
          <h4>Athlantix</h4>
        </div>

        <div className="sidebar_cont   d-flex flex-column justify-content-between ">
          <div className="sidebar_cont_top d-flex flex-column justify-content-between">
            <div
              className={`icon_cont ${
                activeIcon === "Dashboard" ? "active_nav" : ""
              }`}
              onClick={() => setActiveIcon("Dashboard")}
            >
              <FontAwesomeIcon icon={faHouse} />
              <p>Dashboard</p>
            </div>
            <div
              className={`icon_cont ${
                activeIcon === "Saved Players" ? "active_nav" : ""
              }`}
              onClick={() => setActiveIcon("Saved Players")}
            >
              <FontAwesomeIcon icon={faBookmark} />
              <p>Saved Players</p>
            </div>
            <div
              className={`icon_cont ${
                activeIcon === "Search players" ? "active_nav" : ""
              }`}
              onClick={() => setActiveIcon("Search players")}
            >
              <FontAwesomeIcon icon={faPeopleGroup} />
              <p>Search Players</p>
            </div>
            <div
              className={`icon_cont ${
                activeIcon === "Contact" ? "active_nav" : ""
              }`}
              onClick={() => setActiveIcon("Contact")}
            >
              <FontAwesomeIcon icon={faEnvelope} />
              <p>Contact</p>
            </div>
            <div
              className={`icon_cont ${
                activeIcon === "Message" ? "active_nav" : ""
              }`}
              onClick={() => setActiveIcon("Message")}
            >
              <FontAwesomeIcon icon={faMessage} />
              <p>Message</p>
            </div>

            <div
              className={`icon_cont ${
                activeIcon === "Settings" ? "active_nav" : ""
              }`}
              onClick={() => setActiveIcon("Settings")}
            >
              <FontAwesomeIcon icon={faGear} />
              <p>Settings</p>
            </div>
          </div>
          <div className="sidebar_cont_bottom">
            {" "}
            <div className="icon_cont" onClick={handleLogout}>
              <FontAwesomeIcon icon={faArrowRightFromBracket} /> <p>Logout</p>
            </div>
          </div>
        </div>
      </div>
      <div className="scout_main px-4 py-3">
        <div className="main_top d-flex justify-content-end align-items-center">
          <div className="main_top_right d-flex justify-content-between align-items-center ">
            <div className="notification_cont">
              <FontAwesomeIcon icon={faBell} size="xl" />
            </div>
            <Avatar color="cyan" radius="xl">
              MK
            </Avatar>
          </div>
        </div>
        {activeIcon === "Dashboard" && (
          <>
            {searches == {} && (
              <div className="empty_search d-flex flex-column justify-content-center align-items-center">
                <div className="circle d-flex  justify-content-center align-items-center mb-2">
                  <FontAwesomeIcon icon={faPlus} color="#ffffff" />
                </div>
                <p>No Previous Searches are available</p>
              </div>
            )}
            <div className="search_history row px-5">
              {searches &&
                searches?.map((search) => <SearchHistory search={search} />)}
            </div>
          </>
        )}
        {activeIcon === "Saved Players" && <ScoutSearch />}
        {activeIcon === "Search players" && (
          <>
            {!searched && (
              <div className="scout_search d-flex flex-column justify-content-center align-items-center px-4">
                <form onSubmit={onSubmit}>
                  <div class="row metric_search">
                    <div class="col-6">
                      <Select
                        label="Metric"
                        placeholder="Pick value"
                        data={[
                          "speed",
                          "endurance",
                          "runBlocking",
                          "passBlocking",
                          "vision",
                          "power",
                          "accuracy",
                          "passCoverage",
                          "tackling",
                          "passRushing",
                          "runStopping",
                          "elusiveness",
                          "routeRunning",
                          "hands",
                          "ballSecurity",
                          "kickPower",
                          "kickAccuracy",
                          "puntPower",
                          "puntAccuracy",
                        ]}
                        required
                        name="metric"
                        value={formInputs[0]}
                        onChange={(e) => {
                          const newFormInputs = [...formInputs];
                          newFormInputs[0] = e;
                          setFormInputs(newFormInputs);
                        }}
                      />
                    </div>
                    <div class="col-6">
                      <NumberInput
                        label="Metric value"
                        placeholder="Input placeholder"
                        allowNegative={false}
                        min={1}
                        max={19}
                        required
                        name="value"
                        value={formInputs[1]}
                        onChange={(e) => {
                          const newFormInputs = [...formInputs];
                          newFormInputs[1] = e;
                          setFormInputs(newFormInputs);
                        }}
                      />
                    </div>
                    <div class="col-6">
                      <NumberInput
                        label="Min Age"
                        placeholder="Input placeholder"
                        allowNegative={false}
                        min={18}
                        required
                        name="minAge"
                        value={formInputs[2]}
                        onChange={(e) => {
                          const newFormInputs = [...formInputs];
                          newFormInputs[2] = e;
                          setFormInputs(newFormInputs);
                        }}
                      />
                    </div>
                    <div class="col-6">
                      <NumberInput
                        label="Max Age"
                        placeholder="Input placeholder"
                        allowNegative={false}
                        min={19}
                        max={40}
                        required
                        name="maxAge"
                        value={formInputs[3]}
                        onChange={(e) => {
                          const newFormInputs = [...formInputs];
                          newFormInputs[3] = e;
                          setFormInputs(newFormInputs);
                        }}
                      />
                    </div>
                    <div className="button_cont d-flex align-items-center justify-content-center">
                      <Button
                        variant="filled"
                        className="mt-3"
                        color="#009B3A"
                        type="submit"
                      >
                        Search
                      </Button>
                    </div>
                  </div>
                </form>
              </div>
            )}

            {searched && (
              <div className="search_results_cont mt-4">
                <div className="row">
                  {scoutresults?.map((result, index) => (
                    <div className="col-6 px-2 mb-3" key={index}>
                      <SearchResult result={result} />
                    </div>
                  ))}
                </div>
              </div>
            )}
          </>
        )}
        {activeIcon === "Message" && <Messaging />}
      </div>
    </div>
  );
};

export default ScoutDashboard;
