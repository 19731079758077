import React from "react";
import ReactDOM from "react-dom/client";
import { NavigationProgress } from "@mantine/nprogress";
import { MantineProvider } from "@mantine/core";
import "./index.css";
import App from "./App";
import { store } from "./auth/store";
import { Provider } from "react-redux";

import "@mantine/core/styles.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "@mantine/nprogress/styles.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <MantineProvider>
      {/* <NavigationProgress withinPortal={false} size={10} color="#3E68FF" /> */}
      <App />
    </MantineProvider>
  </Provider>
);
