import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

const API_URL = `${process.env.REACT_APP_IP_ADDRESS}/api/users/Institute`;

function NewSignUp() {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get("ID");
    if (id) {
      fetchInviteDetails(id);
    }
  }, [location]);

  const fetchInviteDetails = async (id) => {
    try {
      const response = await axios.post(`${API_URL}/getInviteDetails`, { id });
      console.log("Invite Details Response:", response);

      if (response.data?.type === "Coach") {
        navigate("/signupcoachbyinst", {
          replace: true,
          state: {
            emailFromInst: response.data?.email,
            instituteIdFromInst: response.data?.instituteId,
          },
        });
      } else if (response.data?.type === "Player") {
        navigate("/signupbyinst", {
          replace: true,
          state: {
            emailPlayerFromInst: response.data?.email,
            teamName: response.data?.teamName,
            instituteIdFromInst: response.data?.instituteId,
          },
        });
      }
    } catch (error) {
      console.error("Failed to fetch invite details:", error);
      navigate("/error-route", { replace: true });
    }
  };

  return null;
}

export default NewSignUp;
