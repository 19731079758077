import React, { useState, useEffect } from "react";
import "../styles/CoachDashboardStyles.scss";
import { Input, TextInput, Select, NumberInput, Button } from "@mantine/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Avatar } from "@mantine/core";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../auth/authslice";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import ScoutSearch from "../components/ScoutSearch";
import SearchResult from "../components/SearchResult";
import { Switch } from "@mantine/core";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Tooltip from "@mui/material/Tooltip";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import CoachStats from "../components/CoachStats";
import Messaging from "../components/Messaging";
import Practice from "../components/Practice";
import { Menu } from "@mantine/core";
import Line from "../components/LineChart";
import Settings from "../components/Settings";
import CoachNotifications from "../components/CoachNotifications";
import Empty from "../components/Empty";
import VideoEditor from "../components/VideoEditor";
import { toast } from "react-toastify";

import {
  faHouse,
  faFutbol,
  faBookmark,
  faPeopleGroup,
  faSignal,
  faGear,
  faEnvelope,
  faArrowRightFromBracket,
  faMagnifyingGlass,
  faBell,
  faCircleInfo,
  faPlus,
  faChartSimple,
  faMessage,
  faFootball,
  faRotate,
  faArrowLeft,
  faVideo,
} from "@fortawesome/free-solid-svg-icons";
import CoachHome from "../components/CoachHome";
import Training from "../components/Training";
import { useDisclosure } from "@mantine/hooks";
import { Modal } from "@mantine/core";
import { CloseButton } from "@mantine/core";
const CoachDashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const port = process.env.REACT_APP_IP_ADDRESS;
  const API_URL = `${port}/api/`;
  const handleLogout = () => {
    dispatch(logout());
    navigate("/loginCoach");
  };
  const { user } = useSelector((state) => state.auth);
  useEffect(() => {
    if (user == null) {
      navigate("/loginCoach");
    } else {
      console.log(user);
    }
  }, [user]);
  const [searched, setSearched] = useState(false);
  const [scoutresults, setScoutResults] = useState({});
  const [formInputs, setFormInputs] = useState([]);
  const [activeIcon, setActiveIcon] = useState("Dashboard");
  const searchScout = async () => {
    const data = {
      text: "a",
      ageRange: {
        minAge: formInputs[2],
        maxAge: formInputs[3],
      },
      metrics: {
        [formInputs[0]]: formInputs[1],
      },
      saveSearch: formInputs[4],
    };
    try {
      const response = await axios.post(`${API_URL}search/unifiedSearch`, {
        scoutId: user._id,
        ...data,
      });
      console.log(response.data);
      setScoutResults(response.data);
    } catch (err) {
      console.log(err);
    } finally {
      setSearched(true);
    }
  };
  const onSubmit = (e) => {
    e.preventDefault();
    console.log(formInputs);
    searchScout();
  };

  const [requests, setrequests] = useState([]);
  const getrequests = async () => {
    const response = await axios.post(
      `${port}/api/Request/viewIncomingRequests`,
      {
        id: user._id,
      }
    );
    console.log(response.data);
    setrequests(response.data);
  };
  const approveRequest = async (request, status) => {
    try {
      const response = await axios.post(
        `${port}/api/Request/respondToRequest`,
        {
          requestId: request._id,
          response: status,
        }
      );
      console.log(response.data);
    } catch (err) {
      console.log(err);
    } finally {
      getrequests();
    }
  };

  const [team, setTeam] = useState();
  const [teams, setTeams] = useState([]);

  const getTeams = async () => {
    try {
      const res = await axios.post(
        `${API_URL}users/Institute/getAllTeamsForInstitute`,
        {
          instituteId: user?.Institute,
        }
      );

      setTeams(res.data);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getTeams();
    getPlayers();
  }, []);
  const goback = () => {
    setTeam();
  };
  const [players, setPlayers] = useState([]);
  const getPlayers = async () => {
    console.log(user);
    try {
      const response = await axios.post(
        `${API_URL}users/Institute/getAllPlayersFromInstitute`,
        {
          instituteId: user?.Institute,
        }
      );
      console.log(response.data);
      setPlayers(response.data);
    } catch (err) {
      console.log(err);
    }
  };
  const [selectedTeamPlayers, setSelectedTeamPlayers] = useState([]);
  useEffect(() => {
    if (team) {
      filterplayersbyteam();
    }
  }, [team]);
  const filterplayersbyteam = () => {
    players.map((player) => {
      if (player?.name === team) {
        setSelectedTeamPlayers(player?.players);
      }
    });
  };
  const [SidebarHidden, setSidebarHidden] = useState(false);
  useEffect(() => {
    setSidebarHidden(false);
  }, []);
  const [upload, setupload] = useState(false);

  const [vidImport, setVidimport] = useState(false);
  const [vidImportOpen, setVidImportOpen] = useState(false);
  const [teamImp, setTeamImp] = useState("");

  const handleInputChange = (e) => {
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    setFormData({ ...formData, [e.target.name]: value });
  };

  // const user = JSON.parse(sessionStorage.getItem("user"));

  const [formData, setFormData] = useState({
    HuddleURL: "",
    Team1: "",
    Team2: "",

    DateOfMatch: dayjs(),
    InstituteId: user?.Institute,
    markforAICuts: false,
    markforVACuts: false,
  });

  const toggleVidImportModal = () => {
    setFormData({
      HuddleURL: "",
      Team1: "",
      Team2: "",

      DateOfMatch: dayjs(),
      InstituteId: user?.Institute,
      markforAICuts: false,
      markforVACuts: false,
    });
    setVidImportOpen((o) => !o);
  };

  const handleSubmit = () => {
    const user = JSON.parse(sessionStorage.getItem("user"));
    const coachID = user?._id;
    console.log("from video editor", teamImp);
    const requestData = {
      CoachId: coachID,
      HudlURL: formData.HuddleURL,
      Team1: formData.Team1,
      Team2: formData.Team2,
      DateOfMatch: formData.DateOfMatch,
      InstituteId: formData.InstituteId,
      markforAICuts: formData.markforAICuts,
      markforVACuts: formData.markforVACuts,
      InstTeam: teamImp,
    };

    // Make the API call
    axios
      .post(`${port}/api/Videos/importfromHudl`, requestData)
      .then((response) => {
        // Handle success
        console.log("API call success:", response.data);
        toast.success(
          <>
            <img src="images/new-logo.png" className="toast_logo" />
            <p className="mt-3">Download is starting.</p>
          </>,

          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          }
        );
        // Reset the form data
        setFormData({
          HuddleURL: "",
          Team1: "",
          Team2: "",

          DateOfMatch: dayjs(),
          InstituteId: user?.Institute,
          markforAICuts: false,
          markforVACuts: false,
        });
        // Close the modal
        setVidImportOpen(false);
      })
      .catch((error) => {
        // Handle error
        console.error("API call error:", error);
        toast.error(
          <>
            <img src="images/new-logo.png" className="toast_logo" />
            <p className="mt-3">Error downloading video.</p>
          </>,

          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          }
        );
        // Reset the form data
        setFormData({
          ...formData,
          HuddleURL: "",
          Team1: "",
          Team2: "",

          DateOfMatch: dayjs(),
          markforAICuts: false,
          markforVACuts: false,
        });
        // Close the modal
        setVidImportOpen(false);
      })
      .finally(() => {
        // Reset the form data
        setFormData({
          HuddleURL: "",
          Team1: "",
          Team2: "",

          DateOfMatch: dayjs(),
          InstituteId: user?.Institute,
          markforAICuts: false,
          markforVACuts: false,
        });

        setVidImportOpen(false);
      });

    setVidImportOpen(false);
  };

  const [goBack, setgoback] = useState(false);
  return (
    <div className="dashboard_cont ">
      {!user?.Institute && (
        <div className="confirmation_cont d-flex flex-column align-items-center justify-content-center">
          <img src="/images/new-logo.png" alt="" id="logo" />
          <img src="images/thankyou_signup.png" alt="" className="gray_scale" />
          <h1 className="mb-5">Thank you for Signing up</h1>
          <h4 className="mb-5">
            Your details are being verified and your institute will need to add
            you. <br />
            <br />
            <br />
            Thank you for choosing Athlantix{" "}
          </h4>
          <Button
            variant="outline"
            radius="md"
            className="mb-2"
            color="green"
            onClick={() => {
              dispatch(logout());
            }}
          >
            Go back home
          </Button>
        </div>
      )}
      {user?.Institute && (
        <>
          {user?.isPaid ? (
            <>
              {" "}
              {!SidebarHidden && (
                <div className="sidebar">
                  <div className="sidebar_logo d-flex  align-items-center justify-content-center pt-4">
                    <img
                      src="/images/new-logo.png"
                      alt=""
                      className="me-1"
                      onClick={() => setActiveIcon("Dashboard")}
                    />
                  </div>
                  <div className="sidebar_cont   d-flex flex-column justify-content-between ">
                    <div className="sidebar_cont_top d-flex flex-column justify-content-start">
                      <>
                        <div
                          className={`icon_cont mb-4 ${
                            activeIcon === "Dashboard" ? "active_nav" : ""
                          }`}
                          onClick={() => setActiveIcon("Dashboard")}
                        >
                          <FontAwesomeIcon icon={faHouse} />
                          <p>Dashboard</p>
                        </div>
                        <div
                          className={`icon_cont mb-4 ${
                            activeIcon === "Players" ? "active_nav" : ""
                          }`}
                          onClick={() => setActiveIcon("Players")}
                        >
                          <FontAwesomeIcon icon={faBookmark} />
                          <p>Players</p>
                        </div>

                        <div
                          className={`icon_cont mb-4 ${
                            activeIcon === "Message" ? "active_nav" : ""
                          }`}
                          onClick={() => setActiveIcon("Message")}
                        >
                          <FontAwesomeIcon icon={faMessage} />
                          <p>Message</p>
                        </div>
                        <div
                          className={`icon_cont mb-4 ${
                            activeIcon === "Training" ? "active_nav" : ""
                          }`}
                          onClick={() => setActiveIcon("Training")}
                        >
                          <FontAwesomeIcon icon={faFootball} />
                          <p>Training</p>
                        </div>
                        <div
                          className={`icon_cont mb-4 ${
                            activeIcon === "Practice" ? "active_nav" : ""
                          }`}
                          onClick={() => setActiveIcon("Practice")}
                        >
                          <FontAwesomeIcon icon={faRotate} />
                          <p>Practice</p>
                        </div>
                        <div
                          className={`icon_cont mb-4 ${
                            activeIcon === "Video" ? "active_nav" : ""
                          }`}
                          onClick={() => setActiveIcon("Video")}
                        >
                          <FontAwesomeIcon icon={faVideo} />
                          <p>Video</p>
                        </div>

                        <div
                          className={`icon_cont mb-4 ${
                            activeIcon === "Notifictaion" ? "active_nav" : ""
                          }`}
                          onClick={() => setActiveIcon("Notifictaion")}
                        >
                          <FontAwesomeIcon icon={faBell} />
                          <p>Notification</p>
                        </div>
                        <div
                          className={`icon_cont ${
                            activeIcon === "Settings" ? "active_nav" : ""
                          }`}
                          onClick={() => setActiveIcon("Settings")}
                        >
                          <FontAwesomeIcon icon={faGear} />
                          <p>Settings</p>
                        </div>
                      </>
                    </div>
                    <div className="sidebar_cont_bottom">
                      {" "}
                      <div className="icon_cont" onClick={handleLogout}>
                        <FontAwesomeIcon icon={faArrowRightFromBracket} />{" "}
                        <p>Logout</p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div
                className={`scout_main px-4 py-3 ${
                  SidebarHidden && "no_sidebar_cont"
                } `}
              >
                <div
                  className={`main_top d-flex ${
                    SidebarHidden
                      ? "justify-content-between"
                      : "justify-content-end"
                  }  align-items-center`}
                >
                  {SidebarHidden && (
                    <div className="sidebar_logo d-flex  align-items-center justify-content-center pt-4">
                      <img src="/images/new-logo.png" alt="" className="me-4" />
                      <Button
                        variant="outline"
                        color="grey"
                        radius={"xl"}
                        leftSection={
                          <FontAwesomeIcon
                            icon={faArrowLeft}
                            color="rgba(255,255,255,0.5)"
                          />
                        }
                        onClick={() => setgoback(true)}
                      >
                        back
                      </Button>
                    </div>
                  )}
                  <div
                    className={`main_top_right d-flex justify-content-between align-items-center ${
                      SidebarHidden && "larger_top_right"
                    }`}
                  >
                    {SidebarHidden && (
                      <>
                        <Button
                          variant="outline"
                          color="blue"
                          radius={"xl"}
                          onClick={toggleVidImportModal}
                        >
                          Import
                        </Button>
                        <Modal
                          opened={vidImportOpen}
                          onClose={toggleVidImportModal}
                          title="Import Video"
                          size={"xl"}
                          centered
                        >
                          <>
                            <div>
                              <Tooltip
                                title="You can find the Huddle URL in your email"
                                arrow
                              >
                                <div>
                                  <Input.Wrapper
                                    label="Huddle URL"
                                    className="my-3"
                                  >
                                    <Input
                                      radius={"md"}
                                      className="mt-2 "
                                      value={formData.HuddleURL}
                                      onChange={handleInputChange}
                                      name="HuddleURL"
                                    />
                                  </Input.Wrapper>
                                </div>
                              </Tooltip>
                            </div>
                            <div className="d-flex align-items-end justify-content-between  ">
                              <Input.Wrapper
                                label="Home Team Name"
                                className="my-3"
                              >
                                <Input
                                  radius={"md"}
                                  className="mt-2 team_name_input"
                                  value={formData.Team1}
                                  onChange={handleInputChange}
                                  name="Team1"
                                ></Input>
                              </Input.Wrapper>
                              <h4 className="mx-3 mb-4">vs</h4>

                              <Input.Wrapper
                                label="Away Team Name"
                                className="my-3"
                              >
                                <Input
                                  radius={"md"}
                                  className="mt-2 team_name_input"
                                  value={formData.Team2}
                                  onChange={handleInputChange}
                                  name="Team2"
                                ></Input>
                              </Input.Wrapper>
                            </div>
                            <div className="d-flex align-items-center justify-content-between date_ai_assist">
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                  value={formData.DateOfMatch}
                                  onChange={(newvlue) => {
                                    setFormData({
                                      ...formData,
                                      DateOfMatch: newvlue,
                                    });
                                  }}
                                  name="DateOfMatch"
                                  sx={{
                                    ".Mui-selected": {
                                      backgroundColor: "#009B3A !important",
                                    },
                                    ".MuiInputBase-root ": {
                                      borderRadius: "10px",

                                      width: "100%",
                                      color: "#cdcdcd",
                                    },
                                    "MuiTextField-root": {
                                      width: "100%",
                                    },
                                    ".MuiFormControl-root": {
                                      border: "none",
                                      width: "100% !important",
                                    },
                                    ".MuiOutlinedInput-notchedOutline": {
                                      border: "none",
                                      backgroundColor: "rgba(57, 60, 82, 0.2)",
                                      width: "100%",
                                    },
                                    ".MuiDateCalendar-root": {
                                      color: "#ffffff",
                                      padding: "5%",
                                      borderRadius: "16px",
                                      backgroundColor: "rgba(57, 60, 82, 0.2)",
                                    },
                                    ".MuiPickersToolbar-root": {
                                      color: "#ffffff",

                                      backgroundColor: "#101222",
                                    },
                                    ".MuiPickersCalendarHeader-root": {
                                      backgroundColor: "rgba(57, 60, 82, 0.2)",
                                      borderRadius: "16px",
                                      paddingTop: "20px",
                                      paddingBottom: "20px",
                                    },
                                    ". MuiPickersLayout-root": {
                                      backgroundColor: "#101222",
                                      color: "#ffffff",
                                    },
                                    ".MuiPickersLayout-contentWrapper": {
                                      backgroundColor: "#101222",
                                      color: "#ffffff",
                                    },
                                    ".MuiDialogActions-root": {
                                      backgroundColor: "#101222",
                                      color: "#ffffff",
                                      display: "none",
                                    },
                                    ".MuiDayCalendar-weekDayLabel": {
                                      color: "#ffffff",
                                    },
                                    ".MuiPickersDay-root": {
                                      color: "#ffffff",
                                      backgroundColor: "#101222",
                                    },
                                    ".MuiButtonBase-root": {
                                      color: "#ffffff",
                                    },
                                    ".Mui-selected": {
                                      backgroundColor: "#009B3A !important",
                                    },
                                  }}
                                />
                              </LocalizationProvider>
                              <Switch
                                name="markforAICuts"
                                value={formData.markforAICuts}
                                onChange={handleInputChange}
                                label={
                                  <div className="d-flex align-items-center">
                                    <img
                                      className="radio_image me-2"
                                      src="images/magicpen.png"
                                    />
                                    <p className="m-0 radio_text ">
                                      Huddle & AI Cut
                                    </p>
                                  </div>
                                }
                              />
                              <Switch
                                name="markforVACuts"
                                value={formData.markforVACuts}
                                onChange={handleInputChange}
                                // iconColor="#009b3a"
                                // color="#232535"
                                label={
                                  <div className="d-flex align-items-center">
                                    <img
                                      className="radio_image me-2"
                                      src="images/Vector.png"
                                    />
                                    <p className="m-0 radio_text ">
                                      Athlantix Assist
                                    </p>
                                  </div>
                                }
                              />
                            </div>

                            <div className="button_cont_modal d-flex align-items-center justify-content-center mt-3">
                              <Button
                                onClick={toggleVidImportModal}
                                className="me-3"
                                radius={"xl"}
                                color="red"
                              >
                                Cancel
                              </Button>
                              <Button
                                onClick={() => handleSubmit()}
                                radius={"xl"}
                                color="green"
                              >
                                Submit
                              </Button>
                            </div>
                          </>
                        </Modal>
                        <Button
                          variant="outline"
                          color="green"
                          radius={"xl"}
                          onClick={() => setupload(true)}
                        >
                          Upload
                        </Button>
                      </>
                    )}
                    <div className="notification_cont">
                      <Menu closeOnItemClick={false} position="bottom-end">
                        <Menu.Target>
                          <FontAwesomeIcon
                            icon={faBell}
                            onClick={() => getrequests()}
                            size="xl"
                          />
                        </Menu.Target>
                        <Menu.Dropdown>
                          <Menu.Label>
                            <div className="dropdown_header d-flex align-items-center justify-content-start ">
                              <h6> {`Notifications (${requests?.length})`}</h6>
                            </div>
                          </Menu.Label>
                          {requests &&
                            requests?.map((item, index) => (
                              <Menu.Item className="mb-3" key={index}>
                                <div className="notification d-flex flex-column ">
                                  <h6 style={{ padding: "0", margin: "0" }}>
                                    {item?.institute?.name}
                                  </h6>
                                  <p style={{ padding: "0", margin: "0" }}>
                                    {item?.institute?.name} has invited you to
                                    be a coach
                                  </p>
                                  <div className="mt-2 d-flex justify-content-center align-items-center button_cont">
                                    <Button
                                      variant="filled"
                                      color="green"
                                      className="me-2"
                                      onClick={() => approveRequest(item, true)}
                                    >
                                      Accept
                                    </Button>
                                    <Button
                                      variant="filled"
                                      color="red"
                                      onClick={() =>
                                        approveRequest(item, false)
                                      }
                                    >
                                      Reject
                                    </Button>
                                  </div>
                                </div>
                              </Menu.Item>
                            ))}
                          {!requests.length && (
                            <div className="no_notifications_cont d-flex align-items-center justify-content-center">
                              <h4 className="text-center">
                                No Notifications Found
                              </h4>
                            </div>
                          )}
                        </Menu.Dropdown>
                      </Menu>
                    </div>
                    <Avatar color="cyan" radius="xl">
                      {/* <img src="images/team-logo.png" alt="" /> */}
                      <img className="user_image" src={user?.Image} alt="" />
                    </Avatar>
                  </div>
                </div>
                {activeIcon == "Players" && (
                  <>
                    {team ? (
                      <>
                        <div className="d-flex align-items-center">
                          <FontAwesomeIcon
                            icon={faArrowLeft}
                            size="lg"
                            className="me-3 back_icon"
                            onClick={goback}
                          />
                          <h3 id="my_team">My Team</h3>
                        </div>
                        <div className="d-flex flex-wrap align-items-center mb-3 mt-3">
                          {selectedTeamPlayers.map((player, index) => (
                            <Myplayer player={player} key={index} />
                          ))}
                        </div>
                      </>
                    ) : (
                      <>
                        <h2 style={{ color: "#ffffff" }}>Teams</h2>
                        <div className="d-flex align-items-center justify-content-start flex-wrap">
                          {teams?.map((team, index) => (
                            <div
                              className="Team_box m-2 d-flex flex-column align-items-center justify-content-center"
                              onClick={() => {
                                setTeam(team);
                              }}
                            >
                              <img src="images/team-logo.png" alt="" />
                              <div className="design_box d-flex align-items-center justify-content-center">
                                <h4 style={{ color: "#ffffff" }}>{team}</h4>
                              </div>
                            </div>
                          ))}
                        </div>
                      </>
                    )}
                  </>
                )}

                {activeIcon === "Dashboard" && <CoachHome />}
                {activeIcon === "Message" && <Messaging />}
                {activeIcon === "Training" && <Training />}
                {activeIcon === "Video" && (
                  <VideoEditor
                    hideSidebar={() => setSidebarHidden(!SidebarHidden)}
                    upload={upload}
                    setupload={setupload}
                    vidImport={vidImport}
                    setVidimport={setVidimport}
                    goBack={goBack}
                    setgoback={setgoback}
                    setTeamImp={setTeamImp}
                  />
                )}

                {activeIcon === "Settings" && <Settings type="Coach" />}
                {activeIcon === "Notifictaion" && <CoachNotifications />}

                {activeIcon === "Practice" && <Practice />}
              </div>
            </>
          ) : (
            <>
              <div className="confirmation_cont d-flex flex-column align-items-center justify-content-center">
                <img src="/images/new-logo.png" alt="" id="logo" />
                <img
                  src="images/payment_failed.png"
                  alt=""
                  className="gray_scale"
                />
                <h1 className="mb-5"></h1>
                <h1 className="mb-5">Institute Access Blocked</h1>
                <h4 className="mb-5">
                  Your access has been blocked. Please check back later or
                  contact your Institute
                  <br /> if the problem persists.
                </h4>
                <Button
                  variant="outline"
                  radius="md"
                  className="mb-2"
                  color="green"
                  onClick={() => {
                    dispatch(logout());
                  }}
                >
                  Go back home
                </Button>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default CoachDashboard;

const Myplayer = ({ player }) => {
  const [opened, { open, close }] = useDisclosure(false);
  return (
    <>
      <Modal
        opened={opened}
        onClose={close}
        withCloseButton={false}
        centered={true}
        size="lg"
        radius={10}
      >
        <div className="d-flex flex-column align-items-center justify-content-between my-5">
          <h5 className="mb-3">Are you sure you want to delete this player?</h5>

          <div className="d-flex align-items-center justify-content-center">
            <Button
              variant="filled"
              className="me-2"
              color="rgba(5, 128, 23, 1)"
              onClick={close}
            >
              Decline
            </Button>
            <Button variant="filled" color="rgba(163, 2, 2, 1)" onClick={close}>
              Accept
            </Button>
          </div>
        </div>
      </Modal>
      <div className="d-flex  align-items-center  ">
        <div className="Coach_Player m-2">
          <CloseButton
            size="lg"
            variant="transparent"
            className="close_button"
            onClick={open}
          />
          <div className="row">
            <div className="col-6">
              <img src={player?.Image} alt="" />
              {/* <img src="images/player-1.png" alt="" /> */}
            </div>
            <div className="col-6 pt-4">
              <h5>{player?.firstName + " " + player?.lastName}</h5>
              {/* <h5>ABC XYZ</h5> */}
              <div className="d-flex align-items-center mt-2 ">
                <p className="metric me-3">Position</p>
                <p className="value">Forward right winger</p>
              </div>
              <div className="d-flex align-items-center mt-2 ">
                <p className="metric me-3">Weight</p>
                <p className="value">{player?.weight} lbs</p>
              </div>
              <div className="d-flex align-items-center mt-2 ">
                <p className="metric me-3">Jersey Number</p>
                <p className="value">{player?.jerseyNumber}</p>
              </div>
              {/* <div className="button_cont d-flex align-items-center justify-content-center mt-4">
                <Button
                  variant="filled"
                  color="rgba(57, 60, 82, 0.2)"
                  size="sm"
                  radius="xl"
                  className="me-3"
                >
                  Add Player
                </Button>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
