import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./PlayerRequestsStyles.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Modal } from "@mantine/core";
import { Textarea } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { toast } from "react-toastify";
import {
  faMagnifyingGlass,
  faBell,
  faCircleInfo,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
import { Avatar } from "@mantine/core";
import axios from "axios";

const PlayerRequests = () => {
  const { user } = useSelector((state) => state.auth);
  const port = process.env.REACT_APP_IP_ADDRESS;
  const API_URL = `${port}/api/Requirements`;
  const [SelectedCategory, setSelectedCategory] = useState("");
  const goback = () => {
    if (ViewSentRequest) {
      setViewSentRequest(false);
    } else {
      setSelectedCategory("");
    }
  };
  useEffect(() => {
    if (SelectedCategory != "") {
      getReq();
    }
  }, [SelectedCategory]);

  const [Requirements, setRequirements] = useState([]);
  const getReq = async () => {
    try {
      const res = await axios.post(`${API_URL}/getPlayerReqbyType`, {
        playerId: user?._id,
        reqType: SelectedCategory,
      });
      console.log(res.data);
      setRequirements(res.data);
    } catch (error) {
      console.log(error);
    }
  };
  const [requestLoading, setRequestLoading] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const sendreq = async (req) => {
    setRequestLoading(true);

    try {
      const res = await axios.post(`${API_URL}/requestRequirementUpdate`, {
        playerId: user?._id,
        reqHeading: req?.reqHeading,
        instituteId: user?.InstituteID,
      });
      console.log(res.data);
      toast.success(
        <>
          <img src="images/new-logo.png" className="toast_logo" />
          <p className="mt-3">Request Sent Successfully</p>
        </>,

        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        }
      );
      getReq();
    } catch (error) {
      toast.error(
        <>
          <img src="images/new-logo.png" className="toast_logo" />
          <p className="mt-3">Request Limit Reached</p>
        </>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        }
      );
      console.log(error);
    } finally {
      setRequestLoading(false);
    }
  };

  const catMap = {
    Edu: "Academic",
    CoEdu: "Non-Academic",
    Other: "Other",
  };
  const [ViewSentRequest, setViewSentRequest] = useState(false);
  const [opened, { open, close }] = useDisclosure(false);
  const [requirementRequests, setrequiremnetRequests] = useState([]);
  const getRequirementRequests = async () => {
    try {
      const res = await axios.post(`${API_URL}/ViewmyRequirementRequests`, {
        playerId: user?._id,
      });
      console.log(res.data);
      setrequiremnetRequests(res.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (ViewSentRequest) {
      getRequirementRequests();
    }
  }, [ViewSentRequest]);

  const [selectedSentRequest, setSelectedSentRequest] = useState(null);
  const [PlayerComment, setPlayerComment] = useState("");
  const [PlayerCommentError, setPlayerCommentError] = useState(false);
  const sendupdatebyplayer = async () => {
    try {
      const res = await axios.post(`${API_URL}/updatePlayerReqbyPlayer`, {
        playerId: user?._id,
        reqHeading: selectedSentRequest?.reqHeading,
        playerComment: PlayerComment,
      });
      console.log(res.data);
      toast.success(
        <>
          <img src="images/new-logo.png" className="toast_logo" />
          <p className="mt-3">Comment Added Successfully</p>
        </>,

        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        }
      );

      close();
      getReq();
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <div className="palyer_request">
        {!SelectedCategory && !ViewSentRequest && (
          <>
            <h3>Requirements</h3>

            <div className="d-flex align-items-center justify-content-between mt-4">
              <p className="contrast_text">Select Requirements Category</p>
              <Button
                variant="filled"
                color="rgba(24, 26, 44, 1)"
                size="md"
                radius={"md"}
                onClick={() => setViewSentRequest(true)}
              >
                View Sent Requests
              </Button>
            </div>

            <div className="categories d-flex align-items-center justify-content-start">
              <div
                className="category d-flex flex-column align-items-center justify-content-center mx-2 "
                onClick={() => setSelectedCategory("Edu")}
              >
                <div className="glow"></div>
                <img className="mb-2" src="images/Cap.png" alt="" />
                <h5>Academic</h5>
              </div>
              <div
                className="category d-flex flex-column align-items-center justify-content-center mx-2 "
                onClick={() => setSelectedCategory("CoEdu")}
              >
                <div className="glow"></div>
                <img className="mb-2" src="images/Rugby.svg" alt="" />
                <h5>Non-Academic</h5>
              </div>
              <div
                className="category d-flex flex-column align-items-center justify-content-center mx-2 "
                onClick={() => setSelectedCategory("Other")}
              >
                <div className="glow"></div>
                <img className="mb-2" src="images/other.png" alt="" />
                <h5>Other</h5>
              </div>
            </div>
          </>
        )}
        {SelectedCategory && !ViewSentRequest && (
          <>
            <div className="d-flex align-items-center justify-content-start mb-3">
              <FontAwesomeIcon
                icon={faArrowLeft}
                className="me-3 back_icon"
                onClick={goback}
              />
              <h4>{catMap[SelectedCategory]}</h4>
            </div>
            <div className="req_header py-1 px-3 mb-4">
              <h4>Requirements</h4>
            </div>

            {Requirements?.map((req, index) => (
              <div
                className="d-flex align-items-center justify-content-between px-2 requirement mt-2"
                onClick={() => {
                  open();
                  setSelectedSentRequest(req);
                }}
              >
                <div className="d-flex flex-column align-items-start req_desc pb-2">
                  <h5>{req?.reqHeading}</h5>
                  <p>{req?.reqDescription}</p>
                </div>
                <Button
                  variant="filled"
                  color="rgba(24, 26, 44, 1)"
                  size="md"
                  radius={"xl"}
                  onClick={() => {
                    sendreq(req);
                    setSelectedRequest(req?._id);
                  }}
                  loading={requestLoading && req?._id === selectedRequest}
                >
                  Send Approval Request
                </Button>
              </div>
            ))}
          </>
        )}
        {ViewSentRequest && (
          <>
            <div className="d-flex align-items-center justify-content-start mb-3">
              <FontAwesomeIcon
                icon={faArrowLeft}
                className="me-3 back_icon"
                onClick={goback}
              />
              <h4>View Sent Request</h4>
            </div>
            {requirementRequests?.map((req, index) => (
              <div className="sent_requests d-flex align-items-center justify-content-between px-3 mb-3">
                <div className="d-flex flex-column">
                  <div className="d-flex mb-2">
                    <h5 className="me-5">{req?.Requirement?.reqHeading}</h5>
                    {req?.Requirement?.reqTags?.map((tag, index) => (
                      <div className="label d-flex align-items-center justify-content-center px-2 me-2">
                        <img
                          src="images/label_green.png"
                          alt=""
                          className="me-3"
                        />
                        <p>{tag}</p>
                      </div>
                    ))}
                  </div>
                  <h5>Description:</h5>
                  <p>{req?.Requirement?.reqDescription}</p>
                </div>
                <div className="request_status d-flex align-items-center justif-content-center px-3 py-1">
                  <p>Pending Approval Request</p>
                </div>
              </div>
            ))}
          </>
        )}
        <Modal
          opened={opened}
          onClose={close}
          centered
          size={"lg"}
          id="Player_request_modal"
        >
          <div className="d-flex align-items-center justify-content-between mt-3 mb-4">
            <h4 className="me-3">{selectedSentRequest?.reqHeading}</h4>

            <div className="request_status d-flex align-items-center justif-content-center px-3 py-1">
              <p>{selectedSentRequest?.status}</p>
            </div>
          </div>
          <div className="d-flex mb-3 flex-wrap">
            {selectedSentRequest?.reqTags?.map((tag, index) => (
              <div className="label d-flex align-items-center justify-content-center px-2 py-1 me-2">
                <img src="images/label.png" alt="" className="me-3" />
                <p>{tag}</p>
              </div>
            ))}
          </div>
          <h5 className="mb-3">Description: </h5>
          <p className="desc_text mb-3">
            {selectedSentRequest?.reqDescription}
          </p>
          <Textarea
            className="mb-3"
            label={<h6 className="mb-3">Add Comment :</h6>}
            placeholder="Write Comment Here"
            autosize
            minRows={"3"}
            value={PlayerComment}
            error={PlayerCommentError && "Comment is Required"}
            onChange={(e) => setPlayerComment(e.target.value)}
          />
          <div className="d-flex align-items-center justify-content-center">
            <Button
              variant="filled"
              color="green"
              onClick={() => {
                if (PlayerComment?.length !== 0) {
                  sendupdatebyplayer();
                } else {
                  setPlayerCommentError(true);
                }
              }}
            >
              Send
            </Button>
          </div>
          <h5 className="mb-3">Comments :</h5>
          {selectedSentRequest?.adminComment?.map((comment, index) => (
            <>
              <h6 className="user_name">Admin</h6>
              <p className="comment ">{comment}</p>
            </>
          ))}
          {selectedSentRequest?.playerComment?.map((comment, index) => (
            <>
              <h6 className="user_name">Player</h6>
              <p className="comment ">{comment}</p>
            </>
          ))}
        </Modal>
      </div>
    </>
  );
};

export default PlayerRequests;
