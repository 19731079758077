import "./RequirementRequestsStyles.scss";
import React, { useState, useEffect } from "react";
import { Avatar } from "@mantine/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal } from "@mantine/core";
import { Button } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import {
  faMagnifyingGlass,
  faBell,
  faCircleInfo,
  faCirclePlus,
  faBookmark,
  faArrowLeft,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { Radio, Group } from "@mantine/core";
import { Textarea } from "@mantine/core";

const RequirementRequests = () => {
  const { user } = useSelector((state) => state.auth);
  const [opened, { open, close }] = useDisclosure(false);

  const port = process.env.REACT_APP_IP_ADDRESS;
  const API_URL = `${port}/api/Requirements`;
  useEffect(() => {
    getRequests();
  }, []);
  const [requests, setRequests] = useState([]);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [comment, setComment] = useState("");
  const [status, setStatus] = useState("");
  const getRequests = async () => {
    try {
      const res = await axios.post(`${API_URL}/viewRequirementUpdateRequests`, {
        instituteId: user?._id,
      });
      console.log(res.data);
      setRequests(res.data);
    } catch (error) {}
  };

  const mapToNoSpace = {
    Fulfilled: "Fulfilled",
    "Partially Fulfilled": "PartiallyFulfilled",
    "Un Fulfilled": "UnFulfilled",
  };
  const updateRequest = async () => {
    console.log(mapToNoSpace[status]);

    try {
      const res = await axios.post(`${API_URL}/updatePlayerReqbyAdmin`, {
        playerId: selectedRequest?.playerId?._id,
        reqHeading: selectedRequest?.Requirement?.reqHeading,
        status: mapToNoSpace[status],
        adminComment: comment,
      });
      console.log(res.data);
      getRequests();
      close();
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (!opened) {
      setComment("");
      setStatus("");
    }
  }, [opened]);
  return (
    <div className="dashboard_content px-4 py-3">
      <div className="main_top d-flex justify-content-end align-items-center">
        <div className="main_top_right d-flex justify-content-between align-items-center ">
          <div className="notification_cont">
            <FontAwesomeIcon icon={faBell} size="xl" />
          </div>
          <Avatar color="cyan" radius="xl">
            {/* <img src="images/team-logo.png" alt="" /> */}
            <img className="user_image" src={user?.Image} alt="" />
          </Avatar>
        </div>
      </div>
      <div className="Requirement_request_cont">
        <h3>Requirements Review Requests </h3>
        <div className="requests_top px-4 pt-2 mt-3 mb-3">
          <div className="row">
            <div className="col-3">
              <p className="heading">Student name</p>
            </div>
            <div className="col-2">
              <p className="heading">Requirement</p>
            </div>
          </div>
        </div>
        <div className="request_cont px-4">
          {requests.map((req) => (
            <div className="request mb-2">
              <div className="row">
                <div className="col-3">
                  <h5 className="player_name">
                    {req?.playerId?.firstName + " " + req?.playerId?.lastName}
                  </h5>
                </div>
                <div className="col-7 ">
                  <h5>{req?.Requirement?.reqHeading}</h5>
                  <p>{req?.Requirement?.reqDescription}</p>
                </div>
                <div className="col-2 d-flex align-items-center justify-content-center">
                  <Button
                    variant="filled"
                    color="rgba(255,255,255,0.2)"
                    size="md"
                    radius={"xl"}
                    onClick={() => {
                      open();
                      setSelectedRequest(req);
                    }}
                  >
                    Respond
                  </Button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Modal
        opened={opened}
        onClose={close}
        centered
        size="xl"
        id="requirement_request_modal"
        overlayProps={{
          backgroundOpacity: 0.55,
          blur: 3,
        }}
      >
        <div className="requirement_request_modal_cont  d-flex flex-column px-5">
          <h4 className="mb-3">
            {selectedRequest?.playerId?.firstName +
              " " +
              selectedRequest?.playerId?.lastName}
          </h4>

          <div className="d-flex  align-items-center justify-content-between fullwidth mb-3">
            <div className="d-flex">
              <h5>{selectedRequest?.Requirement?.reqHeading}</h5>
              <div className="label d-flex align-items-center justify-content-start ms-3 px-3">
                <img src="images/label.png" alt="" className="me-3" />
                <p>Label</p>
              </div>
            </div>
          </div>
          <h6>Description:</h6>
          <p className="desc">{selectedRequest?.Requirement?.reqDescription}</p>

          <div className="status_cont d-flex align-items-center justify-content-between mb-3">
            <Radio.Group
              name="favoriteFramework"
              label="Status:"
              className="status_radio"
              value={status}
              onChange={setStatus}
            >
              <Group mt="xs" justify="space-between">
                <Radio
                  value="Fulfilled"
                  label="Fulfilled"
                  iconColor="#009b3a"
                  color="#232535"
                />
                <Radio
                  value="Partially Fulfilled"
                  label="Partially Fulfilled"
                  iconColor="#009b3a"
                  color="#232535"
                />
                <Radio
                  value="Un Fulfilled"
                  label="Un Fulfilled"
                  iconColor="#009b3a"
                  color="#232535"
                />
              </Group>
            </Radio.Group>
          </div>
          <Textarea
            label="Comment"
            description=" "
            placeholder="Add a comment for the Player..."
            autosize
            minRows={6}
            radius={"lg"}
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
          <div className="buttons_cont d-flex align-items-center justify-content-center mt-4">
            <div className="button_cont me-2">
              <Button variant="outline" color="#009b3a" fullWidth radius={"lg"}>
                Back
              </Button>
            </div>

            <div className="button_cont ms-2">
              {" "}
              <Button
                variant="filled"
                color="#009b3a"
                fullWidth
                radius={"lg"}
                onClick={updateRequest}
              >
                Approve
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default RequirementRequests;
