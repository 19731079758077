import React from "react";
import "./TrainingStyles.scss";
import { Button } from "@mantine/core";
import { Pill } from "@mantine/core";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import dayjs from "dayjs";
import { StaticDateTimePicker } from "@mui/x-date-pickers/StaticDateTimePicker";
import { useDisclosure } from "@mantine/hooks";
import { Modal } from "@mantine/core";
import { useState, useEffect } from "react";
import { Input } from "@mantine/core";
import { Textarea } from "@mantine/core";
import { Progress } from "@mantine/core";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import { useDispatch, useSelector } from "react-redux";
import { NumberInput } from "@mantine/core";
import TeamSkelletonLoader from "../components/TeamSkelletonLoader";
import axios from "axios";
const Training = () => {
  const port = process.env.REACT_APP_IP_ADDRESS;
  const API_URL = `${port}/api/`;
  const { user } = useSelector((state) => state.auth);
  const [opened, { open, close }] = useDisclosure(false);
  const [position, setposition] = useState(0);
  const [date, setdate] = useState(false);
  const [selectedDate, setselectedDate] = useState(dayjs());
  const [team, setTeam] = useState("");
  const [description, setDescription] = useState("");
  const [nextdisabled, setnextdisabled] = useState(true);
  const nextposition = () => {
    if (position < 1) {
      if (!date) {
        setposition(position + 1);
      } else {
        if (selectedPlayer && description) {
          setposition(position + 1);
        }
      }
    } else {
      if (!date) {
        if (team && description) {
          addtraining();
          close();
        }
      }
      if (date) {
        if (
          attendance !== null &&
          Punctuality !== null &&
          ExtraTime !== null &&
          gamefilm !== null &&
          Effort &&
          Coachable &&
          BodyLanguage &&
          MentalToughness &&
          PhysicalToughness &&
          Feedback
        ) {
          addPlayerTraining();
          close();
        }
      }
    }
  };
  const prevposition = () => {
    if (position > 0) {
      setposition(position - 1);
    } else {
      close();
    }
  };
  useEffect(() => {
    setposition(0);
  }, [opened]);

  const [teams, setTeams] = useState([]);

  const getTeams = async () => {
    try {
      const res = await axios.post(
        `${API_URL}users/Institute/getAllTeamsForInstitute`,
        {
          instituteId: user?.Institute,
        }
      );

      setTeams(res.data);
    } catch (err) {
      console.log(err);
    }
  };
  const addtraining = async () => {
    try {
      const res = await axios.post(`${API_URL}Training/addTrainingDay`, {
        date: selectedDate,
        Description: description,
        TeamName: team,
        instituteID: user?.Institute,
      });
    } catch (err) {
      console.log(err);
    } finally {
      setDescription("");
      setTeam("");
      getTrainings();
    }
  };
  const [loading, setloading] = useState(false);
  const [trainigs, settrainings] = useState([]);
  const getTrainings = async () => {
    try {
      setloading(true);
      const res = await axios.post(
        `${API_URL}Training/getAllCurrentTrainings`,
        {
          instituteID: user?.Institute,
        }
      );
      console.log("all trainings", res.data);
      settrainings(res.data);
    } catch (err) {
      console.log(err);
    } finally {
      setloading(false);
    }
  };
  useEffect(() => {
    getTrainings();
    getTeams();
    getPlayers();
  }, []);
  useEffect(() => {
    if (currentTrainingdate) {
      trainigs?.SeasonTrainingDays?.map((currdate) => {
        if (currdate?._id == currentTrainingdate?._id) {
          chooseTraingdate(currdate);
          return;
        }
      });
    }
  }, [trainigs]);
  const [players, setPlayers] = useState([]);
  const [selectedTeamplayers, setselectedTeamplayers] = useState([]);
  const getPlayers = async () => {
    try {
      const res = await axios.post(
        `${API_URL}users/Institute/getAllPlayersFromInstitute`,
        {
          instituteId: user?.Institute,
        }
      );
      console.log("all players", res.data);
      setPlayers(res.data);
    } catch (error) {
      console.log(error);
    }
  };
  // const [selectedDate, setSelectedDate] = useState();
  // const [Trainingdate, setTrainingdate] = useState();
  const chooseTraingdate = (currdate) => {
    console.log("currdate", currdate);
    setcurrentTrainingdate(currdate);
    setdate(true);
  };

  const Trainingdatehelper = (date) => {
    if (!datedelete) {
      chooseTraingdate(date);
    } else {
      deleteTraining(date);
    }
  };

  const adddatehelper = () => {
    open();
    setdatedelete(false);
  };

  //date delete

  const [datetobedeleted, setdatetobedeleted] = useState();
  const [confirmationModal, setconfirmationModal] = useState(false);
  const [deletedateConfirmation, setdeletedateConfirmation] = useState(false);
  const deleteTraining = (date) => {
    setdatetobedeleted(date);
    setconfirmationModal(true);
  };

  useEffect(() => {
    if (deletedateConfirmation && !date) {
      setconfirmationModal(false);
      deleteTrainingdate();
    }
    if (deletedateConfirmation && date) {
      setconfirmationModal(false);
      removepalyertraining();
    }
  }, [deletedateConfirmation]);

  const deleteTrainingdate = async () => {
    console.log("delete date", datetobedeleted);
    try {
      const res = await axios.post(`${API_URL}Training/RemoveTrainingDay`, {
        trainingDayId: datetobedeleted?._id,
        instituteID: user?.Institute,
      });
    } catch (error) {
      console.log(error);
    } finally {
      setdeletedateConfirmation(false);
      setdatetobedeleted();
      setdatedelete(false);
      getTrainings();
    }
  };

  const playertraininghelper = () => {
    open();
    setplayerdeletemode(false);
  };

  //delete player training
  const [playerdeletemode, setplayerdeletemode] = useState(false);
  const [playerTobedeleted, setplayerTobedeleted] = useState();

  const deletePlayerTraining = (player) => {
    if (playerdeletemode) {
      setplayerTobedeleted(player);
      setconfirmationModal(true);
    }
  };

  const removepalyertraining = async () => {
    console.log("delete player", playerTobedeleted);
    try {
      const response = await axios.post(
        `${API_URL}Training/RemoveTrainingPlayer`,
        {
          trainingObjId: playerTobedeleted?._id,
          trainingDayId: currentTrainingdate?._id,
        }
      );
    } catch (error) {
      console.log(error);
    } finally {
      setconfirmationModal(false);
      setplayerTobedeleted();
      setplayerdeletemode(false);
      getTrainings();
    }
  };

  //player trainings
  const [currentTrainingdate, setcurrentTrainingdate] = useState();
  useEffect(() => {
    console.log("--------------------------------fileter called");
    if (currentTrainingdate) {
      filterplayers();
    }
  }, [currentTrainingdate]);
  const filterplayers = () => {
    if (players) {
      players.map((currplayer) => {
        if (currplayer?.name == currentTrainingdate?.TeamName) {
          setselectedTeamplayers(currplayer);
          console.log("crrplayer", currplayer);
        }
      });
    }
  };

  const [selectedPlayer, setselectedPlayer] = useState();
  const [attendance, setAttendance] = useState(null);
  const [Punctuality, setPunctuality] = useState(null);
  const [ExtraTime, setExtraTime] = useState(null);
  const [gamefilm, setGamefilm] = useState(null);
  const [Effort, setEffort] = useState(1);
  const [Coachable, setCoachable] = useState(1);
  const [BodyLanguage, setBodyLanguage] = useState(1);
  const [MentalToughness, setMentalToughness] = useState(1);
  const [PhysicalToughness, setPhysicalToughness] = useState(1);
  const [Feedback, setFeedback] = useState("");
  const [datedelete, setdatedelete] = useState(false);
  //hover
  // const [activePlayer, setActivePlayer] = useState("");
  useEffect(() => {
    setselectedPlayer();
  }, [opened]);

  const addPlayerTraining = async () => {
    try {
      const res = await axios.post(`${API_URL}Training/addPlayerTraining`, {
        playerID: selectedPlayer?._id,
        TrainingDayID: currentTrainingdate?._id,
        TrainingObj: {
          Attendance: attendance,
          Punctual: Punctuality,
          ExtraTime: ExtraTime,
          Film: gamefilm,
          Effort: Effort,
          Coachable: Coachable,
          BodyLanguage: BodyLanguage,
          MentalToughness: MentalToughness,
          PhysicalToughness: PhysicalToughness,
          Feedback: Feedback,
        },
      });
      console.log("res", res.data);
    } catch (error) {
      console.log(error);
    } finally {
      getTrainings();
      setAttendance(null);
      setPunctuality(null);
      setExtraTime(null);
      setGamefilm(null);
      setEffort(1);
      setCoachable(1);
      setBodyLanguage(1);
      setMentalToughness(1);
      setPhysicalToughness(1);
      setFeedback();
    }
  };

  const goback = () => {
    setdate(false);
    setcurrentTrainingdate();
  };
  return (
    <div className="Training_cont pt-3">
      {!date && (
        <>
          <div className="training_top d-flex align-items-center justify-content-between">
            <h3>Training Dates</h3>
            <div>
              <Button
                variant="outline"
                color="green"
                onClick={adddatehelper}
                className="me-3"
              >
                Add Date
              </Button>
              <Button
                variant={datedelete ? "filled" : "outline"}
                color="red"
                onClick={() => setdatedelete(!datedelete)}
              >
                Delete Date
              </Button>
            </div>
          </div>
          {!loading && trainigs?.SeasonTrainingDays?.length > 0 && (
            <div className="training_main mt-5">
              <div className="date_cont mt-3 px-3 mb-3 d-flex flex-wrap">
                {trainigs?.SeasonTrainingDays?.map((currdate, index) => {
                  const date = new Date(currdate.TrainingDate);
                  const formattedDate = date.toISOString().split("T")[0];
                  const monthYear =
                    date.toLocaleString("default", { month: "long" }) +
                    ", " +
                    date.getFullYear().toString().substr(-2);
                  const dayOfWeek = date.toLocaleString("default", {
                    weekday: "long",
                  });
                  const day = date.getDate();
                  return (
                    <div
                      className={`${
                        datedelete ? "delete_box" : "date_box "
                      } d-flex flex-column align-items-center justify-content-center m-2`}
                      onClick={() => Trainingdatehelper(currdate)}
                    >
                      <div className="design_box"></div>
                      <div className="date_inner d-flex  align-items-start justify-content-start px-4">
                        <div className="d-flex flex-column align-items-start justify-content-center">
                          <p>{monthYear}</p>
                          <h1>{day}</h1>
                          <p>{dayOfWeek}</p>
                        </div>

                        <div className="card"></div>
                        <div className="card"></div>
                        <div className="card d-flex align-items-center justify-content-center">
                          <h4>{currdate?.PlayerTrainings?.length}</h4>
                        </div>
                      </div>
                      <div className="design_box"></div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          {!loading && trainigs?.SeasonTrainingDays?.length === 0 && (
            <>
              <div className="empty d-flex flex-column align-items-center justify-content-start">
                <img src="images/no_player.png" alt="" className="grayscale" />
                <h2>Maximize Your Training Efforts!</h2>
                <h4>
                  Training is essential for player development and team success.
                  Let's create <br /> your first training session to optimize
                  your team's performance.
                </h4>
                <Button
                  variant="outline"
                  color="green"
                  onClick={adddatehelper}
                  className="me-3 mt-4"
                >
                  Add Date
                </Button>
              </div>
            </>
          )}
          {loading && <TeamSkelletonLoader />}
        </>
      )}
      {date && (
        <>
          <div className="training_top d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <FontAwesomeIcon
                icon={faArrowLeft}
                size="lg"
                className="me-3 back_icon"
                onClick={goback}
              />
              <h3>
                {new Date(currentTrainingdate?.TrainingDate).toLocaleString(
                  "default",
                  { month: "long", day: "numeric", year: "numeric" }
                )}
              </h3>
            </div>
            <div>
              <Button
                variant="outline"
                color="green"
                onClick={playertraininghelper}
                className="me-3"
              >
                Add Data for this date
              </Button>
              <Button
                variant={playerdeletemode ? "filled" : "outline"}
                color="red"
                onClick={() => setplayerdeletemode(!playerdeletemode)}
              >
                Delete Data for this date
              </Button>
            </div>
          </div>
          <div className="training_main mt-5">
            {currentTrainingdate?.PlayerTrainings?.length > 0 &&
              currentTrainingdate?.PlayerTrainings.map((currplayer, index) => (
                <>
                  <div
                    className={`user_training d-flex align-items-center justify-content-center mb-3 ${
                      playerdeletemode ? "player_delete" : ""
                    }`}
                    onClick={() => deletePlayerTraining(currplayer)}
                  >
                    <div className="d-flex flex-column align-items-center justify-content-center player_image">
                      <img src={currplayer?.PlayerID?.Image} alt="" />
                      <p>
                        {currplayer?.PlayerID?.firstName +
                          " " +
                          currplayer?.PlayerID?.lastName}
                      </p>
                    </div>
                    <div className="d-flex flex-column align-items-center justify-content-center attendance px-3">
                      <div className="d-flex align-items-center justify-content-between pb-2 top_attendance ">
                        <div className="d-flex flex-column align-items-start justify-content center me-4">
                          <p className="complimnetary"> Attendance</p>
                          <p className="value">
                            {" "}
                            {currplayer?.TrainingObj?.Attendance
                              ? "Present"
                              : "Absent"}
                          </p>
                        </div>
                        <div className="d-flex flex-column align-items-start justify-content center ">
                          <p className="complimnetary"> Punctuality</p>
                          <p className="value">
                            {" "}
                            {currplayer?.TrainingObj?.Punctual
                              ? "Punctual"
                              : "Not Punctual"}
                          </p>
                        </div>
                      </div>
                      <div className="d-flex align-items-center justify-content-between pt-2 ">
                        <div className="d-flex flex-column align-items-start justify-content center pe-3 me-5">
                          <p className="complimnetary"> Extra Time</p>
                          <p className="value">
                            {" "}
                            {currplayer?.TrainingObj?.ExtraTime ? "Yes" : "No"}
                          </p>
                        </div>
                        <div className="d-flex flex-column align-items-start justify-content center">
                          <p className="complimnetary"> Film</p>
                          <p className="value">
                            {" "}
                            {currplayer?.TrainingObj?.Film ? "Yes" : "No"}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex flex-column align-items-center justify-content-center metrics px-3">
                      <div className="d-flex flex-column   justify-content-center metric mb-2">
                        <p className="complimentary">Effort</p>
                        <div className="d-flex  justify-content-between metric_value">
                          <p className=" align-self-start"> 1-5</p>
                          <p className=" align-self-end highlight">
                            {(currplayer?.TrainingObj?.Effort / 5) * 100 + "%"}
                          </p>
                        </div>
                        <Progress
                          color="green"
                          value={(currplayer?.TrainingObj?.Effort / 5) * 100}
                        />
                      </div>
                      <div className="d-flex flex-column   justify-content-center metric mb-2">
                        <p className="complimentary">Coachable</p>
                        <div className="d-flex  justify-content-between metric_value">
                          <p className=" align-self-start"> 1-5</p>
                          <p className=" align-self-end highlight">
                            {(currplayer?.TrainingObj?.Coachable / 5) * 100 +
                              "%"}
                          </p>
                        </div>
                        <Progress
                          color="green"
                          value={(currplayer?.TrainingObj?.Coachable / 5) * 100}
                        />
                      </div>
                    </div>
                    <div className="d-flex flex-column align-items-center justify-content-center metrics px-3">
                      <div className="d-flex flex-column   justify-content-center metric mb-2">
                        <p className="complimentary">Body Language</p>
                        <div className="d-flex  justify-content-between metric_value">
                          <p className=" align-self-start"> 1-5</p>
                          <p className=" align-self-end highlight">
                            {" "}
                            {(currplayer?.TrainingObj?.BodyLanguage / 5) * 100 +
                              "%"}
                          </p>
                        </div>
                        <Progress
                          color="green"
                          value={
                            (currplayer?.TrainingObj?.BodyLanguage / 5) * 100
                          }
                        />
                      </div>
                      <div className="d-flex flex-column   justify-content-center metric mb-2">
                        <p className="complimentary">Mental Toughness</p>
                        <div className="d-flex  justify-content-between metric_value">
                          <p className=" align-self-start"> 1-5</p>
                          <p className=" align-self-end highlight">
                            {(currplayer?.TrainingObj?.MentalToughness / 5) *
                              100 +
                              "%"}
                          </p>
                        </div>
                        <Progress
                          color="green"
                          value={
                            (currplayer?.TrainingObj?.MentalToughness / 5) * 100
                          }
                        />
                      </div>
                    </div>
                    <div
                      id="last_metrics"
                      className="d-flex flex-column align-items-center justify-content-center metrics  px-3"
                    >
                      <div className="d-flex flex-column   justify-content-center metric mb-2">
                        <p className="complimentary">Physical Toughness</p>
                        <div className="d-flex  justify-content-between metric_value">
                          <p className=" align-self-start"> 1-5</p>
                          <p className=" align-self-end highlight">
                            {" "}
                            {(currplayer?.TrainingObj?.PhysicalToughness / 5) *
                              100 +
                              "%"}
                          </p>
                        </div>
                        <Progress
                          color="green"
                          value={
                            (currplayer?.TrainingObj?.PhysicalToughness / 5) *
                            100
                          }
                        />
                      </div>
                      {/* <div className="d-flex flex-column   justify-content-center metric mb-2">
                      <p className="complimentary">Body Language</p>
                      <div className="d-flex  justify-content-between metric_value">
                        <p className=" align-self-start"> 1-4</p>
                        <p className=" align-self-end highlight">89%</p>
                      </div>
                      <Progress color="green" value={50} />
                    </div> */}
                    </div>
                  </div>
                </>
              ))}
            {currentTrainingdate?.PlayerTrainings?.length == 0 && (
              <>
                <div className="empty_2 d-flex flex-column align-items-center justify-content-start">
                  <img
                    src="images/no_player.png"
                    alt=""
                    className="grayscale"
                  />
                  <h2>Maximize Your Training Efforts!</h2>
                  <h4>
                    Training is essential for player development and team
                    success. Let's create <br /> your first training session to
                    optimize your team's performance.
                  </h4>
                </div>
              </>
            )}
          </div>
        </>
      )}

      <Modal
        opened={opened}
        onClose={close}
        centered
        size="xl"
        className="date_modal"
        overlayProps={{
          backgroundOpacity: 0.55,
          blur: 3,
        }}
        id="training_modal"
      >
        <div className="main_modal_cont d-flex flex-column  justify-content-between">
          {!date && position == 0 && (
            <div className="modal_cont d-flex align-items-center justify-content-center">
              <div className="dt_cont">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <StaticDatePicker
                    defaultValue={dayjs()}
                    value={selectedDate}
                    onChange={(newValue) => setselectedDate(newValue)}
                    sx={{
                      ".MuiDateCalendar-root": {
                        color: "#ffffff",
                        padding: "5%",
                        borderRadius: "16px",
                        backgroundColor: "rgba(57, 60, 82, 0.2)",
                      },
                      ".MuiPickersToolbar-root": {
                        color: "#ffffff",

                        backgroundColor: "#101222",
                      },
                      ".MuiPickersCalendarHeader-root": {
                        backgroundColor: "rgba(57, 60, 82, 0.2)",
                        borderRadius: "16px",
                        paddingTop: "20px",
                        paddingBottom: "20px",
                      },
                      ". MuiPickersLayout-root": {
                        backgroundColor: "#101222",
                        color: "#ffffff",
                      },
                      ".MuiPickersLayout-contentWrapper": {
                        backgroundColor: "#101222",
                        color: "#ffffff",
                      },
                      ".MuiDialogActions-root": {
                        backgroundColor: "#101222",
                        color: "#ffffff",
                        display: "none",
                      },
                      ".MuiDayCalendar-weekDayLabel": {
                        color: "#ffffff",
                      },
                      ".MuiPickersDay-root": {
                        color: "#ffffff",
                        backgroundColor: "#101222",
                      },
                      ".MuiButtonBase-root": {
                        color: "#ffffff",
                      },
                      ".Mui-selected": {
                        backgroundColor: "#009B3A !important",
                      },
                      ".MuiPickersToolbar-content": {
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      },
                    }}
                  />
                </LocalizationProvider>
              </div>
            </div>
          )}
          {!date && position == 1 && (
            <>
              <div className="modal_cont d-flex align-items-center justify-content-center">
                <div className="dt_cont">
                  <Input.Wrapper
                    label="Description"
                    description=""
                    className="mb-4"
                    size="md"
                    value={description}
                    onChange={(e) => setDescription(e.target.value.trim())}
                  >
                    <Textarea
                      variant="filled"
                      size="lg"
                      radius="md"
                      name="email"
                    />
                  </Input.Wrapper>
                  <h5>Teams</h5>
                  <div className="d-flex align-items-start justify-content-start flex-wrap">
                    {teams.map((currteam, index) => (
                      <div
                        className=" m-1"
                        key={index}
                        onClick={() => setTeam(currteam)}
                      >
                        <div
                          className={`player ${
                            team == currteam && "active"
                          } px-2 d-flex align-items-center flex-column justify-content-center`}
                        >
                          <img src="images/team-logo.png" alt="" />
                          <p>{currteam}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </>
          )}

          {date && position == 0 && (
            <>
              <div className="modal_cont d-flex align-items-center justify-content-center">
                <div className="dt_cont">
                  <Input.Wrapper
                    label="Description"
                    description=""
                    className="mb-4"
                    size="md"
                    value={description}
                    onChange={(e) => setDescription(e.target.value.trim())}
                  >
                    <Input
                      variant="filled"
                      size="lg"
                      radius="md"
                      name="email"
                    />
                  </Input.Wrapper>
                  <h5>Players</h5>
                  <div className="d-flex align-items-start justify-content-start flex-wrap">
                    {selectedTeamplayers?.players?.map((player, index) => (
                      <div
                        className="m-2"
                        key={index}
                        onClick={() => setselectedPlayer(player)}
                      >
                        <div
                          className={`player_card  px-2 d-flex  align-items-center justify-content-start   ${
                            selectedPlayer == player && "activePlayer"
                          }`}
                        >
                          <img
                            src="images/player-1.png"
                            alt=""
                            className="me-2"
                          />
                          <p>{player?.firstName}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </>
          )}
          {date && position == 1 && (
            <>
              <div className="modal_cont d-flex align-items-center justify-content-center">
                <div className="player_values_cont">
                  <div className="row">
                    <div className="col-12 d-flex align-items-center justify-content-evenly">
                      <div className=" mb-3 d-flex flex-column align-items-center">
                        <h5>Attendeance</h5>
                        <div className="inner_box d-flex align-items-center justify-content-center px-3 py-2 ">
                          <Button
                            variant={attendance ? "filled" : "outline"}
                            color={attendance ? "green" : "gray"}
                            size="xs"
                            className="me-2"
                            onClick={() => setAttendance(true)}
                          >
                            Present
                          </Button>
                          <Button
                            variant={attendance == false ? "filled" : "outline"}
                            color={attendance == false ? "red" : "gray"}
                            size="xs"
                            className="ms-2"
                            onClick={() => setAttendance(false)}
                          >
                            Absent
                          </Button>
                        </div>
                      </div>
                      <div className=" mb-3 d-flex flex-column align-items-center">
                        <h5>Punctuality</h5>
                        <div className="inner_box d-flex align-items-center justify-content-center px-3 py-2 ">
                          <Button
                            variant={Punctuality ? "filled" : "outline"}
                            color={Punctuality ? "green" : "gray"}
                            size="xs"
                            className="me-2"
                            onClick={() => setPunctuality(true)}
                          >
                            Early
                          </Button>
                          <Button
                            variant={
                              Punctuality == false ? "filled" : "outline"
                            }
                            color={Punctuality == false ? "red" : "gray"}
                            size="xs"
                            className="ms-2"
                            onClick={() => setPunctuality(false)}
                          >
                            Late
                          </Button>
                        </div>
                      </div>
                      <div className=" mb-3 d-flex flex-column align-items-center">
                        <h5>Extra Time</h5>
                        <div className="inner_box d-flex align-items-center justify-content-center px-3 py-2 ">
                          <Button
                            variant={ExtraTime ? "filled" : "outline"}
                            color={ExtraTime ? "green" : "gray"}
                            size="xs"
                            className="me-2"
                            onClick={() => setExtraTime(true)}
                          >
                            Yes
                          </Button>
                          <Button
                            variant={ExtraTime == false ? "filled" : "outline"}
                            color={ExtraTime == false ? "red" : "gray"}
                            size="xs"
                            className="ms-2"
                            onClick={() => setExtraTime(false)}
                          >
                            No
                          </Button>
                        </div>
                      </div>
                      <div className="mb-3 d-flex flex-column align-items-center">
                        <h5>Game Film</h5>
                        <div className="inner_box d-flex align-items-center justify-content-center px-3 py-2 ">
                          <Button
                            variant={gamefilm ? "filled" : "outline"}
                            color={gamefilm ? "green" : "gray"}
                            size="xs"
                            className="me-2"
                            onClick={() => setGamefilm(true)}
                          >
                            Yes
                          </Button>
                          <Button
                            variant={gamefilm == false ? "filled" : "outline"}
                            color={gamefilm == false ? "red" : "gray"}
                            size="xs"
                            className="ms-2"
                            onClick={() => setGamefilm(false)}
                          >
                            No
                          </Button>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 d-flex align-items-center justify-content-evenly mb-3">
                      <div className=" d-flex flex-column align-items-center">
                        <NumberInput
                          label="Effort"
                          placeholder="Effort"
                          size="md"
                          min={1}
                          max={5}
                          value={Effort}
                          onChange={setEffort}
                        />
                      </div>
                      <div className=" d-flex flex-column align-items-center">
                        <NumberInput
                          label="Coachable"
                          placeholder="Coachable"
                          size="md"
                          min={1}
                          max={5}
                          value={Coachable}
                          onChange={setCoachable}
                        />
                      </div>
                    </div>
                    <div className="col-12 d-flex align-items-center justify-content-evenly mb-3">
                      <div className=" d-flex flex-column align-items-center">
                        <NumberInput
                          label="Body Language"
                          placeholder="Body Language"
                          size="md"
                          min={1}
                          max={5}
                          value={BodyLanguage}
                          onChange={setBodyLanguage}
                        />
                      </div>
                      <div className=" d-flex flex-column align-items-center">
                        <NumberInput
                          label="Mental Toughness"
                          placeholder="Mental Toughness"
                          size="md"
                          min={1}
                          max={5}
                          value={MentalToughness}
                          onChange={setMentalToughness}
                        />
                      </div>
                    </div>
                    <div className="col-12 d-flex align-items-center justify-content-evenly mb-">
                      <div className=" d-flex flex-column align-items-center">
                        <NumberInput
                          label="Physical Toughness"
                          placeholder="Physical Toughness"
                          size="md"
                          min={1}
                          max={5}
                          value={PhysicalToughness}
                          onChange={setPhysicalToughness}
                        />
                      </div>
                      <div className="feedback_cont">
                        <Input.Wrapper
                          label="Feedback"
                          value={Feedback}
                          onChange={(e) => setFeedback(e.target.value.trim())}
                        >
                          <Textarea placeholder="Feedback" />
                        </Input.Wrapper>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          <div className="d-flex align-items-center  justify-content-end mt-3">
            <Button
              variant="outline"
              color="grey"
              className="me-2"
              onClick={prevposition}
            >
              Back
            </Button>
            <Button variant="outline" color="green" onClick={nextposition}>
              Next
            </Button>
          </div>
        </div>
      </Modal>

      <Modal
        opened={confirmationModal}
        onClose={() => setconfirmationModal(false)}
        withCloseButton={false}
        centered={true}
        size="lg"
        radius={10}
        overlayProps={{
          backgroundOpacity: 0.55,
          blur: 3,
        }}
        id="date_delete_modal"
      >
        <div className="d-flex flex-column align-items-center justify-content-center my-5">
          <h5 className="mb-3">Are you sure you want to delete this player?</h5>

          <div className="d-flex align-items-center justify-content-center">
            <Button
              variant="filled"
              className="me-2"
              color="rgba(5, 128, 23, 1)"
              onClick={() => setconfirmationModal(false)}
            >
              Decline
            </Button>
            <Button
              variant="filled"
              color="rgba(163, 2, 2, 1)"
              onClick={() => setdeletedateConfirmation(true)}
            >
              Accept
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Training;
