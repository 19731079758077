import "../styles/blogStyles.css";

import { Link } from "react-router-dom";
// import { format } from "date-fns"; // Importing directly to format dates
import BlockContent from "@sanity/block-content-to-react";
import { Button } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import sanityClient from "../client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { Menu, MenuItem } from "@mui/material";

const BlogV2Dark = () => {
  const [allPostsData, setAllPosts] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(""); // State for selected category
  const [categories, setCategories] = useState([]);
  const [suggestedPosts, setSuggestedPosts] = useState([]); // State for suggested posts
  const [featuredPosts, setFeaturedPosts] = useState([]); // State for suggested posts

  useEffect(() => {
    sanityClient
      .fetch(`*[_type == "category"]{title}`)
      .then((data) => setCategories(data))
      .catch(console.error);
  }, []);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "post" && $selectedCategory in categories[]->title]{
          title,
          slug,
          author->{name, image, bio},
          publishedAt,
          mainImage{
            asset->{
              _id,
              url
            }
          },
          categories[]->{
            title
          }
        }`,
        { selectedCategory }
      )
      .then((data) => setAllPosts(data))
      .catch(console.error);
  }, [selectedCategory]);

  useEffect(() => {
    console.log(allPostsData);
  }, [allPostsData]);

  //------------------------getting posts respect to published time-----------------//
  const [topPosts, setTopPosts] = useState([]);

  useEffect(() => {
    const fetchTopPost = async () => {
      try {
        const data = await sanityClient.fetch(
          `*[_type == "post" && websites[references(*[_type == "website" && name match "Athlantix*"]._id)]] | order(publishedAt desc){
            title,
            slug,
            author->{name, image, bio},
            publishedAt,
            body,
            websites[]->{name},
            mainImage{
              asset->{
                _id,
                url
              }
            }
          }`
        );
        setTopPosts(data);
        console.log("top", data); // Logging data here
      } catch (error) {
        console.error("Error fetching top posts:", error);
      }
    };

    fetchTopPost();
  }, []); // Empty dependency array to run only once

  //-----------------------top one from all categoris------------------//
  const [entertainmentPost, setEntertainmentPost] = useState(null);
  const [businessPost, setBusinessPost] = useState(null);
  const [gamingPost, setGamingPost] = useState(null);
  const [technologyPost, setTechnologyPost] = useState(null);

  useEffect(() => {
    const fetchEntertainmentPost = async () => {
      try {
        const data = await sanityClient.fetch(
          `*[_type == "post" && "Entertainment" in categories[]->title && websites[references(*[_type == "website" && name match "Athlantix*"]._id)]] | order(publishedAt desc){
            title,
            slug,
            author->{name, image, bio},
            publishedAt,
            websites[]->{name},
            mainImage{
              asset->{
                _id,
                url
              }
            }
          }`
        );
        setEntertainmentPost(data);
        console.log("entertainment", data);
      } catch (error) {
        console.error(error);
      }
    };

    const fetchBusinessPost = async () => {
      try {
        const data = await sanityClient.fetch(
          `*[_type == "post" && "Business" in categories[]->title && 
            websites[references(*[_type == "website" && name match "Athlantix*"]._id)]
          ] | order(publishedAt desc){
            title,
            slug,
            author->{name, image, bio},
            publishedAt,
            websites[]->{name},
            mainImage{
              asset->{
                _id,
                url
              }
            }
          }`
        );
        setBusinessPost(data);
        console.log("business", data);
      } catch (error) {
        console.error(error);
      }
    };

    const fetchGamingPost = async () => {
      try {
        const data = await sanityClient.fetch(
          `*[_type == "post" && "Gaming" in categories[]->title && 
            websites[references(*[_type == "website" && name match "Athlantix*"]._id)]
          ] | order(publishedAt desc){
            title,
            slug,
            author->{name, image, bio},
            publishedAt,
            websites[]->{name},
            mainImage{
              asset->{
                _id,
                url
              }
            }
          }`
        );

        setGamingPost(data);
        console.log("gaming", data);
      } catch (error) {
        console.error(error);
      }
    };
    const fetchTechnologyPost = async () => {
      try {
        const data = await sanityClient.fetch(
          `*[_type == "post" && "Technology" in categories[]->title && 
            websites[references(*[_type == "website" && name match "Athlantix*"]._id)]
          ] | order(publishedAt desc){
            title,
            slug,
            author->{name, image, bio},
            publishedAt,
            websites[]->{name},
            mainImage{
              asset->{
                _id,
                url
              }
            }
          }`
        );
        setTechnologyPost(data);
        console("technology", data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchTechnologyPost();
    fetchEntertainmentPost();
    fetchBusinessPost();
    fetchGamingPost();
  }, []);

  //-----------------------fetching suggested posts-------------------//
  // Fetching suggested posts
  // Function to fetch suggested posts
  const fetchSuggestedPosts = async () => {
    try {
      const data = await sanityClient.fetch(
        `*[_type == "post" && isSuggested == true && 
          websites[references(*[_type == "website" && name match "Athlantix*"]._id)]
        ] {
          title,
          slug,
          author->{name, image, bio},
          publishedAt,
          websites[]->{name},
          mainImage{
            asset->{
              _id,
              url
            }
          },
          categories[]->{
            title
          }
        }`
      );
      console.log("Suggested Posts:", data); // Logging the fetched data for debugging
      setSuggestedPosts(data);
    } catch (error) {
      console.error(error);
    }
  };

  // Fetching suggested posts on component mount
  useEffect(() => {
    fetchSuggestedPosts();
  }, []);

  // Splitting suggested posts into two halves
  const halfIndex = suggestedPosts?.length
    ? Math.ceil(suggestedPosts.length / 2)
    : 0;
  const firstHalfSuggested = suggestedPosts?.slice(0, halfIndex) || [];
  const secondHalfSuggested = suggestedPosts?.slice(halfIndex) || [];

  //-------------------------------------------//

  //-----------------------fetching suggested posts-------------------//
  // Fetching suggested posts
  // Function to fetch suggested posts
  const fetchFeaturedPosts = async () => {
    try {
      const data = await sanityClient.fetch(
        `*[_type == "post" && isFeatured == true && 
          websites[references(*[_type == "website" && name match "Athlantix*"]._id)]
        ] {
          title,
          slug,
          author->{name, image, bio},
          publishedAt,
          websites[]->{name},
          mainImage{
            asset->{
              _id,
              url
            }
          },
          categories[]->{
            title
          }
        }`
      );
      console.log("featured Posts:", data); // Logging the fetched data for debugging
      setFeaturedPosts(data);
    } catch (error) {
      console.error(error);
    }
  };

  // Fetching suggested posts on component mount
  useEffect(() => {
    fetchFeaturedPosts();
  }, []);

  //------------------------------------------------------//

  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (feature) => {
    // Handle the selection here, e.g., navigate to the selected feature
    console.log(`Selected feature: ${feature}`);
    handleClose();
  };

  const navigate = useNavigate();
  const navtohome = () => {
    navigate("/");
  };
  const navtodash = () => {
    navigate("/chooseuser");
  };

  const navtopracticefeature = () => {
    navigate("/practice-features");
  };
  const navtotrainingfeature = () => {
    navigate("/training-features");
  };
  const navtovideofeature = () => {
    navigate("/video-features");
  };
  const navtopricing = () => {
    navigate("/pricing");
  };
  const navtocontactus = () => {
    navigate("/contactus");
  };
  const navtoBlog = () => {
    navigate("/blog");
  };

  return (
    <>
      <div className="bg-[#020f21] p-4 md:p-0 font-kanit">
        <div className="landing_top d-flex align-items-center justify-content-sm-between justify-content-center">
          <img src="images/Vector.png" alt="" id="top_logo" />
          <div className="navbar">
            <Button
              variant="transparent"
              size="lg"
              color="gray"
              onClick={navtohome}
              id="aboutUs_btn"
            >
              Home
            </Button>
            {/* <Button
              variant="transparent"
              size="lg"
              color="gray"
              onClick={navtofeatures}
              id="features_btn"
            >
              Features
            </Button>{" "} */}
            <Button
              variant="transparent"
              size="lg"
              color="gray"
              //   aria-controls="features-menu"
              //   aria-haspopup="true"
              onClick={handleClick}
              id="features_btn"
              rightSection={<FontAwesomeIcon icon={faAngleDown} />}
            >
              Features
            </Button>
            <Menu
              id="features-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              PaperProps={{
                style: {
                  background: "transparent",
                  boxShadow: "none",
                },
              }}
            >
              <MenuItem onClick={() => handleMenuItemClick("Feature 1")}>
                <Button
                  variant="transparent"
                  size="md"
                  color="gray"
                  onClick={navtopracticefeature}
                  id="pricing_btn"
                >
                  Practice
                </Button>{" "}
              </MenuItem>
              <MenuItem onClick={() => handleMenuItemClick("Feature 2")}>
                <Button
                  variant="transparent"
                  size="md"
                  color="gray"
                  onClick={navtotrainingfeature}
                  id="pricing_btn"
                >
                  Training
                </Button>
              </MenuItem>
              <MenuItem onClick={() => handleMenuItemClick("Feature 3")}>
                <Button
                  variant="transparent"
                  size="md"
                  color="gray"
                  onClick={navtovideofeature}
                  id="pricing_btn"
                >
                  Video
                </Button>{" "}
              </MenuItem>
            </Menu>
            <Button
              variant="transparent"
              size="lg"
              color="gray"
              onClick={navtopricing}
              id="pricing_btn"
            >
              Pricing
            </Button>{" "}
            <Button
              variant="transparent"
              size="lg"
              color="gray"
              onClick={navtocontactus}
              id="contactUs_btn"
            >
              Contact us
            </Button>
            <Button
              variant="transparent"
              size="lg"
              color="gray"
              onClick={navtoBlog}
              id="contactUs_btn"
            >
              Blog
            </Button>
          </div>

          <Button
            variant="filled"
            size="lg"
            radius={"xl"}
            color="#0C0F21"
            onClick={navtodash}
            id="dashboard_btn"
          >
            Dashboard
          </Button>
        </div>
        {/* first section */}
        <div className="py-10 section1-background">
          <div className="flex justify-center py-14">
            <p className="text-3xl font-bold text-white md:text-5xl font-kanit">
              OUR BLOGS
            </p>
          </div>
          <div className="flex flex-col items-start justify-center mb-32 space-x-0 md:ml-48 md:flex-row md:space-x-2 lg:space-x-2 ">
            {featuredPosts?.length > 0 && (
              <>
                <div className="relative">
                  <Link
                    to={`/blog-details?slug=${featuredPosts[0]?.slug.current}`}
                    key={featuredPosts[0]?.slug?.current}
                  >
                    <div className="relative p-3 m-4 bg-gray-700 bg-opacity-50 cursor-pointer rounded-3xl">
                      <img
                        src={featuredPosts[0]?.mainImage?.asset.url}
                        className="h-[28rem] w-[30rem] rounded-2xl md:h-[37rem] md:w-[40rem]"
                      />
                      <img
                        src="images/gaming-circle.png"
                        className="absolute right-0 mt-2 transform top-8 translate-x-1/4"
                      />
                      <div
                        className="absolute bottom-0 left-0 right-0 h-32 rounded-3xl"
                        style={{
                          background:
                            "linear-gradient(to top, #010e1e 0%, transparent 100%)",
                        }}
                      ></div>
                    </div>
                  </Link>
                  <div className="absolute bottom-0 right-0 mb-4 mr-4">
                    <div className="flex items-center justify-between">
                      <p className="text-[#f29102] font-semibold">
                        {featuredPosts[0]?.author?.name}
                      </p>
                      <p className="font-semibold text-white">
                        {featuredPosts[0]?.publishedAt
                          ? new Date(
                              featuredPosts[0].publishedAt
                            ).toLocaleDateString("en-US", {
                              year: "numeric",
                              month: "long",
                              day: "numeric",
                            })
                          : "No date available"}
                      </p>
                    </div>
                    <p className="mt-2 text-xl font-semibold text-white md:text-3xl">
                      {featuredPosts[0]?.title}
                    </p>
                  </div>
                </div>
              </>
            )}

            {topPosts?.length > 0 && (
              <>
                <div className="flex flex-col max-w-md mx-auto md:max-w-xl">
                  <Link
                    to={`/blog-details?slug=${topPosts[0]?.slug?.current}`}
                    key={topPosts[0]?.slug?.current}
                  >
                    <div className="cursor-pointer">
                      <img
                        src={topPosts[0]?.mainImage?.asset.url}
                        className="w-[24rem] h-[20rem] md:h-[25rem] md:w-[36rem] p-3 m-4 bg-gray-700 bg-opacity-50 h-96 rounded-3xl"
                      />
                    </div>
                  </Link>
                  <div className="mx-5 mb-20">
                    <div className="flex items-center justify-between">
                      <p className="text-[#f29102] font-semibold">
                        {topPosts[0]?.author?.name}
                      </p>
                      <p className="text-[#0d6ff2] font-semibold">
                        {topPosts[0]?.publishedAt
                          ? new Date(
                              topPosts[0].publishedAt
                            ).toLocaleDateString("en-US", {
                              year: "numeric",
                              month: "long",
                              day: "numeric",
                            })
                          : "No date available"}
                      </p>
                    </div>
                    <div className="flex justify-between">
                      <p className="mt-2 text-lg font-bold text-white md:text-2xl">
                        {topPosts[0]?.title}
                      </p>
                    </div>
                  </div>

                  <div className="flex flex-row lg:flex-row lg:items-center border-t-2 border-[#27313f]">
                    <div className="relative w-full lg:w-1/3">
                      <Link
                        to={`/blog-details?slug=${topPosts[1]?.slug?.current}`}
                        key={topPosts[1]?.slug?.current}
                      >
                        <img
                          src={topPosts[1]?.mainImage?.asset.url}
                          className="w-[10rem]  h-[9rem] md:w-[10rem] md:h-[9rem] p-2 m-4 bg-gray-700 bg-opacity-50 cursor-pointer rounded-3xl"
                        />
                      </Link>
                    </div>
                    <div className="flex flex-col w-full space-y-4 lg:mt-0 lg:ml-10">
                      <div className="flex items-center justify-between">
                        <p className="text-[#c9f31d] font-semibold">
                          {topPosts[1]?.author?.name}
                        </p>
                        <p className="text-[#0d6ff2] font-semibold">
                          {topPosts[1]?.publishedAt
                            ? new Date(
                                topPosts[1].publishedAt
                              ).toLocaleDateString("en-US", {
                                year: "numeric",
                                month: "long",
                                day: "numeric",
                              })
                            : "No date available"}
                        </p>
                      </div>
                      <p className="mt-4 text-xl font-semibold text-white lg:text-2xl">
                        {topPosts[1]?.title}
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-row lg:flex-row lg:items-center border-t-2 border-[#27313f]">
                    <div className="relative w-full lg:w-1/3">
                      <Link
                        to={`/blog-details?slug=${topPosts[2]?.slug?.current}`}
                        key={topPosts[2]?.slug?.current}
                      >
                        <img
                          src={topPosts[2]?.mainImage?.asset.url}
                          className="w-[10rem]  h-[9rem] md:w-[10rem] md:h-[9rem] p-2 m-4 bg-gray-700 bg-opacity-50 cursor-pointer rounded-3xl"
                        />
                      </Link>
                    </div>
                    <div className="flex flex-col w-full space-y-4 lg:mt-0 lg:ml-10">
                      <div className="flex items-center justify-between">
                        <p className="text-[#c9f31d] font-semibold">
                          {topPosts[2]?.author?.name}
                        </p>
                        <p className="text-[#0d6ff2] font-semibold">
                          {topPosts[2]?.publishedAt
                            ? new Date(
                                topPosts[2].publishedAt
                              ).toLocaleDateString("en-US", {
                                year: "numeric",
                                month: "long",
                                day: "numeric",
                              })
                            : "No date available"}
                        </p>
                      </div>
                      <p className="mt-4 text-xl font-semibold text-white lg:text-2xl">
                        {topPosts[2]?.title}
                      </p>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>

        {/* second section */}
        <div class="py-10 section2-background">
          <div class="flex flex-col container mx-auto px-4">
            <img
              src="images/three-stars.png"
              alt="Three Stars"
              style={{ width: "200px", height: "auto" }}
              className="hidden p-2 md:block"
            />

            <div class="flex flex-col md:flex-row lg:flex-row items-start justify-center mb-20 gap-20">
              <div class="flex flex-col  lg:w-[42rem]">
                <div class="entry">
                  {entertainmentPost && entertainmentPost?.length > 0 && (
                    <div className="flex flex-row lg:flex-row lg:items-center border-t-2 border-[#27313f]">
                      <div className="relative w-full lg:w-1/3">
                        <div className="mt-3">
                          <p className="text-[#c9f31d] font-semibold w-32">
                            Entertainment
                          </p>
                        </div>
                        <Link
                          to={`/blog-details?slug=${entertainmentPost[0]?.slug?.current}`}
                          key={entertainmentPost[0]?.slug?.current}
                        >
                          <img
                            src={entertainmentPost[0]?.mainImage?.asset.url}
                            className="w-[10rem]  h-[9rem] md:w-[10rem] md:h-[9rem] p-2 m-4 bg-gray-700 bg-opacity-50 cursor-pointer rounded-3xl"
                          />
                        </Link>
                      </div>
                      <div className="flex flex-col w-full mt-5 space-y-4 lg:mt-0 lg:ml-10">
                        <div className="flex items-center justify-between">
                          <p className="text-[#c9f31d] font-semibold">
                            {entertainmentPost[0]?.author?.name}
                          </p>
                          <p className="text-[#0d6ff2] font-semibold">
                            {entertainmentPost[0]?.publishedAt
                              ? new Date(
                                  entertainmentPost[0].publishedAt
                                ).toLocaleDateString("en-US", {
                                  year: "numeric",
                                  month: "long",
                                  day: "numeric",
                                })
                              : "No date available"}
                          </p>
                        </div>
                        <p className="mt-4 text-xl font-semibold text-white lg:text-2xl">
                          {entertainmentPost[0]?.title}
                        </p>
                      </div>
                    </div>
                  )}
                  {businessPost && businessPost?.length > 0 && (
                    <div className="flex flex-row lg:flex-row lg:items-center border-t-2 border-[#27313f]">
                      <div className="relative w-full lg:w-1/3">
                        <div className="mt-3">
                          <p className="text-[#c9f31d] font-semibold w-32">
                            Business
                          </p>
                        </div>
                        <Link
                          to={`/blog-details?slug=${businessPost[0]?.slug.current}`}
                          key={businessPost[0]?.slug?.current}
                        >
                          <img
                            src={businessPost[0]?.mainImage?.asset.url}
                            className="w-[10rem]  h-[9rem] md:w-[10rem] md:h-[9rem] p-2 m-4 bg-gray-700 bg-opacity-50 cursor-pointer rounded-3xl"
                          />
                        </Link>
                      </div>
                      <div className="flex flex-col w-full mt-5 space-y-4 lg:mt-0 lg:ml-10">
                        <div className="flex items-center justify-between">
                          <p className="text-[#c9f31d] font-semibold">
                            {businessPost[0]?.author?.name}
                          </p>
                          <p className="text-[#0d6ff2] font-semibold">
                            {businessPost[0]?.publishedAt
                              ? new Date(
                                  businessPost[0].publishedAt
                                ).toLocaleDateString("en-US", {
                                  year: "numeric",
                                  month: "long",
                                  day: "numeric",
                                })
                              : "No date available"}
                          </p>
                        </div>
                        <p className="mt-4 text-xl font-semibold text-white lg:text-2xl">
                          {businessPost[0]?.title}
                        </p>
                      </div>
                    </div>
                  )}
                  {gamingPost && gamingPost?.length > 0 && (
                    <div className="flex flex-row lg:flex-row lg:items-center border-t-2 border-[#27313f]">
                      <div className="relative w-full lg:w-1/3">
                        <div className="mt-3">
                          <p className="text-[#c9f31d] font-semibold w-32">
                            Gaming
                          </p>
                        </div>
                        <Link
                          to={`/blog-details?slug=${gamingPost[0]?.slug.current}`}
                          key={gamingPost[0]?.slug?.current}
                        >
                          <img
                            src={gamingPost[0]?.mainImage?.asset.url}
                            className="w-[10rem]  h-[9rem] md:w-[10rem] md:h-[9rem] p-2 m-4 bg-gray-700 bg-opacity-50 cursor-pointer rounded-3xl"
                          />
                        </Link>
                      </div>
                      <div className="flex flex-col w-full mt-5 space-y-4 lg:mt-0 lg:ml-10">
                        <div className="flex items-center justify-between">
                          <p className="text-[#c9f31d] font-semibold">
                            {gamingPost[0]?.author?.name}
                          </p>
                          <p className="text-[#0d6ff2] font-semibold">
                            {gamingPost[0]?.publishedAt
                              ? new Date(
                                  gamingPost[0].publishedAt
                                ).toLocaleDateString("en-US", {
                                  year: "numeric",
                                  month: "long",
                                  day: "numeric",
                                })
                              : "No date available"}
                          </p>
                        </div>
                        <p className="mt-4 text-xl font-semibold text-white lg:text-2xl">
                          {gamingPost[0]?.title}
                        </p>
                      </div>
                    </div>
                  )}
                </div>
                {featuredPosts?.length > 0 && (
                  <>
                    <div className="relative">
                      <Link
                        to={`/blog-details?slug=${featuredPosts[1]?.slug.current}`}
                        key={featuredPosts[1]?.slug?.current}
                      >
                        <div className="relative p-3 m-4 bg-gray-700 bg-opacity-50 cursor-pointer rounded-3xl">
                          <img
                            src={featuredPosts[1]?.mainImage?.asset.url}
                            className="h-[28rem] w-[30rem] rounded-2xl md:h-[37rem] md:w-[40rem]"
                          />
                          <img
                            src="images/gaming-circle.png"
                            className="absolute right-0 mt-2 transform top-8 translate-x-1/4"
                          />
                          <div
                            className="absolute bottom-0 left-0 right-0 h-32 rounded-3xl"
                            style={{
                              background:
                                "linear-gradient(to top, #010e1e 0%, transparent 100%)",
                            }}
                          ></div>
                        </div>
                      </Link>
                      <div className="absolute bottom-0 right-0 mb-4 mr-4">
                        <div className="flex items-center justify-between">
                          <p className="text-[#f29102] font-semibold">
                            {featuredPosts[1]?.author.name}
                          </p>
                          <p className="font-semibold text-white">
                            {featuredPosts[1]?.publishedAt
                              ? new Date(
                                  featuredPosts[1].publishedAt
                                ).toLocaleDateString("en-US", {
                                  year: "numeric",
                                  month: "long",
                                  day: "numeric",
                                })
                              : "No date available"}
                          </p>
                        </div>
                        <p className="mt-2 text-xl font-semibold text-white md:text-3xl">
                          {featuredPosts[1]?.title}
                        </p>
                      </div>
                    </div>
                  </>
                )}
                {technologyPost && technologyPost?.length > 0 && (
                  <div className="flex flex-row lg:flex-row lg:items-center border-t-2 border-[#27313f] mt-20">
                    <div className="relative w-full lg:w-1/3">
                      <div className="mt-4">
                        <p className="text-[#c9f31d] font-semibold w-32">
                          Technology
                        </p>
                      </div>
                      <Link
                        to={`/blog-details?slug=${technologyPost[0]?.slug?.current}`}
                        key={technologyPost[0]?.slug?.current}
                      >
                        <img
                          src={technologyPost[0]?.mainImage?.asset.url}
                          className="w-[10rem]  h-[9rem] md:w-[10rem] md:h-[9rem] p-2 m-4 bg-gray-700 bg-opacity-50 cursor-pointer rounded-3xl"
                        />
                      </Link>
                    </div>
                    <div className="flex flex-col w-full mt-5 space-y-4 lg:mt-0 lg:ml-10">
                      <div className="flex items-center justify-between">
                        <p className="text-[#c9f31d] font-semibold">
                          {technologyPost[0]?.author.name}
                        </p>
                        <p className="text-[#0d6ff2] font-semibold">
                          {technologyPost[0]?.publishedAt
                            ? new Date(
                                technologyPost[0].publishedAt
                              ).toLocaleDateString("en-US", {
                                year: "numeric",
                                month: "long",
                                day: "numeric",
                              })
                            : "No date available"}
                        </p>
                      </div>
                      <p className="mt-4 text-xl font-semibold text-white lg:text-2xl">
                        {technologyPost[0]?.title}
                      </p>
                    </div>
                  </div>
                )}
              </div>

              <div class="suggested bg-[#c9f31d] flex flex-col rounded-3xl p-6 lg:w-[33rem] md:w-[28rem] w-[22rem]">
                <p class="text-lg font-bold mb-4">Suggested</p>
                <div className="flex flex-col space-y-3 md:space-y-6 max-h-[60rem] min-h-[40rem] overflow-y-auto no-scrollbar">
                  {firstHalfSuggested?.map((post) => (
                    <Link
                      to={`/blog-details?slug=${post?.slug.current}`}
                      key={post?.slug.current}
                      className="no-underline text-inherit"
                    >
                      <div
                        key={post?.slug.current}
                        className="flex flex-row border-t-2 border-[#afd21d] pt-3"
                      >
                        <div className="flex flex-col space-y-4">
                          <div className="flex flex-col space-y-4 md:flex-row md:items-center md:justify-between md:space-y-0 md:space-x-40">
                            <div>
                              <p className="text-xl font-bold">
                                {post?.title}
                                <span className="block">
                                  {post?.mainHeading}
                                </span>
                              </p>
                            </div>
                            <div>
                              <p className="bg-[#9fea07] p-2 text-lg leading-none font-semibold">
                                {post?.categories?.[0]?.title || "No category"}{" "}
                              </p>
                            </div>
                          </div>
                          <div className="flex flex-col space-y-2 md:flex-row md:items-center md:justify-between md:space-y-0">
                            <p className="font-semibold">
                              {post?.author?.name}
                            </p>
                            <p className="font-semibold">
                              {post?.publishedAt
                                ? new Date(post.publishedAt).toLocaleDateString(
                                    "en-US",
                                    {
                                      year: "numeric",
                                      month: "long",
                                      day: "numeric",
                                    }
                                  )
                                : "No date available"}
                            </p>
                          </div>
                        </div>
                      </div>
                    </Link>
                  ))}
                </div>
              </div>
            </div>

            <div class="flex flex-col items-center justify-center w-full"></div>
          </div>
          <div className="flex flex-col items-center justify-center ">
            {featuredPosts?.slice(2).map((post, index) => (
              <div key={index}>
                {index % 2 === 0 ? (
                  <div className="flex flex-col md:flex-row border-t-2 border-[#27313f] py-6">
                    <div className="flex justify-center md:justify-start">
                      <Link
                        to={`/blog-details?slug=${post?.slug?.current}`}
                        key={post?.slug?.current}
                        className="no-underline text-inherit"
                      >
                        <img
                          src={post?.mainImage?.asset.url}
                          className="p-2 m-4 bg-gray-700 bg-opacity-50 w-72 h-60 w-80 rounded-3xl md:w-96 md:h-80 "
                        />
                      </Link>
                    </div>
                    <div className="flex flex-col mt-5 space-y-6 md:space-y-10 md:ml-6">
                      <div className="flex flex-col space-y-2 md:flex-row md:items-center md:justify-between md:space-y-0">
                        <p className="text-[#c9f31d] font-semibold">
                          {post?.author?.name}
                        </p>
                        <p className="text-[#0d6ff2] font-semibold">
                          {post?.publishedAt
                            ? new Date(post.publishedAt).toLocaleDateString(
                                "en-US",
                                {
                                  year: "numeric",
                                  month: "long",
                                  day: "numeric",
                                }
                              )
                            : "No date available"}{" "}
                        </p>
                      </div>
                      <div className="flex flex-col">
                        <p className="text-xl md:text-5xl font-semibold text-white md:w-[55rem] w-[22rem]">
                          {post?.title}
                        </p>
                      </div>
                      <div>
                        <p className="text-[#f29102] font-semibold">
                          {post?.author?.name}
                        </p>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="flex flex-col md:flex-row border-t-2 border-[#27313f] py-6">
                    <div className="flex flex-col mt-5 space-y-6 md:space-y-10 md:mr-6">
                      <div className="flex flex-col space-y-2 md:flex-row md:items-center md:justify-between md:space-y-0">
                        <p className="text-[#c9f31d] font-semibold">
                          {post?.author?.name}
                        </p>
                        <p className="text-[rgb(13,111,242)] font-semibold">
                          {post?.publishedAt
                            ? new Date(post.publishedAt).toLocaleDateString(
                                "en-US",
                                {
                                  year: "numeric",
                                  month: "long",
                                  day: "numeric",
                                }
                              )
                            : "No date available"}{" "}
                        </p>
                      </div>
                      <div className="flex flex-col">
                        <p className="text-xl md:text-5xl font-semibold text-white md:w-[55rem] w-[22rem]">
                          {post?.title}
                        </p>
                      </div>
                      <div>
                        <p className="text-[#f29102] font-semibold">
                          {post?.author?.name}
                        </p>
                      </div>
                    </div>
                    <div className="flex justify-center md:justify-start">
                      <Link
                        to={`/blog-details?slug=${post?.slug?.current}`}
                        key={post?.slug?.current}
                      >
                        <img
                          src={post?.mainImage?.asset.url}
                          className="p-2 m-4 bg-gray-700 bg-opacity-50 w-72 h-60 w-80 rounded-3xl md:w-96 md:h-80 "
                        />
                      </Link>
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>

        {/* third section */}
        <div class="py-10 section2-background">
          <div class="flex flex-col container mx-auto ">
            <img
              src="images/three-stars.png"
              alt="Three Stars"
              style={{ width: "200px", height: "auto" }}
              className="hidden p-2 md:block"
            />

            <div class="flex flex-col md:flex-row lg:flex-row items-start justify-center mb-20 gap-20">
              <div class="flex flex-col  lg:w-[42rem]">
                <div class="entry">
                  {entertainmentPost && entertainmentPost?.length > 1 && (
                    <div className="flex flex-row lg:flex-row lg:items-center  border-t-2 border-[#27313f]">
                      <div className="relative w-full lg:w-1/3">
                        <div className="mt-3">
                          <p className="text-[#c9f31d] font-semibold w-32">
                            Entertainment
                          </p>
                        </div>
                        <Link
                          to={`/blog-details?slug=${entertainmentPost[1]?.slug?.current}`}
                          key={entertainmentPost[1]?.slug?.current}
                        >
                          <img
                            src={entertainmentPost[1]?.mainImage?.asset.url}
                            className="w-[10rem]  h-[9rem] md:w-[10rem] md:h-[9rem] p-2 m-4 bg-gray-700 bg-opacity-50 cursor-pointer rounded-3xl"
                          />
                        </Link>
                      </div>
                      <div className="flex flex-col w-full mt-5 space-y-4 lg:mt-0 lg:ml-10">
                        <div className="flex items-center justify-between">
                          <p className="text-[#c9f31d] font-semibold">
                            {entertainmentPost[1]?.author?.name}
                          </p>
                          <p className="text-[#0d6ff2] font-semibold">
                            {entertainmentPost[1]?.publishedAt
                              ? new Date(
                                  entertainmentPost[1]?.publishedAt
                                ).toLocaleDateString("en-US", {
                                  year: "numeric",
                                  month: "long",
                                  day: "numeric",
                                })
                              : "No date available"}
                          </p>
                        </div>
                        <p className="mt-4 text-xl font-semibold text-white lg:text-2xl">
                          {entertainmentPost[1]?.title}
                        </p>
                      </div>
                    </div>
                  )}
                  {businessPost && businessPost?.length > 1 && (
                    <div className="flex flex-row lg:flex-row lg:items-center border-t-2 border-[#27313f]">
                      <div className="relative w-full lg:w-1/3">
                        <div className="mt-3">
                          <p className="text-[#c9f31d] font-semibold w-32">
                            Business
                          </p>
                        </div>
                        <Link
                          to={`/blog-details?slug=${businessPost[1]?.slug.current}`}
                          key={businessPost[1]?.slug?.current}
                        >
                          <img
                            src={businessPost[1]?.mainImage?.asset.url}
                            className="w-[10rem]  h-[9rem] md:w-[10rem] md:h-[9rem] p-2 m-4 bg-gray-700 bg-opacity-50 cursor-pointer rounded-3xl"
                          />
                        </Link>
                      </div>
                      <div className="flex flex-col w-full mt-5 space-y-4 lg:mt-0 lg:ml-10">
                        <div className="flex items-center justify-between">
                          <p className="text-[#c9f31d] font-semibold">
                            {businessPost[1]?.author.name}
                          </p>
                          <p className="text-[#0d6ff2] font-semibold">
                            {businessPost[1].publishedAt
                              ? new Date(
                                  entertainmentPost[1].publishedAt
                                ).toLocaleDateString("en-US", {
                                  year: "numeric",
                                  month: "long",
                                  day: "numeric",
                                })
                              : "No date available"}
                          </p>
                        </div>
                        <p className="mt-4 text-xl font-semibold text-white lg:text-2xl">
                          {businessPost[1]?.title}
                        </p>
                      </div>
                    </div>
                  )}
                  {gamingPost && gamingPost?.length > 1 && (
                    <div className="flex flex-row lg:flex-row lg:items-center border-t-2 border-[#27313f]">
                      <div className="relative w-full lg:w-1/3">
                        <div className="mt-3">
                          <p className="text-[#c9f31d] font-semibold w-32">
                            Gaming
                          </p>
                        </div>
                        <Link
                          to={`/blog-details?slug=${gamingPost[1]?.slug?.current}`}
                          key={gamingPost[1]?.slug?.current}
                        >
                          <img
                            src={gamingPost[1]?.mainImage?.asset.url}
                            className="w-[10rem]  h-[9rem] md:w-[10rem] md:h-[9rem] p-2 m-4 bg-gray-700 bg-opacity-50 cursor-pointer rounded-3xl"
                          />
                        </Link>
                      </div>
                      <div className="flex flex-col w-full mt-5 space-y-4 lg:mt-0 lg:ml-10">
                        <div className="flex items-center justify-between">
                          <p className="text-[#c9f31d] font-semibold">
                            {gamingPost[1]?.author.name}
                          </p>
                          <p className="text-[rgb(13,111,242)] font-semibold">
                            {gamingPost[1]?.publishedAt
                              ? new Date(
                                  entertainmentPost[1].publishedAt
                                ).toLocaleDateString("en-US", {
                                  year: "numeric",
                                  month: "long",
                                  day: "numeric",
                                })
                              : "No date available"}
                          </p>
                        </div>
                        <p className="mt-4 text-xl font-semibold text-white lg:text-2xl">
                          {gamingPost[1]?.title}
                        </p>
                      </div>
                    </div>
                  )}
                  {technologyPost && technologyPost?.length > 1 && (
                    <div className="flex flex-row lg:flex-row lg:items-center border-t-2 border-[#27313f]">
                      <div className="relative w-full lg:w-1/3">
                        <div className="mt-3">
                          <p className="text-[#c9f31d] font-semibold w-32">
                            Technology
                          </p>
                        </div>
                        <Link
                          to={`/blog-details?slug=${technologyPost[1]?.slug?.current}`}
                          key={technologyPost[1]?.slug?.current}
                        >
                          <img
                            src={technologyPost[1]?.mainImage?.asset.url}
                            className="w-[10rem]  h-[9rem] md:w-[10rem] md:h-[9rem] p-2 m-4 bg-gray-700 bg-opacity-50 cursor-pointer rounded-3xl"
                          />
                        </Link>
                      </div>
                      <div className="flex flex-col w-full mt-5 space-y-4 lg:mt-0 lg:ml-10">
                        <div className="flex items-center justify-between">
                          <p className="text-[#c9f31d] font-semibold">
                            {technologyPost[1]?.author?.name}
                          </p>
                          <p className="text-[#0d6ff2] font-semibold">
                            {technologyPost[1]?.publishedAt
                              ? new Date(
                                  technologyPost[1].publishedAt
                                ).toLocaleDateString("en-US", {
                                  year: "numeric",
                                  month: "long",
                                  day: "numeric",
                                })
                              : "No date available"}
                          </p>
                        </div>
                        <p className="mt-4 text-xl font-semibold text-white lg:text-2xl">
                          {technologyPost[1]?.title}
                        </p>
                      </div>
                    </div>
                  )}
                </div>

                <div className="flex flex-col space-y-2  border-t-2 border-[#27313f] p-8 mt-32">
                  {topPosts.slice(3).map((post, index) => (
                    <div key={index}>
                      <div className="flex flex-col items-center justify-between md:flex-row">
                        <p className="text-[#f29102] font-semibold">
                          {post?.author?.name}
                        </p>
                        <p className="text-[#0d6ff2] font-semibold">
                          {post?.publishedAt
                            ? new Date(post.publishedAt).toLocaleDateString(
                                "en-US",
                                {
                                  year: "numeric",
                                  month: "long",
                                  day: "numeric",
                                }
                              )
                            : "No date available"}
                        </p>
                      </div>
                      <div className="flex flex-col justify-between md:flex-row">
                        <p className="flex-grow mt-2 text-2xl font-semibold text-white underline md:text-5xl">
                          {post?.title}
                        </p>
                      </div>
                      <div className="w-full md:w-[40rem] mt-10 text-gray-300">
                        <BlockContent
                          blocks={
                            post?.body && post?.body[0] ? [post?.body[0]] : []
                          } // Pass only the first block
                          projectId={sanityClient.clientConfig?.projectId}
                          dataset={sanityClient.clientConfig?.dataset}
                        />
                      </div>
                      <div className="relative mt-10 mb-56">
                        <div className="relative m-4 bg-opacity-50 rounded-3xl">
                          <Link
                            to={`/blog-details?slug=${post?.slug.current}`}
                            key={post?.slug?.current}
                          >
                            <img
                              src={post?.mainImage?.asset.url}
                              className="w-full md:w-[44rem] md:h-[35rem] h-[13rem] bg-gray-800 p-3 rounded-3xl"
                            />
                          </Link>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div class="suggested bg-[#4c99ff] flex flex-col rounded-3xl p-6 w-full lg:w-[33rem]">
                <p class="text-lg font-bold mb-4">Suggested</p>
                <div className="flex flex-col space-y-6 md:space-y-6 max-h-[60rem] min-h-[40rem] overflow-y-auto no-scrollbar">
                  {secondHalfSuggested?.map((post) => (
                    <Link
                      to={`/blog-details?slug=${post?.slug?.current}`}
                      key={post?.slug?.current}
                      className="no-underline text-inherit"
                    >
                      <div
                        key={post?.slug?.current}
                        className="flex flex-col md:flex-row border-t-2 border-[#4078c4] pt-3"
                      >
                        <div className="flex flex-col space-y-4">
                          <div className="flex flex-col space-y-2 md:flex-row md:items-center md:justify-between md:space-y-0 md:space-x-40">
                            <div>
                              <p className="text-xl font-bold md:text-2xl">
                                {post?.title}
                                <span className="block">
                                  {post?.mainHeading}
                                </span>
                              </p>
                            </div>
                            <div>
                              <p className="bg-[#3a81ff] p-2 text-lg leading-none font-semibold">
                                {post?.categories?.[0]?.title || "No category"}
                              </p>
                            </div>
                          </div>
                          <div className="flex flex-col space-y-2 md:flex-row md:items-center md:justify-between md:space-y-0">
                            <p className="font-semibold">
                              {post?.author?.name}
                            </p>
                            <p className="font-semibold">
                              {post.publishedAt
                                ? new Date(post.publishedAt).toLocaleDateString(
                                    "en-US",
                                    {
                                      year: "numeric",
                                      month: "long",
                                      day: "numeric",
                                    }
                                  )
                                : "No date available"}
                            </p>
                          </div>
                        </div>
                      </div>
                    </Link>
                  ))}
                </div>{" "}
              </div>
            </div>

            <div class="flex flex-col items-center justify-center w-full"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogV2Dark;
