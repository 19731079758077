import React, { useEffect, useState } from "react";
import "./PlayerGradeSearchStyles.scss";
import { Avatar, Modal, Button } from "@mantine/core";
import { Textarea } from "@mantine/core";
import { TagsInput } from "@mantine/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import { useDisclosure } from "@mantine/hooks";
import { toast } from "react-toastify";
import {
  faMagnifyingGlass,
  faBell,
  faCircleInfo,
  faCirclePlus,
  faBookmark,
  faArrowLeft,
  faPlus,
  faArrowUp,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";

const PlayerGradeSearch = () => {
  const [Class, setClass] = useState();
  const { user } = useSelector((state) => state.auth);
  const port = process.env.REACT_APP_IP_ADDRESS;
  const API_URL = `${port}/api/users/Institute`;
  const [Players, setPlayers] = useState([]);
  const [opened, { open, close }] = useDisclosure(false);
  const getPlayers = async () => {
    try {
      const res = await axios.post(`${API_URL}/getPlayersInTeamByYear`, {
        instituteId: user?._id,
        Year: Class,
      });
      setPlayers(res.data);
    } catch (error) {}
  };
  useEffect(() => {
    if (Class) {
      getPlayers();
    }
  }, [Class]);
  const getyear = (date) => {
    return new Date(date).getFullYear();
  };
  const [selectedplayer, setSelectedplayer] = useState();
  const promoteplayer = async (year) => {
    try {
      const res = await axios.post(`${port}/api/Requirements/PromotePlayer`, {
        playerId: selectedplayer,
        Year: year,
        instituteId: user?._id,
      });
      console.log(res.data);
      close();
      toast.success(
        <>
          <img src="images/new-logo.png" className="toast_logo" />
          <p className="mt-3">Player Promoted Successfully</p>
        </>,

        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        }
      );
      getPlayers();
    } catch (error) {
      console.log(error);
      toast.error(
        <>
          <img src="images/new-logo.png" className="toast_logo" />
          <p className="mt-3">Error in Promoting Player</p>
        </>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        }
      );
    }
  };
  const [deletedateConfirmation, setdeletedateConfirmation] = useState(false);
  const [confirmationModal, setconfirmationModal] = useState(false);
  const deleteplayer = async () => {
    try {
      const res = await axios.post(`${API_URL}/RemovePlayer`, {
        instituteId: user?._id,
        playerId: selectedplayer,
      });
      console.log(res.data);

      toast.success(
        <>
          <img src="images/new-logo.png" className="toast_logo" />
          <p className="mt-3">Player Deleted Successfully</p>
        </>,

        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        }
      );
      getPlayers();
    } catch (error) {
      console.log(error);
      toast.error(
        <>
          <img src="images/new-logo.png" className="toast_logo" />
          <p className="mt-3">Error in Deleting Player</p>
        </>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        }
      );
    } finally {
      setconfirmationModal(false);
    }
  };
  useEffect(() => {
    if (deletedateConfirmation) {
      deleteplayer();
      setdeletedateConfirmation(false);
    }
  }, [deletedateConfirmation]);
  const [title, settitle] = useState("");
  const [label, setlabel] = useState([]);
  const [description, setdescription] = useState("");
  const [category, setcategory] = useState("");
  const [AddRequirement, setAddRequirement] = useState(false);
  const [chooseRequirementmodal, setchooseRequirementmodal] = useState(false);
  const categoryMap = {
    Academic: "Edu",
    "Non-Academic": "CoEdu",
    Other: "Other",
  };
  const AddNewrequirement = async () => {
    try {
      const response = await axios.post(
        `${port}/api/Requirements/addNewRequirement`,
        {
          instituteId: user?._id,
          reqHeading: title,
          reqDescription: description,
          reqTags: label,
          reqType: categoryMap[category],
          Year: Class,
        }
      );
      toast.success(
        <>
          <img src="images/new-logo.png" className="toast_logo" />
          <p className="mt-3">Requirement Added Successfully</p>
        </>,

        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        }
      );
      goback();
    } catch (error) {
      console.log(error);

      toast.error(
        <>
          <img src="images/new-logo.png" className="toast_logo" />
          <p className="mt-3">Error in Adding Requirement</p>
        </>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        }
      );
    }
  };

  const goback = () => {
    if (AddRequirement) {
      setAddRequirement(false);
    } else if (Class) {
      setClass("");
    }
  };

  const [Errors, setErrors] = useState([false, false, false]);

  const CheckErrors = () => {
    let error = false;
    let newErrors = [false, false, false];
    if (title == "") {
      error = true;
      newErrors[0] = true;
    }
    if (label.length == 0) {
      error = true;
      newErrors[1] = true;
    }
    if (description == "") {
      error = true;
      newErrors[2] = true;
    }
    setErrors(newErrors);
    if (!error) {
      AddNewrequirement();
    }
  };

  return (
    <div className="dashboard_content px-4 py-3">
      <div className="main_top d-flex justify-content-end align-items-center">
        {/* <div className="input_cont">
          <Input
            size="md"
            radius="xl"
            placeholder="Search"
            leftSection={<FontAwesomeIcon icon={faMagnifyingGlass} />}
          />
        </div> */}
        <div className="main_top_right d-flex justify-content-between align-items-center ">
          <div className="notification_cont">
            <FontAwesomeIcon icon={faBell} size="xl" />
          </div>
          <Avatar color="cyan" radius="xl">
            {/* <img src="images/team-logo.png" alt="" /> */}
            <img className="user_image" src={user?.Image} alt="" />
          </Avatar>
        </div>
      </div>
      <div className="player_grade_search_cont">
        {!Class && (
          <>
            {" "}
            <h3 className="mb-3">Player Search</h3>
            <p>Select a grade to view players:</p>
          </>
        )}
        {Class && (
          <div className="d-flex align-items-center justify-content-between mb-3">
            <div className="d-flex align-items-center">
              <FontAwesomeIcon
                className="me-2"
                icon={faArrowLeft}
                size="lg"
                onClick={() => goback()}
              />
              {!AddRequirement && <h3> {Class}</h3>}
              {AddRequirement && (
                <h3>
                  {" "}
                  Add {category} Requirement in {Class}
                </h3>
              )}
            </div>
            <Button
              variant="filled"
              size="md"
              color="#181A2C"
              leftSection={<FontAwesomeIcon icon={faPlus} />}
              onClick={() => setchooseRequirementmodal(true)}
            >
              Add Requirement
            </Button>
          </div>
        )}
        {/* ["Freshman","Sophomore","Junior","Senior","FifthGrade","SixthGrade","SeventhGrade","EighthGrade"] */}

        {!Class && (
          <div className="classes d-flex align-items-center justify-content-between mt-5 flex-wrap">
            <div
              className="class d-flex align-items-center justify-content-center m-2"
              onClick={() => setClass("Freshman")}
            >
              <div className="eclipse"></div>
              <h3 className="grade">Freshman</h3>
            </div>
            <div
              className="class d-flex align-items-center justify-content-center m-2 "
              onClick={() => setClass("Sophomore")}
            >
              <div className="eclipse"></div>
              <h3 className="grade">Sophomore</h3>
            </div>
            <div
              className="class d-flex align-items-center justify-content-center m-2"
              onClick={() => setClass("Junior")}
            >
              <div className="eclipse"></div>
              <h3 className="grade">Junior</h3>
            </div>
            <div
              className="class d-flex align-items-center justify-content-center m-2"
              onClick={() => setClass("Senior")}
            >
              <div className="eclipse"></div>
              <h3 className="grade">Senior</h3>
            </div>
            <div
              className="class d-flex align-items-center justify-content-center m-2"
              onClick={() => setClass("FifthGrade")}
            >
              <div className="eclipse"></div>
              <h3 className="grade">5th</h3>
            </div>
            <div
              className="class d-flex align-items-center justify-content-center m-2"
              onClick={() => setClass("SixthGrade")}
            >
              <div className="eclipse"></div>
              <h3 className="grade">6th</h3>
            </div>
            <div
              className="class d-flex align-items-center justify-content-center m-2"
              onClick={() => setClass("SeventhGrade")}
            >
              <div className="eclipse"></div>
              <h3 className="grade">7th</h3>
            </div>

            <div
              className="class d-flex align-items-center justify-content-center m-2"
              onClick={() => setClass("EighthGrade")}
            >
              <div className="eclipse"></div>
              <h3 className="grade">8th</h3>
            </div>
          </div>
        )}
        {Class && !AddRequirement && (
          <>
            <div className="table_top fullwidth d-flex align-items-center justify-content-center">
              <div className="row ">
                <div className="col-3 d-flex align-items-center justify-content-center">
                  <p>Player Name</p>
                </div>
                <div className="col-2 d-flex align-items-center justify-content-center">
                  <p>Team</p>
                </div>
                <div className="col-2 d-flex align-items-center justify-content-center">
                  <p>Year Joined</p>
                </div>
                <div className="col-3 d-flex align-items-center justify-content-center">
                  <p>Position</p>
                </div>
                <div className="col-2"></div>
              </div>
            </div>
            {Players.map((player) => (
              <div className="table_row fullwidth d-flex align-items-center justify-content-center my-3">
                <div className="row ">
                  <div className="col-3 d-flex align-items-center justify-content-center">
                    <p>{player?.firstName + " " + player?.lastName}</p>
                  </div>
                  <div className="col-2 d-flex align-items-center justify-content-center">
                    <p>{player?.teamName}</p>
                  </div>
                  <div className="col-2 d-flex align-items-center justify-content-center">
                    <p>{getyear(player?.createdAt)}</p>
                  </div>
                  <div className="col-3 d-flex align-items-center justify-content-center">
                    <div className="highlight_box d-flex align-items-center justify-content-center p-2">
                      <p>Forward Right Winger</p>
                    </div>
                  </div>
                  <div className="col-2  d-flex align-items-center justify-content-evenly">
                    <FontAwesomeIcon
                      icon={faArrowUp}
                      onClick={() => {
                        open();
                        setSelectedplayer(player?._id);
                      }}
                      id="promote_icon"
                    />
                    <FontAwesomeIcon
                      icon={faTrashCan}
                      id="delete_icon"
                      onClick={() => {
                        setconfirmationModal(true);
                        setSelectedplayer(player?._id);
                      }}
                    />
                  </div>
                </div>
              </div>
            ))}

            {/* <div className="table_row fullwidth d-flex align-items-center justify-content-center my-3">
              <div className="row ">
                <div className="col-3 d-flex align-items-center justify-content-center">
                  <p>Alexander Alex</p>
                </div>
                <div className="col-2 d-flex align-items-center justify-content-center">
                  <p>Gladiator</p>
                </div>
                <div className="col-2 d-flex align-items-center justify-content-center">
                  <p>2023</p>
                </div>
                <div className="col-3 d-flex align-items-center justify-content-center">
                  <div className="highlight_box d-flex align-items-center justify-content-center p-2">
                    <p>Forward Right Winger</p>
                  </div>
                </div>
                <div className="col-2  d-flex align-items-center justify-content-evenly">
                  <FontAwesomeIcon icon={faArrowUp} />
                  <FontAwesomeIcon icon={faTrashCan} />
                </div>
              </div>
            </div>
            <div className="table_row fullwidth d-flex align-items-center justify-content-center my-3">
              <div className="row ">
                <div className="col-3 d-flex align-items-center justify-content-center">
                  <p>Alexander Alex</p>
                </div>
                <div className="col-2 d-flex align-items-center justify-content-center">
                  <p>Gladiator</p>
                </div>
                <div className="col-2 d-flex align-items-center justify-content-center">
                  <p>2023</p>
                </div>
                <div className="col-3 d-flex align-items-center justify-content-center">
                  <div className="highlight_box d-flex align-items-center justify-content-center p-2">
                    <p>Forward Right Winger</p>
                  </div>
                </div>
                <div className="col-2  d-flex align-items-center justify-content-evenly">
                  <FontAwesomeIcon icon={faArrowUp} />
                  <FontAwesomeIcon icon={faTrashCan} />
                </div>
              </div>
            </div>
            <div className="table_row fullwidth d-flex align-items-center justify-content-center my-3">
              <div className="row ">
                <div className="col-3 d-flex align-items-center justify-content-center">
                  <p>Alexander Alex</p>
                </div>
                <div className="col-2 d-flex align-items-center justify-content-center">
                  <p>Gladiator</p>
                </div>
                <div className="col-2 d-flex align-items-center justify-content-center">
                  <p>2023</p>
                </div>
                <div className="col-3 d-flex align-items-center justify-content-center">
                  <div className="highlight_box d-flex align-items-center justify-content-center p-2">
                    <p>Forward Right Winger</p>
                  </div>
                </div>
                <div className="col-2  d-flex align-items-center justify-content-evenly">
                  <FontAwesomeIcon icon={faArrowUp} />
                  <FontAwesomeIcon icon={faTrashCan} />
                </div>
              </div>
            </div>
            <div className="table_row fullwidth d-flex align-items-center justify-content-center my-3">
              <div className="row ">
                <div className="col-3 d-flex align-items-center justify-content-center">
                  <p>Alexander Alex</p>
                </div>
                <div className="col-2 d-flex align-items-center justify-content-center">
                  <p>Gladiator</p>
                </div>
                <div className="col-2 d-flex align-items-center justify-content-center">
                  <p>2023</p>
                </div>
                <div className="col-3 d-flex align-items-center justify-content-center">
                  <div className="highlight_box d-flex align-items-center justify-content-center p-2">
                    <p>Forward Right Winger</p>
                  </div>
                </div>
                <div className="col-2  d-flex align-items-center justify-content-evenly">
                  <FontAwesomeIcon icon={faArrowUp} />
                  <FontAwesomeIcon icon={faTrashCan} />
                </div>
              </div>
            </div> */}
          </>
        )}
        {AddRequirement && (
          <>
            <Textarea
              label={<h5 className="mt-3 mb-3">Requirement Title :</h5>}
              placeholder="Add Title"
              radius={"md"}
              autosize
              minRows={2}
              value={title}
              onChange={(e) => settitle(e.target.value)}
              error={Errors[0] && "This field cannot be empty"}
            />

            <TagsInput
              label={<h5 className="mt-3 mb-3">Label :</h5>}
              placeholder="Press Enter After Every Label to add"
              size="lg"
              radius={"md"}
              clearable
              value={label}
              onChange={setlabel}
              error={Errors[1] && "This field cannot be empty"}
            />

            <Textarea
              label={<h5 className="mt-3 mb-3">Description :</h5>}
              placeholder="Add Description"
              radius={"md"}
              autosize
              minRows={5}
              value={description}
              onChange={(e) => setdescription(e.target.value)}
              error={Errors[2] && "This field cannot be empty"}
            />

            <div className="d-flex align-items-center justify-content-center mt-5">
              <Button
                variant="outline"
                color="gray"
                className="mx-3"
                onClick={() => goback()}
              >
                Cancel
              </Button>
              <Button
                variant="filled"
                color="green"
                className="mx-3"
                onClick={() => CheckErrors()}
              >
                Save
              </Button>
            </div>
          </>
        )}
      </div>
      <Modal
        opened={opened}
        onClose={close}
        centered
        size={"xl"}
        id="Promote_modal"
      >
        <div className="d-flex align-items-center justify-content-center">
          <h3>Promote to</h3>
        </div>
        <div className="d-flex align-items-center justify-content-center flex-wrap ">
          <div
            className="promotion_box m-2 d-flex align-items-center justify-content-center "
            onClick={() => promoteplayer("FifthGrade")}
          >
            <p>5th</p>
          </div>
          <div
            className="promotion_box m-2 d-flex align-items-center justify-content-center "
            onClick={() => promoteplayer("SixthGrade")}
          >
            <p>6th</p>
          </div>
          <div
            className="promotion_box m-2 d-flex align-items-center justify-content-center "
            onClick={() => promoteplayer("SeventhGrade")}
          >
            <p>7th</p>
          </div>
          <div
            className="promotion_box m-2 d-flex align-items-center justify-content-center "
            onClick={() => promoteplayer("EighthGrade")}
          >
            <p>8th</p>
          </div>
          <div
            className="promotion_box m-2 d-flex align-items-center justify-content-center "
            onClick={() => promoteplayer("Freshman")}
          >
            <p>Freshman</p>
          </div>
          <div
            className="promotion_box m-2 d-flex align-items-center justify-content-center "
            onClick={() => promoteplayer("Sophomore")}
          >
            <p>Sophmore</p>
          </div>
          <div
            className="promotion_box m-2 d-flex align-items-center justify-content-center "
            onClick={() => promoteplayer("Junior")}
          >
            <p>Junior</p>
          </div>
          <div
            className="promotion_box m-2 d-flex align-items-center justify-content-center "
            onClick={() => promoteplayer("Senior")}
          >
            <p>Senior</p>
          </div>
        </div>
      </Modal>
      <Modal
        opened={confirmationModal}
        onClose={() => setconfirmationModal(false)}
        withCloseButton={false}
        centered={true}
        size="lg"
        radius={10}
        overlayProps={{
          backgroundOpacity: 0.55,
          blur: 3,
        }}
        id="date_delete_modal"
      >
        <div className="d-flex flex-column align-items-center justify-content-center my-5">
          <h5 className="mb-3">Are you sure you want to delete this player?</h5>

          <div className="d-flex align-items-center justify-content-center">
            <Button
              variant="filled"
              className="me-2"
              color="rgba(5, 128, 23, 1)"
              onClick={() => setconfirmationModal(false)}
            >
              Decline
            </Button>
            <Button
              variant="filled"
              color="rgba(163, 2, 2, 1)"
              onClick={() => setdeletedateConfirmation(true)}
            >
              Accept
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        opened={chooseRequirementmodal}
        onClose={() => setchooseRequirementmodal(false)}
        centered
        size="xl"
        id="choose_requirement_modal"
        overlayProps={{
          backgroundOpacity: 0.55,
          blur: 3,
        }}
      >
        <div className="requirement_modal_cont d-flex flex-column align-items-center">
          <h5>Select Requirements Category</h5>

          <div className="requirement_categories d-flex align-items-center justify-content-between">
            <div
              className="requirement d-flex flex-column align-items-center justify-content-center "
              onClick={() => {
                setcategory("Academic");
                setAddRequirement(true);
                setchooseRequirementmodal(false);
              }}
            >
              <div className="glow"></div>
              <img className="mb-2" src="images/Cap.png" alt="" />
              <h5>Academic</h5>
            </div>
            <div
              className="requirement d-flex flex-column align-items-center justify-content-center "
              onClick={() => {
                setcategory("Non-Academic");

                setAddRequirement(true);
                setchooseRequirementmodal(false);
              }}
            >
              <div className="glow"></div>
              <img className="mb-2" src="images/Rugby.svg" alt="" />
              <h5>Non-Academic</h5>
            </div>
            <div
              className="requirement d-flex flex-column align-items-center justify-content-center "
              onClick={() => {
                setcategory("Other");
                setAddRequirement(true);
                setchooseRequirementmodal(false);
                close();
              }}
            >
              <div className="glow"></div>
              <img className="mb-2" src="images/other.png" alt="" />
              <h5>Other</h5>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default PlayerGradeSearch;
