import React from "react";
import "./ScoutSearchStyles.scss";
import SearchResult from "./SearchResult";
const ScoutSearch = () => {
  return (
    <div className="search_results_cont mt-4">
      <div className="row">
        <div className="col-6 px-2 mb-3">
          <SearchResult />
        </div>
        <div className="col-6 px-2 mb-3">
          <SearchResult />
        </div>
        <div className="col-6 px-2 mb-3">
          <SearchResult />
        </div>
        <div className="col-6 px-2 mb-3">
          <SearchResult />
        </div>
        <div className="col-6 px-2 mb-3">
          <SearchResult />
        </div>
        <div className="col-6 px-2 mb-3">
          <SearchResult />
        </div>
      </div>
    </div>
  );
};

export default ScoutSearch;
