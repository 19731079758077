import React, { useState } from "react";
import "./SearchInstStyles.scss";
import { Input, TextInput } from "@mantine/core";
import { Avatar } from "@mantine/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { update } from "../auth/authslice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CloseButton } from "@mantine/core";
import { toast } from "react-toastify";
import {
  faMagnifyingGlass,
  faBell,
  faCircleInfo,
  faCirclePlus,
  faBookmark,
} from "@fortawesome/free-solid-svg-icons";
import Signupbyinst from "../pages/Signupbyinst";
import { faCircleCheck } from "@fortawesome/free-regular-svg-icons";
import { useDisclosure } from "@mantine/hooks";
import { Button, Modal } from "@mantine/core";
import axios from "axios";
const SearchInst = ({
  hideSidebar = () => {},
  teamName,
  RefreshAfterplayerSignup = () => {},
}) => {
  const [opened, { open, close }] = useDisclosure(false);
  const { user } = useSelector((state) => state.auth);
  const [searchValue, setSearchValue] = useState("");
  const port = process.env.REACT_APP_IP_ADDRESS;
  const API_URL = `${port}/api/users/Institute`;

  const SearchPlayer = () => {
    console.log(searchValue.trim(), teamName, user?._id, API_URL);
    if (searchValue.trim() !== "") {
      TextSearch();
    }
  };

  const TextSearch = async () => {
    try {
      console.log("called");
      const response = await axios.post(`${API_URL}/addPlayerByEmail`, {
        email: searchValue.trim(),
        instituteId: user?._id,
        TeamName: teamName,
      });
      console.log(response.data);
      toast.success(
        <>
          <img src="images/new-logo.png" className="toast_logo" />
          <p className="mt-3">Player added to Team</p>
        </>,

        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        }
      );
    } catch (err) {
      console.log(err);
      toast.error(
        <>
          <img src="images/new-logo.png" className="toast_logo" />
          <p className="mt-3">No player with given Email</p>
        </>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        }
      );
    } finally {
      close();
      // setSearched(true);
    }
  };
  const navigate = useNavigate();
  const [Signup, setSignup] = useState(false);
  return (
    <>
      {!Signup && (
        <div className=" px-4 py-3">
          {/* {searched == false && ( */}
          <div className="Search_cont d-flex align-items-center justify-content-center">
            <div className="outter_search_box d-flex justify-content-center align-items-center">
              <div className="inner_search_box d-flex flex-column align-items-center justify-content-center">
                <img src="images/vector.png" alt="" className="mb-4" />
                <h6 className="mb-4">Check Registered Players</h6>
                <Button variant="filled" color="green" onClick={open}>
                  Check Players
                </Button>
              </div>
              <div className="inner_search_box d-flex flex-column align-items-center justify-content-center">
                <img src="images/vector.png" alt="" className="mb-4" />
                <h6 className="mb-4">Add New Players</h6>
                <Button
                  variant="filled"
                  color="green"
                  onClick={() =>
                    // navigate("/signupcoachbyinst", {
                    //   state: { instituteId: user._id },
                    // })
                    {
                      hideSidebar();
                      setSignup(true);
                    }
                  }
                >
                  Add Players
                </Button>
              </div>
            </div>
          </div>
          {/* )} */}
          {/* {searched == true && (
        <div className="d-flex align-items-center justify-content-start flex-wrap">
          {SearchResults &&
            SearchResults?.map((player, index) => (
              <AddPlayer player={player} key={index} teamName={teamName} />
            ))}
        </div>
      )} */}
          <Modal
            opened={opened}
            onClose={close}
            withCloseButton={false}
            centered
            overlayProps={{
              backgroundOpacity: 0.55,
              blur: 3,
            }}
            id="search_players_modal"
          >
            <TextInput
              size="lg"
              radius="md"
              label="Add Player by email"
              placeholder="Email"
              className="mt-5"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              leftSection={<FontAwesomeIcon icon={faMagnifyingGlass} />}
            />
            <div className="d-flex align-items-center justify-content-center pt-3 mt-5">
              <Button
                variant="filled"
                color="green"
                onClick={() => SearchPlayer()}
              >
                Search
              </Button>
            </div>
          </Modal>
        </div>
      )}
      {Signup && (
        <Signupbyinst
          Finished={() => {
            setSignup(false);
            hideSidebar();
            RefreshAfterplayerSignup();
          }}
          TeamName={teamName}
        />
      )}
    </>
  );
};

const AddPlayer = ({ player, teamName }) => {
  const { user } = useSelector((state) => state.auth);
  const [opened, { open, close }] = useDisclosure(false);
  const dispatch = useDispatch();
  const port = process.env.REACT_APP_IP_ADDRESS;
  const addtoteam = async (player) => {
    try {
      console.log(
        "---------------------------------------------------------",
        user
      );
      const response = await axios.post(
        `${port}/api/users/Institute/team/addplayer`,
        {
          instituteId: user._id,
          teamName: teamName,
          playerId: player._id,
        }
      );
      console.log(response.data);

      console.log(player);
    } catch (err) {
      console.log("err in add player", err);
    } finally {
      toast.success("Request sent to player", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };
  return (
    <>
      <div className="m-2">
        <div className="Player">
          <div className="row">
            <div className="col-6">
              <img src={player?.Image} alt="" />
            </div>
            <div className="col-6 pt-4">
              <h5>{player?.firstName + " " + player?.lastName}</h5>
              {/* <div className="d-flex align-items-center">
              <FontAwesomeIcon
                icon={faCircleCheck}
                className="me-2"
                color="#009B3A"
              />
              <p>Registered</p>
            </div> */}
              <div className="d-flex align-items-center mt-2 ">
                <p className="metric me-3">Position</p>
                <p className="value">Forward right winger</p>
              </div>
              <div className="d-flex align-items-center mt-2 ">
                <p className="metric me-3">Weight</p>
                <p className="value">{player?.instituteName}</p>
              </div>
              <div className="button_cont d-flex align-items-center justify-content-center mt-4">
                <Button
                  variant="filled"
                  color="rgba(57, 60, 82, 0.2)"
                  size="sm"
                  radius="xl"
                  className="me-3"
                  onClick={() => addtoteam(player)}
                >
                  Add Player
                </Button>
                {/* <FontAwesomeIcon icon={faBookmark} /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SearchInst;
