import React, { useState, useEffect } from "react";
import "../styles/CoachDashboardStyles.scss";
import VaVideoEditor from "../components/VaVideoEditor";
import { Button } from "@mantine/core";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "../auth/authslice";

const VaDash = () => {
  const [SidebarHidden, setSidebarHidden] = useState(false);
  useEffect(() => {
    setSidebarHidden(false);
  }, []);
  const [upload, setupload] = useState(false);
  const [goBack, setgoback] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(logout());
  };
  useEffect(() => {
    if (!user) {
      navigate("/");
    }
  }, [user]);
  return (
    <div className="dashboard_cont ">
      <Button variant="filled" onClick={() => handleLogout()}>
        Logout
      </Button>
      <VaVideoEditor
        hideSidebar={() => setSidebarHidden(!SidebarHidden)}
        upload={upload}
        setupload={setupload}
        goBack={goBack}
        setgoback={setgoback}
      />
    </div>
  );
};

export default VaDash;
