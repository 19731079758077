import React, { useState, useEffect } from "react";
import axios from "axios";
import { Input, Modal, Button } from "@mantine/core";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./DownloadButtonStyles.scss";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const port = process.env.REACT_APP_IP_ADDRESS;
const Download_Video_API_URL = `${port}/api/videos/generateDownloadLink`;

// Simple regex pattern for basic email validation
const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const DownloadButton = () => {
  const userData = JSON.parse(sessionStorage.getItem("user"));
  const [openedModal, setOpenedModal] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [loadingSelf, setLoadingSelf] = useState(false); // Loading state for sending to self
  const [loadingOthers, setLoadingOthers] = useState(false); // Loading state for sending to others
  const location = useLocation();

  const [downloadVideoId, setDownloadVideoId] = useState(
    location.state?.video?._id || ""
  );

  useEffect(() => {
    if (location.state?.video) {
      setDownloadVideoId(location.state.video._id);
    }
  }, [location.state?.video]);

  useEffect(() => {
    if (!openedModal) {
      setUserEmail("");
      setEmailError("");
      setLoadingSelf(false);
      setLoadingOthers(false);
    }
  }, [openedModal]);

  const handleSendEmail = async (emailToSend, sendToSelf) => {
    const isValidEmail = emailToSend.trim() && emailRegex.test(emailToSend);

    if (!sendToSelf && !isValidEmail) {
      setEmailError("Please enter a valid email address.");
      return; // Exit early if the email is invalid
    }

    sendToSelf ? setLoadingSelf(true) : setLoadingOthers(true);
    const requestBody = {
      videoId: downloadVideoId,
      coachId: userData._id,
      Email: emailToSend,
      SendToSelf: sendToSelf,
    };

    try {
      const response = await axios.post(Download_Video_API_URL, requestBody);
      toast.success(
        <>
          <img src="images/new-logo.png" className="toast_logo" />
          <p className="mt-3">Email sent Successfully</p>
        </>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        }
      );
      setOpenedModal(false); // Close modal on success
    } catch (error) {
      console.error("Failed to send email:", error);
      toast.error(
        <>
          <img src="images/new-logo.png" className="toast_logo" />
          <p className="mt-3">Failed to send email. Please try again</p>
        </>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        }
      );
    } finally {
      sendToSelf ? setLoadingSelf(false) : setLoadingOthers(false);
    }
  };

  return (
    <>
      <Button
        variant="outline"
        color="blue"
        radius="xl"
        leftSection={<FontAwesomeIcon icon={faDownload} />}
        className="me-3"
        onClick={() => setOpenedModal(true)}
      >
        Download
      </Button>
      <Modal
        opened={openedModal}
        onClose={() => setOpenedModal(false)}
        title="Send Video"
        centered
        size="lg"
      >
        <Input
          value={userEmail}
          onChange={(e) => {
            setUserEmail(e.target.value);
            setEmailError(""); // Clear error when user starts typing
          }}
          placeholder="Enter email address"
          type="email"
          label="Email"
          className="mb-4 mx-3"
          size="md"
          error={emailError} // Show error below the input
        />
        {emailError && (
          <div className="text-red-500 text-sm mb-4 mx-3 emailError">
            {emailError}
          </div>
        )}
        <div className="videoDownloadButtons">
          {" "}
          <Button
            size="md"
            variant="filled"
            color="green"
            onClick={() => handleSendEmail(userData.email, true)}
            className="mx-3"
            loading={loadingSelf}
          >
            Send to self
          </Button>
          <Button
            size="md"
            variant="filled"
            color="green"
            onClick={() => handleSendEmail(userEmail, false)}
            className="mx-3"
            loading={loadingOthers}
          >
            Send to Others
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default DownloadButton;
