import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Input, Button, Modal, Textarea, Checkbox } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { toast } from "react-toastify";
import "./CoachNotificationStyles.scss";
import axios from "axios";

const CoachNotifications = () => {
  const port = process.env.REACT_APP_IP_ADDRESS;
  const API_URL = `${port}/api/`;
  const { user } = useSelector((state) => state.auth);
  const [allPlayers, setallPlayers] = useState([]);
  const [Title, setTitle] = useState("");
  const [Description, setDescription] = useState("");
  const DiscardInputs = () => {
    setTitle("");
    setDescription("");
  };
  const [opened, { open, close }] = useDisclosure(false);
  const [SendToChoice, setSendToChoice] = useState("");
  const [NotificationSendStep, setNotificationSendStep] = useState(1);
  const [PlayerNameSearch, setPlayerNameSearch] = useState("");
  const [chosenPlayer, setchosenPlayer] = useState();
  const [chosenTeam, setchosenTeam] = useState();

  useEffect(() => {
    if (!opened) {
      setTitle("");
      setDescription("");
      setPlayerNameSearch("");
      setSendToChoice("");
      setNotificationSendStep(1);
      setallPlayers([]);
      setchosenPlayer();
      setchosenTeam();
    }
  }, [opened]);

  const getPlayers = async () => {
    try {
      if (PlayerNameSearch.trim() == "") return;
      const res = await axios.post(
        `${API_URL}search/searchPlayersInInstitute`,
        {
          InstituteId: user?.Institute,
          SearchText: PlayerNameSearch,
        }
      );
      console.log("players", res.data);
      setallPlayers(res.data);
    } catch (error) {
      console.log(error);
    }
  };
  const [AllTeams, setAllTeams] = useState([]);
  const getAllteams = async () => {
    try {
      const res = await axios.post(
        `${API_URL}users/Institute/getAllTeamsForInstitute`,
        {
          instituteId: user?.Institute,
        }
      );
      console.log("teams", res.data);
      setAllTeams(res.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAllteams();
  }, []);
  const playerSearchHelper = () => {
    setNotificationSendStep(3);
    getPlayers();
  };

  const ChooseTeamHelper = () => {
    if (chosenTeam !== "All Teams") {
      setNotificationSendStep(3);
      getplayersbyteam();
    } else {
      SendNotification();
      close();
    }
  };
  const [chosenTeamPlayers, setchosenTeamPlayers] = useState([]);
  const getplayersbyteam = async () => {
    try {
      const response = await axios.post(
        `${port}/api/users/Institute/getPlayersbyTeamName`,
        {
          InstituteID: user?.Institute,
          TeamName: chosenTeam,
        }
      );
      console.log(response.data);
      setchosenTeamPlayers(
        response.data?.players.map((player) => ({
          ...player,
          checkbox: false,
        }))
      );
    } catch (error) {
      console.log(error);
    } finally {
      console.log("choseteamPlayers", chosenTeamPlayers);
    }
  };
  const checkAllPlayers = () => {
    const newPlayers = chosenTeamPlayers.map((player) => ({
      ...player,
      checkbox: true,
    }));
    setchosenTeamPlayers(newPlayers);
    setIsAnyChecked(newPlayers.some((player) => player.checkbox));
  };
  const [isAnyChecked, setIsAnyChecked] = useState(false);
  useEffect(() => {
    console.log("choseteamPlayers", chosenTeamPlayers);
  }, [chosenTeamPlayers]);
  const SendNotification = async () => {
    try {
      const res = await axios.post(`${API_URL}Request/createNotification`, {
        InstituteId: user?.Institute,
        Title: Title,
        Message: Description,
        SenderName: user?.firstName + " " + user?.lastName,
        SendToAllTeams: chosenTeam === "All Teams",
        UserId: chosenPlayer?._id ? chosenPlayer?._id : checkedPlayerIds,
        UserType: "Player",
      });
      getNotifications();
      toast.success(
        <>
          <img src="images/new-logo.png" className="toast_logo" />
          <p className="mt-3">Notification Sent Successfully</p>
        </>,

        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        }
      );
    } catch (error) {
      console.log(error);
      toast.error(
        <>
          <img src="images/new-logo.png" className="toast_logo" />
          <p className="mt-3">Error in Sending Notification</p>
        </>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        }
      );
    }
  };
  const [checkedPlayerIds, setCheckedPlayerIds] = useState([]);
  const TeamNotificationHelper = () => {
    setCheckedPlayerIds(
      chosenTeamPlayers
        .filter((player) => player.checkbox)
        .map((player) => player._id)
    );
    SendNotification();
    close();
  };
  const [sentNotifications, setsentNotifications] = useState([]);
  const getNotifications = async () => {
    try {
      const res = await axios.post(`${API_URL}Request/viewOutGoingRequests`, {
        id: user?.Institute,
      });
      console.log("get notifications", res.data);
      setsentNotifications(res.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getNotifications();
  }, []);
  return (
    <div className="Notifications_cont">
      <h2 className="mb-4">Notifications</h2>
      <div className="main_notifications">
        <div className="row">
          <div className="col-8 d-flex flex-column ">
            <h4 className="mb-4">Write Notifications</h4>
            <div className="title_cont mb-3">
              <Input
                placeholder="Title"
                size="lg"
                radius={"xl"}
                value={Title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
            <div className="Description_cont mb-3">
              <Textarea
                placeholder="Input placeholder"
                autosize
                minRows={15}
                radius={"lg"}
                value={Description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>
            <div className="button_cont d-flex align-items-center justify-content-center ">
              <div className="button_width me-3">
                <Button
                  variant="outline"
                  size="md"
                  radius={"xl"}
                  color="#FFFFFF"
                  fullWidth
                  onClick={() => DiscardInputs()}
                >
                  Discard
                </Button>
              </div>
              <div className="button_width">
                <Button
                  fullWidth
                  variant="gradient"
                  size="md"
                  radius={"xl"}
                  disabled={Title.trim() == "" || Description.trim() == ""}
                  gradient={{ from: "#009B3A", to: "#2FED76", deg: 180 }}
                  onClick={open}
                >
                  Send
                </Button>
              </div>
            </div>
          </div>
          <div className="col-4 notification_columns">
            <div className="sent_notifications_cont d-flex flex-column py-3 px-3">
              <div className="sent_notifications_top d-flex align-items-center justify-content-between pb-2">
                <h5>Sent Notifications</h5>
                <img src="images/Edit.png" alt="" />
              </div>
              {sentNotifications?.length > 0 &&
                sentNotifications?.map((notification, index) => {
                  const date = new Date(notification?.createdAt);

                  const year = date.getFullYear().toString().substr(-2); // get the last two digits of the year
                  const month = ("0" + (date.getMonth() + 1)).slice(-2); // get the month and pad it with a zero if it's a single digit
                  const day = ("0" + date.getDate()).slice(-2); // get the day and pad it with a zero if it's a single digit

                  const formattedDate = `${year}/${month}/${day}`;
                  return (
                    <>
                      <div className="sent_notification d-flex flex-column align-items-start mt-3 pb-2">
                        <div className="d-flex align-items-center justify-content-between sent_notification_top">
                          <p className="sent_notification_title">
                            {notification?.Title}
                          </p>
                          <p className="sent_notification_date">
                            {formattedDate}
                          </p>
                        </div>
                        <p className="sent_notification_message">
                          {notification?.message.length > 30
                            ? `${notification?.message.slice(0, 30)}...`
                            : notification?.message}
                        </p>
                      </div>
                    </>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
      <Modal
        opened={opened}
        onClose={close}
        centered
        size={"lg"}
        id="Notifications_modal"
      >
        <div className="Notification_modal_sendTo d-flex flex-column justify-content-center align-items-center">
          {NotificationSendStep == 1 && (
            <>
              <h3 className="mb-5">Send To</h3>
              <div className="d-flex mb-5">
                <div
                  className={`user_choice mx-3 d-flex flex-column align-items-center justify-content-center ${
                    SendToChoice == "Team" && "Active"
                  }`}
                  onClick={() => setSendToChoice("Team")}
                >
                  <img src="images/Users_Group.png" alt="" />
                  <div className="design_box d-flex align-items-center justify-content-center">
                    <h5>Team</h5>
                  </div>
                </div>
                <div
                  className={`user_choice mx-3 d-flex flex-column align-items-center justify-content-center ${
                    SendToChoice == "Player" && "Active"
                  }`}
                  onClick={() => setSendToChoice("Player")}
                >
                  <img src="images/User.png" alt="" />
                  <div className="design_box d-flex align-items-center justify-content-center">
                    <h5>Player</h5>
                  </div>
                </div>
              </div>
              <div className="select_button_cont">
                <Button
                  variant="filled"
                  color="#ffffff"
                  style={{ color: "black" }}
                  fullWidth
                  size="md"
                  radius={"xl"}
                  disabled={SendToChoice == ""}
                  onClick={() => setNotificationSendStep(2)}
                >
                  Confirm
                </Button>
              </div>
            </>
          )}
          {NotificationSendStep == 2 && SendToChoice == "Player" && (
            <>
              <h3 className="mb-5">Registered Players</h3>
              <div className="Player_search_cont ">
                <Input
                  placeholder="Player Name"
                  size="lg"
                  radius={"lg"}
                  value={PlayerNameSearch}
                  onChange={(e) => setPlayerNameSearch(e.target.value)}
                />
              </div>
              <div className="search_button_cont">
                <Button
                  variant="filled"
                  color="#ffffff"
                  style={{ color: "black" }}
                  fullWidth
                  size="md"
                  radius={"xl"}
                  disabled={PlayerNameSearch.trim() == ""}
                  onClick={() => playerSearchHelper()}
                >
                  Search
                </Button>
              </div>
            </>
          )}
          {NotificationSendStep == 3 && SendToChoice == "Player" && (
            <>
              <h3 className="mb-5">Registered Players</h3>
              <div className="d-flex align-items-start justify-content-start flex-wrap">
                {allPlayers?.length > 0 &&
                  allPlayers?.map((player, index) => {
                    if (user?._id !== player?.referenceId?._id) {
                      return (
                        <div className="m-2" key={index}>
                          <div
                            className={`player_message  px-2 d-flex align-items-center justify-content-start ${
                              chosenPlayer == player && "active_user"
                            }  `}
                            onClick={() => setchosenPlayer(player)}
                          >
                            <div className="player_message_image d-flex align-items-center justify-content-center me-4">
                              {" "}
                              <img src={player?.Image} alt="" />
                            </div>

                            <p>{player?.firstName}</p>
                          </div>
                        </div>
                      );
                    }
                  })}
              </div>
              {allPlayers?.length > 0 && (
                <div className="search_button_cont mt-5">
                  <Button
                    variant="filled"
                    color="#ffffff"
                    style={{ color: "black" }}
                    fullWidth
                    size="md"
                    radius={"xl"}
                    disabled={!chosenPlayer}
                    onClick={() => {
                      SendNotification();
                      close();
                    }}
                  >
                    Send
                  </Button>
                </div>
              )}
            </>
          )}
          {NotificationSendStep == 2 && SendToChoice == "Team" && (
            <>
              <h3 className="mb-5">Registered Teams</h3>
              <div className="d-flex align-items-start justify-content-start flex-wrap">
                {AllTeams?.length > 0 &&
                  AllTeams?.map((Team, index) => (
                    <div className="m-2" key={index}>
                      <div
                        className={`player_message  px-2 d-flex align-items-center justify-content-start ${
                          chosenTeam == Team && "active_user"
                        }  `}
                        onClick={() => setchosenTeam(Team)}
                      >
                        <div className="player_message_image d-flex align-items-center justify-content-center me-4">
                          {" "}
                          <img src="images/team-logo.png" alt="" />
                        </div>

                        <p>{Team}</p>
                      </div>
                    </div>
                  ))}
                {AllTeams?.length >= 2 && (
                  <>
                    <div className="m-2">
                      <div
                        className={`player_message  px-2 d-flex align-items-center justify-content-start ${
                          chosenTeam == "All Teams" && "active_user"
                        }  `}
                        onClick={() => setchosenTeam("All Teams")}
                      >
                        <div className="player_message_image d-flex align-items-center justify-content-center me-4">
                          {" "}
                          <img src="images/team-logo.png" alt="" />
                        </div>

                        <p>All Teams</p>
                      </div>
                    </div>
                  </>
                )}
              </div>
              {AllTeams?.length > 0 && (
                <div className="search_button_cont mt-5">
                  <Button
                    variant="filled"
                    color="#ffffff"
                    style={{ color: "black" }}
                    fullWidth
                    size="md"
                    radius={"xl"}
                    disabled={!chosenTeam}
                    onClick={() => ChooseTeamHelper()}
                  >
                    Send
                  </Button>
                </div>
              )}
            </>
          )}
          {NotificationSendStep == 3 && SendToChoice == "Team" && (
            <>
              <h3 className="mb-5">{chosenTeam}</h3>
              <div className="chosen_team_players_cont mb-4">
                {chosenTeamPlayers?.length > 0 &&
                  chosenTeamPlayers?.map((player, index) => (
                    <div className="m-2" key={index}>
                      <div
                        className={`choosen_team_player  px-2 d-flex align-items-center justify-content-start `}
                      >
                        <Checkbox
                          checked={player.checkbox}
                          labelPosition="left"
                          color="green"
                          variant="outline"
                          className="me-3"
                          size="xs"
                          onChange={() => {
                            const newPlayers = chosenTeamPlayers.map(
                              (item, i) =>
                                i === index
                                  ? { ...item, checkbox: !item.checkbox }
                                  : item
                            );
                            setchosenTeamPlayers(newPlayers);
                            setIsAnyChecked(
                              newPlayers.some((player) => player.checkbox)
                            );
                          }}
                        />
                        <div className="choosen_team_player_image d-flex align-items-center justify-content-center me-4">
                          {" "}
                          <img src="images/player-1.png" alt="" />
                        </div>

                        <p>{player?.firstName + " " + player?.lastName}</p>
                      </div>
                    </div>
                  ))}
              </div>
              <div className="choosen_team_players_buttons_cont d-flex align-items-center justify-content-center ">
                <div className="button_cont me-3">
                  <Button
                    variant="outline"
                    color="#ffffff"
                    fullWidth
                    size="md"
                    radius={"xl"}
                    onClick={checkAllPlayers}
                  >
                    Select All
                  </Button>
                </div>
                <div className="button_cont">
                  <Button
                    variant="filled"
                    color="#ffffff"
                    style={{ color: "black" }}
                    fullWidth
                    size="md"
                    radius={"xl"}
                    disabled={!isAnyChecked}
                    onClick={() => TeamNotificationHelper()}
                  >
                    Send
                  </Button>
                </div>
              </div>
            </>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default CoachNotifications;
